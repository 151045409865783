import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Dropdown, Grid, Loader, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import className from 'classnames';
import { format } from 'date-fns';

import { getTimeline, setTimeline, timelineData, statusLoading } from '../../ducks/report';
import {
    cleverCalculationDays,
    dateParseAndFormat,
    renderHelper,
} from '../../utils/functionHelper';
import { countryTimelineStatus, listMonth } from '../../utils/const';
import ItemDnD from './components/item';

import './style.scss';

const date = new Date();

function dayOff(year, month, day) {
    return new Date(year, month, day, 0).getDay() % 6 === 0;
}

function newMonth(full, day) {
    if (format(date, 'dd.MM.yyyy') !== full) {
        return 1 === day;
    }
}

function currentDay(full) {
    return format(date, 'dd.MM.yyyy') === full;
}

function OrderTimeline() {
    const dispatch = useDispatch();
    const timeline = useSelector(timelineData);
    const [filter, setFilter] = useState({
        statuses: [],
    });
    const [currentItem, setCurrentItem] = useState();
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const start = dateParseAndFormat(timeline?.dateFrom);
    const end = dateParseAndFormat(timeline?.dateTo);
    const listDay = cleverCalculationDays(start, end);

    useEffect(() => {
        fetchData();
    }, [filter]);

    function fetchData() {
        dispatch(getTimeline(filter));
    }

    function dragOverHandler(e) {
        e.preventDefault();
        if (
            e.target.className === 'table__dnd' ||
            e.target.className === 'table__dnd table__dnd--active' ||
            e.target.className === 'table__dnd table__dnd--end'
        ) {
            e.target.style.boxShadow = '2px 3px 3px 2px gray';
        }
    }

    function dragLeaveHandler(e) {
        e.target.style.boxShadow = 'none';
    }

    function dragEndHandler(e) {
        e.target.style.boxShadow = 'none';
    }

    function dragHandler(e, full, stream) {
        e.preventDefault();
        if (currentItem) {
            dispatch(setTimeline({ full, currentItem, stream, callbackSuccess: fetchData }));
            setCurrentItem(false);
        }
        e.target.style.boxShadow = 'none';
    }

    return (
        <div className="order-timeline">
            <div className="order-timeline__body">
                <Grid>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={5} className="filter__title">
                            Производительность
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5} className="filter__text">
                            Статус
                            <Dropdown
                                onChange={handleChange}
                                className="filter__dropdown"
                                value={filter.status}
                                fluid
                                selection
                                multiple
                                placeholder="Выберите статус"
                                options={countryTimelineStatus}
                                name="statuses"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <Segment>
                    <Dimmer active={!timeline} inverted>
                        <Loader>Загрузка</Loader>
                    </Dimmer>
                    <Grid className="table">
                        <Grid.Row>
                            <Container>
                                {listDay &&
                                    listDay.map(({ day, month, year, full }, index) => (
                                        <Grid.Row className="table__title" key={index}>
                                            <Grid.Column
                                                className={className('table__cell', {
                                                    table__disable: dayOff(year, month, day),
                                                    table__end: newMonth(full, day),
                                                    table__active: currentDay(full),
                                                })}
                                            >
                                                {newMonth('', day) && (
                                                    <p
                                                        id={listMonth[month].text}
                                                        className="table__month"
                                                    >{`${listMonth[month].text}, ${year}`}</p>
                                                )}
                                                <br />
                                                <br />
                                                <div
                                                    className={className('table__day', {
                                                        active: currentDay(full),
                                                    })}
                                                >
                                                    {day}
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    ))}
                            </Container>
                        </Grid.Row>
                        <Grid.Row>
                            {timeline &&
                                timeline.forProductionLines.map((item, indexItem) => (
                                    <>
                                        <Container key={item.productionLineName}>
                                            {listDay.map(({ day, month, year, full }, index) =>
                                                index > 1 ? (
                                                    <Grid.Row
                                                        className="table__title"
                                                        key={full + day}
                                                    >
                                                        <Grid.Column>
                                                            <div
                                                                className={className(
                                                                    'table__columns',
                                                                    {
                                                                        'table__dnd--active':
                                                                            currentDay(full),
                                                                        'table__dnd--end': newMonth(
                                                                            full,
                                                                            day,
                                                                        ),
                                                                    },
                                                                )}
                                                            ></div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ) : (
                                                    index == 0 && (
                                                        <Grid.Row
                                                            className="table__title stream"
                                                            key={full + year}
                                                        >
                                                            <Grid.Column>
                                                                <span className="table__stream">
                                                                    {item.productionLineName}
                                                                </span>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )
                                                ),
                                            )}
                                        </Container>
                                        {renderHelper(item.linesNumber).map((number, indexData) => (
                                            <Container key={number}>
                                                {listDay.map(({ full, day }) => (
                                                    <Grid.Row className="table__body" key={full}>
                                                        <Grid.Column>
                                                            <div
                                                                className={className('table__dnd', {
                                                                    'table__dnd--active':
                                                                        currentDay(full),
                                                                    'table__dnd--end': newMonth(
                                                                        full,
                                                                        day,
                                                                    ),
                                                                })}
                                                                onDragOver={dragOverHandler}
                                                                onDragLeave={dragLeaveHandler}
                                                                onDragEnd={dragEndHandler}
                                                                onDrop={(event) =>
                                                                    dragHandler(
                                                                        event,
                                                                        full,
                                                                        item.productionLineId,
                                                                    )
                                                                }
                                                                draggable={false}
                                                            >
                                                                {item.forOrders.map(
                                                                    (data) =>
                                                                        indexData + 1 ===
                                                                            data.line && (
                                                                            <ItemDnD
                                                                                data={data}
                                                                                setCurrentItem={
                                                                                    setCurrentItem
                                                                                }
                                                                                end={
                                                                                    timeline.dateTo
                                                                                }
                                                                                full={full}
                                                                                key={
                                                                                    full +
                                                                                    data.startDate
                                                                                }
                                                                            />
                                                                        ),
                                                                )}
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))}
                                            </Container>
                                        ))}
                                    </>
                                ))}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        </div>
    );
}

export default OrderTimeline;
