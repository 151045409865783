import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { dateFormatDD, setOptionsName } from '../utils/functionHelper';
import { parseISO } from 'date-fns';

//*                                                       /Operation                                                *//

//*  TYPES  *//

export const DELETE_OPERATION_GROUP = 'DELETE_OPERATION_GROUP';

export const GET_OPERATION_GROUP_LIST_SUCCESS = 'GET_OPERATION_GROUP_LIST_SUCCESS';
export const GET_OPERATION_GROUP_LIST_REQUEST = 'GET_OPERATION_GROUP_LIST_REQUEST';

export const GET_OPERATION_LIST_REQUEST = 'GET_OPERATION_LIST_REQUEST';
export const GET_OPERATION_LIST_SUCCESS = 'GET_OPERATION_LIST_SUCCESS';

export const GET_OPERATION_SEARCH_REQUEST = 'GET_OPERATION_SEARCH_REQUEST';
export const GET_OPERATION_SEARCH_SUCCESS = 'GET_OPERATION_SEARCH_SUCCESS';

export const ADD_OPERATION_REQUEST = 'ADD_OPERATION_REQUEST';
export const ADD_OPERATION_SUCCESS = 'ADD_OPERATION_SUCCESS';

export const EDIT_OPERATION_REQUEST = 'EDIT_OPERATION_REQUEST';
export const EDIT_OPERATION_SUCCESS = 'EDIT_OPERATION_SUCCESS';

export const DELETE_OPERATION_REQUEST = 'DELETE_OPERATION_REQUEST';
export const DELETE_OPERATION_SUCCESS = 'DELETE_OPERATION_SUCCESS';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const ADD_OPERATION_LIST_WEB = 'ADD_OPERATION_LIST_WEB';

export const ADD_ITEM_DROPDOWN = 'ADD_ITEM_DROPDOWN';

export const ERROR_HANDLER_OPERATION = 'ERROR_HANDLER_OPERATION';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: false,
    operation: [],
    options: [],
    itemsCount: 0,
    pagesCount: 0,
    operationModel: [],
    searchList: [],
    operationWeb: [],
};

//*  REDUCER  *//

export default function operation(state = initial, action) {
    switch (action.type) {
        case ERROR_HANDLER_OPERATION:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CLEAR_SEARCH:
            return {
                ...state,
                searchList: [],
            };
        case ADD_OPERATION_LIST_WEB:
            return {
                ...state,
                operationWeb: action.payload,
            };
        case GET_OPERATION_SEARCH_SUCCESS:
            return {
                ...state,
                searchList: action.payload,
            };
        case GET_OPERATION_GROUP_LIST_SUCCESS:
        case GET_OPERATION_LIST_SUCCESS:
        case DELETE_OPERATION_SUCCESS:
        case ADD_OPERATION_SUCCESS:
        case EDIT_OPERATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case DELETE_OPERATION_REQUEST:
        case ADD_OPERATION_REQUEST:
        case GET_OPERATION_LIST_REQUEST:
        case EDIT_OPERATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_ITEM_DROPDOWN:
            return {
                ...state,
                operationModel: [
                    ...state.operationModel,
                    { name: action.payload, id: action.payload },
                ],
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const clearOperation = () => {
    return {
        type: CLEAR_SEARCH,
    };
};

export const addOperationWeb = (payload) => {
    return {
        type: ADD_OPERATION_LIST_WEB,
        payload,
    };
};

export const searchOperation = (payload) => {
    return {
        type: GET_OPERATION_SEARCH_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_OPERATION,
    };
};

export const getOperation = (payload) => {
    return {
        type: GET_OPERATION_LIST_REQUEST,
        payload,
    };
};

export const getOperationGroup = (payload) => {
    return {
        type: GET_OPERATION_GROUP_LIST_REQUEST,
        payload,
    };
};

export const deleteOperationGroup = (payload) => {
    return {
        type: DELETE_OPERATION_GROUP,
        payload,
    };
};

export const deleteOperation = (payload) => {
    return {
        type: DELETE_OPERATION_REQUEST,
        payload,
    };
};

export const addOperation = (payload) => {
    return {
        type: ADD_OPERATION_REQUEST,
        payload,
    };
};

export const addDropItem = (payload) => {
    return {
        type: ADD_ITEM_DROPDOWN,
        payload,
    };
};

export const editOperation = (payload) => {
    return {
        type: EDIT_OPERATION_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseOperationState = (state) => state.operation;

export const getLoading = createSelector([baseOperationState], (state) => state.loading);

export const operationList = createSelector(
    [baseOperationState],
    (data) =>
        data.operation &&
        data.operation.map((value) => {
            return {
                ...value,
                createDate: dateFormatDD(parseISO(value.createDate)),
            };
        }),
);

export const operationGroupList = createSelector(
    [baseOperationState],
    (data) =>
        data.operationGroup &&
        data.operationGroup.map((item) => {
            return {
                ...item,
                value: item.id,
                text: item.name,
            };
        }),
);

export const operationListOption = createSelector(
    [baseOperationState],
    (data) => data.operationModel && setOptionsName(data.operationModel),
);

export const getSearchList = createSelector(
    [baseOperationState],
    (data) => data.searchList && data.searchList.map((item) => item.name),
);

export const getOperationListWeb = createSelector(
    [baseOperationState],
    (state) => state.operationWeb,
);

export const operationError = createSelector([baseOperationState], (state) => state.error);

//*  SAGA  *//

function* workerGetOperation({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize, name } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Operations`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
            },
        });

        yield put({
            type: GET_OPERATION_LIST_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                [name]: items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetOperationGroup({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize, name, modelId } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/OperationGroups`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
                modelId,
            },
        });

        yield put({
            type: GET_OPERATION_GROUP_LIST_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                [name]: items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddOperation({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.post(`/Operations`, { ...data });

        yield put({
            type: GET_OPERATION_LIST_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditOperation({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.put(`/Operations/${data.id}`, { ...data });

        yield put({
            type: EDIT_OPERATION_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteOperation({ payload }) {
    try {
        const { item, callbackSuccess } = payload;

        yield postman.put(`/Operations/${item.id}`, { ...item, isActive: !item.isActive });
        yield put({
            type: DELETE_OPERATION_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetSearchOperation({ payload }) {
    try {
        const response = yield postman.get(`/Operations`, {
            params: {
                nameSubstring: payload,
                isActive: true,
                pageSize: 10000,
            },
        });
        yield put({
            type: GET_OPERATION_SEARCH_SUCCESS,
            payload: response.items,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteOperationGroup({ payload }) {
    try {
        const { item, callbackSuccess } = payload;
        yield postman.put(`/OperationGroups`, {
            ...item,
            isActive: !item.isActive,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchOperation() {
    yield takeEvery(ADD_OPERATION_REQUEST, workerAddOperation);
    yield takeEvery(EDIT_OPERATION_REQUEST, workerEditOperation);
    yield takeEvery(GET_OPERATION_GROUP_LIST_REQUEST, workerGetOperationGroup);
    yield takeEvery(GET_OPERATION_LIST_REQUEST, workerGetOperation);
    yield takeEvery(DELETE_OPERATION_REQUEST, workerDeleteOperation);
    yield takeEvery(GET_OPERATION_SEARCH_REQUEST, workerGetSearchOperation);
    yield takeEvery(DELETE_OPERATION_GROUP, workerDeleteOperationGroup);
}
