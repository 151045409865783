import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Icon, Input } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import className from 'classname';

import MyDropdown from './dropdown';
import { operationColor, operationSizes } from '../../../ducks/createOrders';
import ModalOrder from './modal';
import { getPositionCostOperation } from '../../../utils/functionHelper';
function PositionsOrder({
    addItem,
    value,
    copyingModel,
    deleteModel,
    addOperation,
    deleteOperation,
    handleChange,
    form,
    setVisible,
    disable,
}) {
    const optionSizes = useSelector(operationSizes);
    const optionColor = useSelector(operationColor);
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState(null);

    useEffect(() => {
        if (scroll) {
            const el = document.getElementById(`el-${scroll}`);
            el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
            setScroll(null);
        }
    }, [value]);

    return (
        value &&
        value.map((item, index) => (
            <div className="form-create__container" key={index} id={`el-${index}`}>
                <Grid className="form-create__grid">
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <h3 className="form-create__model">{item.model.name}</h3>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <p
                                className="form-create__button"
                                id={item.model.name}
                                onClick={() => {
                                    copyingModel(item);
                                    setScroll(index + 1);
                                }}
                            >
                                <Icon name="clone" />
                                Копировать
                            </p>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <p
                                className={className(
                                    'form-create__button',
                                    disable && item.id && 'form-create__disable',
                                )}
                                onClick={() => (disable && item.id ? '' : deleteModel(index))}
                            >
                                <Icon name="times circle outline" />
                                Удалить
                            </p>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <div className="form-create__visible" onClick={() => setVisible(index)}>
                                <Icon
                                    name={item.model.isVisible ? 'angle up' : 'angle down'}
                                    size="large"
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <p className="form-create__subtitle">Размер</p>
                            <Dropdown
                                value={item.size.name}
                                id={index}
                                name="size.name"
                                search
                                selection
                                fluid
                                allowAdditions
                                additionLabel="Добавить: "
                                onAddItem={addItem}
                                options={optionSizes}
                                onChange={handleChange}
                                placeholder="Выбрать"
                                className="form-create__input"
                                disabled={disable && item.id}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <p className="form-create__subtitle">Цвет</p>
                            <Dropdown
                                id={index}
                                value={item.color.name}
                                name="color.name"
                                search
                                selection
                                fluid
                                allowAdditions
                                additionLabel="Добавить: "
                                onAddItem={addItem}
                                options={optionColor}
                                onChange={handleChange}
                                placeholder="Выбрать цвет"
                                className="form-create__input"
                                disabled={disable && item.id}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <p className="form-create__subtitle">Количество</p>
                            <Input
                                id={index}
                                value={item.count}
                                type="number"
                                name="count"
                                onChange={handleChange}
                                placeholder="0"
                                className="form-create__input"
                                disabled={disable && item.id}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {item.model.operations &&
                        item.model.isVisible &&
                        item.model.operations.map((value, indexItem) => (
                            <>
                                <Grid.Row key={indexItem}>
                                    <Grid.Column width={12}>
                                        <p className="form-create__subtitle">Название операции</p>
                                        <MyDropdown
                                            name={`model.name.${indexItem}`}
                                            id={index}
                                            disabled={value.id}
                                            value={value.name}
                                            placeholder="Введите или выберите операцию"
                                            onChangeModel={handleChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <p className="form-create__subtitle">Время</p>
                                        <Input
                                            id={index}
                                            type="number"
                                            disabled={value.id}
                                            name={`model.duration.${indexItem}`}
                                            placeholder="0"
                                            onChange={handleChange}
                                            value={value.duration}
                                            className="form-create__input"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        {!value.id && indexItem > 0 && (
                                            <p
                                                className="form-create__delete"
                                                onClick={() => deleteOperation(index, indexItem)}
                                            >
                                                <Icon name="times circle outline" size="large" />
                                            </p>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                                <p className="form-create__сost">
                                    Стоимость операции:
                                    {getPositionCostOperation(
                                        value.duration,
                                        form.costPerSecond,
                                        item.count,
                                    )
                                        ? ` ${getPositionCostOperation(
                                              value.duration,
                                              form.costPerSecond,
                                              item.count,
                                          )}₽`
                                        : ' 0'}
                                </p>
                                {item.model.operations.length === indexItem + 1 && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <p
                                                className="form-create__button"
                                                onClick={() =>
                                                    value.id
                                                        ? setOpen(index + 1)
                                                        : addOperation(index)
                                                }
                                            >
                                                <Icon name="plus circle" />
                                                Добавить операцию
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </>
                        ))}
                </Grid>
                {open && (
                    <ModalOrder
                        copyingModel={copyingModel}
                        addItem={addItem}
                        item={value}
                        open={open}
                        setOpen={setOpen}
                        formValue={form}
                    />
                )}
            </div>
        ))
    );
}

export default PositionsOrder;
