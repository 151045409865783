import React, { useEffect, useMemo } from 'react';
import { Grid, Input, Modal, Button, Checkbox, TextArea, Icon, Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, FormikProvider, useFormik } from 'formik';

import { addModel, clearError, getLoading } from '../../../ducks/model';
import { getOperation, operationListOption, addDropItem } from '../../../ducks/operation';
import { getOperationList, getOperationModel, clearOperation } from '../../../ducks/model';

function ModalModel({ fetchData, modalData, closeModal }) {
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const optionOperation = useSelector(operationListOption);
    const operation = useSelector(getOperationModel);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: null,
            name: data.name ? `${data.name}-копия` : null,
            description: data ? data.description : null,
            isActive: data.name ? data.isActive : true,
            operations: data.operation ? data.operation : [{}],
        },
        onSubmit: (values) => {
            dispatch(
                addModel({
                    data: values,
                    callbackSuccess,
                }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const onChange = (event, { value, name }) => {
        let newValue;

        if (name === 'isActive') {
            newValue = !values.isActive;
        } else {
            newValue = value;
        }
        setFieldValue(name, newValue);
    };
    const { values, handleChange, handleSubmit, setFieldValue, handleReset } = formik;
    const isNext = useMemo(() => {
        return !values.name || [...values.operations].some((i) => !i.name || !i.duration);
    }, [values]);
    const addItem = (event, { name, value, id }) => {
        dispatch(addDropItem(value));
    };

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    useEffect(() => {
        dispatch(getOperation({ isActive: true, name: 'operationModel', pageSize: 10000 }));
    }, []);

    useEffect(() => {
        if (operation) {
            setFieldValue('operations', operation);
        }
    }, [operation]);

    useEffect(() => {
        if (data.name) {
            dispatch(getOperationList(data.id));
        } else {
            dispatch(clearOperation());
        }
    }, [data]);

    return (
        <Modal size="small" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {data.name ? 'Копирование модели' : 'Новая модель'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    placeholder="Название"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <TextArea
                                    name="description"
                                    type="text"
                                    value={values.description}
                                    placeholder="Описание"
                                    onChange={handleChange}
                                    className="modal__description"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    toggle
                                    onChange={onChange}
                                    className="modal__check"
                                    label={values.isActive ? 'Активна' : 'Неактивна'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="operations"
                                render={(arrayHelpers) => (
                                    <>
                                        {values.operations &&
                                            values.operations.length > 0 &&
                                            values.operations.map((operation, index) => (
                                                <Grid.Row key={index + operation.name}>
                                                    <Grid.Column width={16}>
                                                        <h4 className="modal__title--model">
                                                            {values.operations.length === index + 1
                                                                ? ' Новая операция'
                                                                : 'Операция'}
                                                        </h4>
                                                    </Grid.Column>
                                                    <Grid.Column width={11}>
                                                        <Dropdown
                                                            name={`operations.${index}.name`}
                                                            search
                                                            selection
                                                            fluid
                                                            allowAdditions
                                                            additionLabel="Добавить: "
                                                            onAddItem={addItem}
                                                            options={optionOperation}
                                                            onChange={onChange}
                                                            value={values.operations[index].name}
                                                            placeholder="Выбрать операцию"
                                                            className="form-create__input"
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={3}>
                                                        <Input
                                                            name={`operations.${index}.duration`}
                                                            type="number"
                                                            value={
                                                                values.operations[index].duration
                                                            }
                                                            placeholder="000"
                                                            onChange={handleChange}
                                                            className="modal__input"
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        {index > 0 && (
                                                            <p
                                                                className="modal__button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(index)
                                                                }
                                                            >
                                                                <Icon
                                                                    name="times circle outline"
                                                                    size="large"
                                                                />
                                                            </p>
                                                        )}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ))}
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p
                                                    className="modal__button"
                                                    onClick={() => arrayHelpers.push({})}
                                                >
                                                    <Icon name="plus circle" />
                                                    Добавить операцию
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                            />
                        </FormikProvider>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalModel;
