import React, { useState } from 'react';
import { Accordion, Checkbox, Icon, Input, Dropdown } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import className from 'classname';

import { productionLinesOption } from '../../ducks/productionLines';

const RoleAddAccordion = ({
    title,
    name,
    value = [],
    error,
    onChange,
    onAdd,
    onDelete,
    isOneDefault,
    onToggle,
}) => {
    const [isOpen, setIsOpen] = useState(isOneDefault);
    const stream = useSelector(productionLinesOption);
    const role = value[0] && value[0].role;

    const handleToggleItem = (event) => {
        setIsOpen(!value.length);
        onToggle();
    };

    return (
        <>
            <Accordion>
                <Checkbox
                    toggle
                    disabled={isOneDefault}
                    checked={value.length}
                    className="roles-and-personnel__cheak"
                    onClick={!(role === 'Producer' || role === 'Master') && handleToggleItem}
                />
                <Accordion.Title
                    id={0}
                    onClick={() => value.length && setIsOpen((open) => !open)}
                    active={isOpen}
                    icon="none"
                    className="roles-and-personnel__text"
                >
                    <span>{`${title} (${value.length})`}</span>
                </Accordion.Title>
                <Accordion.Content active={isOpen && value.length}>
                    <div className="roles-and-personnel__block-input">
                        {value.map((input, index) => (
                            <div key={input.id}>
                                <Input
                                    id={input.id}
                                    icon="user"
                                    iconPosition="left"
                                    className="roles-and-personnel__fio"
                                    placeholder="ФИО сотрудника"
                                    size="big"
                                    name={`name.${name}`}
                                    value={value[index].name}
                                    error={input.id === error.idRole}
                                    onChange={onChange}
                                />
                                {!(name === 'otc' || name === 'cutter') && (
                                    <Dropdown
                                        selection
                                        id={input.id}
                                        className="roles-and-personnel__stream"
                                        placeholder="Поток"
                                        name={`productionLineId.${name}`}
                                        value={value[index].productionLineId}
                                        options={stream}
                                        onChange={onChange}
                                    />
                                )}
                                <Input
                                    id={input.id}
                                    className={className(
                                        !(name === 'otc' || name === 'cutter')
                                            ? 'roles-and-personnel__kod'
                                            : 'roles-and-personnel__kod--margin',
                                    )}
                                    placeholder="Пин-код"
                                    size="big"
                                    type="number"
                                    name={`password.${name}`}
                                    value={value[index].password}
                                    onChange={onChange}
                                />
                                {value.length > 1 && (
                                    <Icon
                                        id={input.id}
                                        name="times circle outline"
                                        className="roles-and-personnel__button-delete"
                                        size="large"
                                        onClick={(event) => onDelete(event, input.id)}
                                    />
                                )}
                            </div>
                        ))}
                        <button className="roles-and-personnel__button-add" onClick={onAdd}>
                            <p>
                                <Icon name="add circle" size="large" />
                                Добавить специалиста
                            </p>
                        </button>
                    </div>
                </Accordion.Content>
            </Accordion>
            <hr className="roles-and-personnel__hr" />
        </>
    );
};

export default RoleAddAccordion;
