import React, { useCallback, useEffect, useMemo } from 'react';
import { Input, Dropdown, Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { parseISO, format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import {
    getOptionList,
    customerList,
    modelsList,
    addCustomers,
    getOperation,
    operation,
    getOptionModelList,
    addDropDown,
    newModel,
} from '../../../ducks/createOrders';
import HintInput from './hintInput';
import PositionsOrder from './positionsOrder';
import { productionLinesOption } from '../../../ducks/productionLines';

function FormCreate({ form, setForm, disable }) {
    const dispatch = useDispatch();
    const optionCustomers = useSelector(customerList);
    const optionModels = useSelector(modelsList);
    const operationList = useSelector(operation);
    const streams = useSelector(productionLinesOption);

    const addNewCustomers = useCallback((event, { name, value, id }) => {
        dispatch(addCustomers(value));
    }, []);

    const addItem = useCallback((event, { name, value, id }) => {
        let [input] = name.split('.');

        if (input === 'model') {
            input = 'listOperation';
        }

        dispatch(addDropDown({ name: input, value: value }));
    }, []);

    const handleChange = (event, { name, value, id }) => {
        let newValue;

        if (name === 'costPerSecond') {
            const [first, two] = value.split('.');

            if (two && two.length > 2) {
                return;
            } else {
                newValue = !isNaN(value) ? (value > 100 ? 100 : +value > 0 ? +value : '') : +value;
            }
        } else if (name === 'startDate') {
            newValue = value ? format(value, "yyyy-MM-dd'T'HH:mm") : null;
        } else if (name === 'number') {
            newValue = `${value}`;
        } else {
            newValue = !isNaN(value) ? (+value > 0 ? +value : '') : value;
        }

        setForm(
            Object.assign({}, form, {
                [name]: newValue,
            }),
        );
    };

    const handleChangeModel = (event, { name, value, id }) => {
        const newOperation = [...form.positions];
        const [object, input, index] = name.split('.');

        if (input && index) {
            newOperation[id][object].operations[index][input] =
                !isNaN(value) && input === 'duration' ? (+value > 0 ? +value : '') : value;
        } else if (input) {
            newOperation[id][object][input] = value;
        } else {
            newOperation[id][name] = +value > 0 ? +value : '';
        }

        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const deleteModel = (index) => {
        const newOperation = [...form.positions];

        newOperation.splice(index, 1);
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const deleteOperation = (index, indexItem) => {
        const newOperation = [...form.positions];

        newOperation[index].model.operations.splice(indexItem, 1);
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const copyingModel = (value) => {
        const newOperation = [...form.positions];
        const newValue = JSON.parse(JSON.stringify(value));

        delete newValue.id;
        newOperation.unshift(newValue);
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const addOperation = (index) => {
        const newOperation = [...form.positions];

        newOperation[index].model.operations = [...form.positions[index].model.operations, {}];
        setForm(Object.assign({}, form, { positions: newOperation }));
    };
    const addPosition = (event, { value }) => {
        if (event._reactName === 'onBlur') {
            return;
        }

        const item = optionModels.find((i) => i.value === value);
        const newOperation = [...form.positions];

        if (item) {
            if (!item.noBack) {
                dispatch(getOperation(item));
                newOperation.unshift({
                    model: {
                        id: item.id,
                        name: item.text,
                        operations: [],
                        isVisible: true,
                    },
                    size: {
                        name: null,
                    },
                    color: {
                        name: null,
                    },
                    count: '',
                });
            } else {
                const noBackModel = form.positions.find((el) => el.model.id === item.id);

                newOperation.unshift({
                    model: {
                        id: item.id,
                        name: item.text,
                        operations: noBackModel.model.operations,
                        isVisible: true,
                    },
                    size: {
                        name: null,
                    },
                    color: {
                        name: null,
                    },
                    count: '',
                });
            }

            setForm(Object.assign({}, form, { positions: newOperation }));
        }
    };

    const createNewModel = (event, { value }) => {
        const newOperation = [...form.positions];
        const id = uuidv4();

        newOperation.unshift({
            model: {
                id: id,
                name: value,
                isVisible: true,
                noBack: true,
                operations: [
                    {
                        name: null,
                        duration: '',
                    },
                ],
            },
            size: {
                name: null,
            },
            color: {
                name: null,
            },
            count: '',
        });
        dispatch(newModel({ id, name: value }));
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const getDate = () => {
        if (form.startDate) {
            return parseISO(form.startDate);
        }
    };
    const setVisible = (index) => {
        const newOperation = [...form.positions];

        newOperation[index].model.isVisible = !newOperation[index].model.isVisible;

        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    useMemo(() => {
        const newOperation = [...form.positions];

        if (newOperation[0] && newOperation[0].model.id && !newOperation[0].model.noBack) {
            newOperation[0].model.operations = operationList;
            setForm(Object.assign({}, form, { positions: newOperation }));
        }
    }, [operationList]);

    useEffect(() => {
        dispatch(getOptionList());
        dispatch(getOptionModelList());
    }, []);

    return (
        <div className="form-create">
            <div className="form-create__body">
                <h2 className="form-create__title">Заказчик</h2>
                <Dropdown
                    placeholder="Выберите или укажите заказчика"
                    className="form-create__input-name"
                    search
                    selection
                    fluid
                    allowAdditions
                    additionLabel="Добавить: "
                    name="customerId"
                    value={form.customerId}
                    options={optionCustomers}
                    onAddItem={addNewCustomers}
                    onChange={handleChange}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <span className="form-create__text">Поток</span>
                            <Dropdown
                                className="form-create__stream"
                                fluid
                                search
                                selection
                                placeholder="Выберите поток"
                                options={streams}
                                onChange={handleChange}
                                name="productionLineId"
                                value={form.productionLineId}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <span className="form-create__text">Номер заказа</span>
                            <Input
                                placeholder="1"
                                className="form-create__number"
                                size="large"
                                name="number"
                                value={form.number}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <span className="form-create__text">Начало выполнения заказа</span>
                            <SemanticDatepicker
                                locale="ru-RU"
                                format="DD.MM.YYYY"
                                onChange={handleChange}
                                className="form-create__stream"
                                iconPosition="left"
                                icon="calendar alternate"
                                placeholder="00.00.0000"
                                name="startDate"
                                value={getDate()}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <span className="form-create__text">
                                Стандартная стоимость <br /> секунды производства, коп
                            </span>
                            <Input
                                type="number"
                                placeholder="0"
                                className="form-create__input-second-price"
                                size="large"
                                name="costPerSecond"
                                value={form.costPerSecond}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <span className="form-create__text">Стоимость заказа</span>
                            <Input
                                type="number"
                                placeholder="0"
                                className="form-create__input-price"
                                size="large"
                                name="cost"
                                value={form.cost}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <h2 className="form-create__title">Состав заказа</h2>
                <Dropdown
                    placeholder="Выберите модель"
                    className="form-create__input-name"
                    search
                    selection
                    value={null}
                    fluid
                    allowAdditions
                    closeOnBlur
                    closeOnEscape
                    additionLabel="Добавить: "
                    name="model"
                    onChange={addPosition}
                    onAddItem={createNewModel}
                    options={optionModels}
                />
                {form.positions && form.positions.length > 0 ? (
                    <PositionsOrder
                        addItem={addItem}
                        form={form}
                        value={form.positions}
                        copyingModel={copyingModel}
                        deleteModel={deleteModel}
                        addOperation={addOperation}
                        deleteOperation={deleteOperation}
                        handleChange={handleChangeModel}
                        setVisible={setVisible}
                        disable={disable}
                    />
                ) : (
                    <HintInput />
                )}
            </div>
        </div>
    );
}

export default FormCreate;
