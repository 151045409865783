import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Modal, Button, Dropdown, Input, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { getOperationGroupId, getOrder, setOrderOperations } from '../../../ducks/createOrders';
import { getLoading, operationGroupList } from '../../../ducks/operation';

function ModalOperationGroupId({
    open,
    closeModal,
    model,
    personalOptions,
    streams,
    idOrder,
    idPosition,
    maxCount,
    id,
}) {
    const dispatch = useDispatch();
    const operationGroup = useSelector(operationGroupList);
    const loading = useSelector(getLoading);
    const [data, setData] = useState({
        arrayOperationGroup: [],
        arrayOperation: [],
        requiredCount: '',
        productionLineId: '',
        producerId: '',
    });
    const [error, setError] = useState('');

    const onClose = () => {
        closeModal();
    };

    const onClickSave = (ev) => {
        ev.stopPropagation();
        const arrayOperation = model.operations.filter((item) =>
            data.arrayOperation.find((el) => item.id === el),
        );
        const arrayOperationGroup = model.operations.filter((item) =>
            data.arrayOperationGroup.find((el) => item.id === el),
        );

        if (
            [...arrayOperation, ...arrayOperationGroup].find(
                (item) => item.maxValue < data.requiredCount,
            )
        ) {
            setError('Превышено максимальное кол-во');
            return;
        }
        dispatch(
            setOrderOperations({
                operationId: [...data.arrayOperation, ...data.arrayOperationGroup],
                producerId: data.producerId,
                requiredCount: data.requiredCount,
                productionLineId: data.productionLineId,
                idOrder,
                idPosition,
                updateData,
            }),
        );
    };

    const updateData = () => {
        dispatch(getOrder(id));
        closeModal();
    };

    const onChangeSelect = (event, { name, value }) => {
        if (name === 'producerId' || name === 'productionLineId') {
            setData(Object.assign({}, data, { [name]: value }));
            return;
        }
        if (name === 'operation') {
            setData(
                Object.assign({}, data, {
                    arrayOperation: [...data.arrayOperation, value],
                }),
            );
            return;
        }
        const arrayOperation = operationGroup.find((item) => item.id === value);

        setData(
            Object.assign({}, data, {
                [name]: arrayOperation.modelOperationIds,
                arrayOperation: [],
            }),
        );
    };

    const onClickDeleteOperation = (name, id) => {
        if (name === 'operation') {
            setData(
                Object.assign({}, data, {
                    arrayOperation: data.arrayOperation.filter((item) => item !== id),
                }),
            );
            return;
        }

        setData(
            Object.assign({}, data, {
                arrayOperationGroup: [],
            }),
        );
    };

    const isNext = useMemo(() => {
        return !(
            (data.arrayOperationGroup?.length || data.arrayOperation?.length) &&
            data?.requiredCount &&
            data?.producerId &&
            data?.productionLineId
        );
    }, [data]);

    const onChangeInput = (event, { name, value }) => {
        setData(
            Object.assign({}, data, {
                [name]: +value > 0 ? (+value > maxCount ? maxCount : +value) : 0,
            }),
        );
    };

    const operationGroupRender = useMemo(() => {
        return model?.operations?.filter((item) => data.arrayOperationGroup.includes(item?.id));
    }, [data?.arrayOperationGroup, model?.operations]);

    const operationRender = useMemo(() => {
        return model?.operations?.filter((item) => data.arrayOperation.includes(item?.id));
    }, [data?.arrayOperation, model?.operations]);

    const optionOperation = useMemo(() => {
        return model?.operations
            ?.filter(
                (item) =>
                    !data.arrayOperationGroup.includes(item?.id) &&
                    !data.arrayOperation.includes(item?.id),
            )
            .map((item) => {
                return {
                    ...item,
                    value: item.id,
                    text: item.name,
                };
            });
    }, [data?.arrayOperationGroup, model?.operations, data?.arrayOperation]);

    function setEvent() {
        setError(null);
    }

    useEffect(() => {
        if (model?.id) {
            dispatch(getOperationGroupId(model?.id));
        }
    }, [model?.id]);

    useEffect(() => {
        document.addEventListener('click', setEvent);
        return () => document.removeEventListener('click', setEvent);
    }, []);

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">Назанчение группы операций</h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="modal__subtext">Сотрудник</span>
                                <div>
                                    <Dropdown
                                        className="modal__textfield"
                                        placeholder="Выберите сотрудника"
                                        search
                                        selection
                                        fluid
                                        options={personalOptions}
                                        onChange={onChangeSelect}
                                        value={data.producerId}
                                        name="producerId"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <span className="modal__subtext">Поток</span>
                                <Dropdown
                                    className="modal__textfield"
                                    placeholder="Поток"
                                    search
                                    selection
                                    fluid
                                    options={streams}
                                    onChange={onChangeSelect}
                                    value={data.productionLineId}
                                    name="productionLineId"
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <span className="modal__subtext">Кол-во</span>
                                <Input
                                    className="modal__textfield"
                                    placeholder="0"
                                    onChange={onChangeInput}
                                    value={data.requiredCount}
                                    type="number"
                                    name="requiredCount"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {!data?.arrayOperationGroup?.length && (
                                <Grid.Column>
                                    <span className="modal__subtext">Группа операций</span>
                                    <Dropdown
                                        placeholder="Выберите группу операций"
                                        className="modal__textfield"
                                        search
                                        selection
                                        value={null}
                                        fluid
                                        closeOnBlur
                                        closeOnEscape
                                        name="arrayOperationGroup"
                                        onChange={onChangeSelect}
                                        options={operationGroup}
                                    />
                                </Grid.Column>
                            )}
                            {!!operationGroupRender?.length && (
                                <div className="modal__section">
                                    <div
                                        className="modal__sectionDeleteAlt"
                                        onClick={onClickDeleteOperation}
                                    >
                                        <Icon name="trash alternate outline" />
                                    </div>
                                    {operationGroupRender?.map((item) => (
                                        <Grid.Column key={item.id}>
                                            <div className="modal__sectionText">{item.name}</div>
                                        </Grid.Column>
                                    ))}
                                </div>
                            )}
                        </Grid.Row>
                        {operationRender?.map((item) => (
                            <div key={item.id} className="modal__sectionTextAlt">
                                {item.name}
                                <div
                                    className="modal__sectionDelete"
                                    onClick={() => onClickDeleteOperation('operation', item.id)}
                                >
                                    <Icon name="trash alternate outline" />
                                </div>
                            </div>
                        ))}
                        <Grid.Row>
                            <Grid.Column>
                                <span className="modal__subtext">Операции</span>
                                <div>
                                    <Dropdown
                                        className="modal__textfield"
                                        placeholder="Выберите Операцию"
                                        search
                                        selection
                                        fluid
                                        options={optionOperation}
                                        onChange={onChangeSelect}
                                        value={null}
                                        name="operation"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            {error && <div className="modal__errorSelection">{error}</div>}
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    onClick={onClickSave}
                                    type="button"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalOperationGroupId;
