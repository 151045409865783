import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Input, Dropdown, Grid } from 'semantic-ui-react';

import { getPersonalList, userList, deletePersonal } from '../../ducks/user';
import { productionLinesOption, getProductionLines } from '../../ducks/productionLines';
import { countryStatusIsActive, countryRole, configTableUser } from '../../utils/const';
import ModalUser from './components/modal';
import useDebounce from '../../hooks/useDebounce';
import DefaultTable from '../../components/DefaultTable';
import ModalDelete from '../../components/ModalDelete';

import './style.scss';

function UserList() {
    const dispatch = useDispatch();
    const user = useSelector(userList);
    const stream = useSelector(productionLinesOption);
    const [modalData, setModalData] = useState({ open: false, data: {} });
    const [modal, setModal] = useState({ isVisible: false, id: null, name: null });
    const [filter, setFilter] = useState({
        roles: [],
        nameSubstring: null,
        pageSize: 20,
        productionLineId: null,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const onClickCreate = useCallback(() => {
        setModalData(Object.assign({}, modalData, { open: true }));
    }, []);
    const onClickEdit = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };
    function fetchData() {
        dispatch(getPersonalList(debouncedFilter));
    }
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const onClickDelete = () => {
        dispatch(deletePersonal({ id: modal.id, callbackSuccess: fetchData }));
        setModal(Object.assign({}, modal, { isVisible: false, number: null, id: null }));
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && user.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };
    const deleteOrder = (item) => {
        setModal(
            Object.assign({}, modal, {
                isVisible: !modal.isVisible,
                id: item.key,
                name: item.FIO,
            }),
        );
    };

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <div className="user-table">
            <div className="filter">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <h1 className="filter__title">Сотрудники</h1>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <button className="filter__button" onClick={onClickCreate}>
                                <p>
                                    <Icon name="plus" size="small" />
                                    Добавить сотрудника
                                </p>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3} className="filter__text">
                            ФИО
                            <Input
                                placeholder="ФИО сотрудника"
                                className="filter__input"
                                onChange={handleChange}
                                name="nameSubstring"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Роль
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                multiple
                                placeholder="Роль сотрудника"
                                options={countryRole}
                                name="roles"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Статус
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Выберите статус"
                                options={countryStatusIsActive}
                                name="isActive"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Поток
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Выберите поток"
                                options={stream}
                                name="productionLineId"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <DefaultTable
                handleUpdate={handleUpdate}
                essence={user}
                onClickDelete={deleteOrder}
                onClickEdit={onClickEdit}
                config={configTableUser}
            />
            {modalData.open && (
                <ModalUser
                    fetchData={fetchData}
                    modalData={modalData}
                    stream={stream}
                    closeModal={closeModal}
                    handleUpdate={handleUpdate}
                />
            )}
            {modal.isVisible && (
                <ModalDelete
                    closeModal={deleteOrder}
                    onClickDelete={onClickDelete}
                    modal={modal}
                    text={`пользователя ${modal.name}`}
                />
            )}
        </div>
    );
}

export default UserList;
