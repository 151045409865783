import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { parseISO, format } from 'date-fns';

import { addStatus } from '../../utils/functionHelper';

import './style.scss';

function HeaderOrder({ onSubmit, isNext, status, name, customer, number }) {
    return (
        <div className="header">
            <div className="header__body">
                <div className="header__left">
                    <h1 className="header__title">
                        {name
                            ? `Заказ №${name.number} от ${format(
                                  parseISO(name.date),
                                  'dd.MM.yyyy',
                              )}`
                            : `Заказ №${number} от ${format(new Date(), 'dd.MM.yyyy')}`}
                    </h1>
                    {customer ? addStatus(status, customer) : addStatus(status)}
                </div>
                <div className="header__right">
                    <Button onClick={onSubmit} disabled={isNext}>
                        Сохранить
                    </Button>
                    <Button basic inverted className="header__button">
                        <Icon name="ellipsis vertical" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default HeaderOrder;
