import React from 'react';
import { format, parseISO } from 'date-fns';
import { Icon } from 'semantic-ui-react';

import { countryStatus, countryStatusIsActive, MANAGER, USER_ROLES } from './const';

export function addStatus(str, customer) {
    const item = countryStatus.filter((i) => i.value === str)[0];
    return (
        item && (
            <pre>
                <a className={`status${item.css}`} />
                {customer ? `${item.text}        |        Заказчик: ${customer}` : `${item.text}`}
            </pre>
        )
    );
}

export function convertingObject(arr = []) {
    let newArr = [];
    let isArrNull = true;

    for (let key in arr) {
        if (arr[key].income === 0) {
            newArr.push([arr[key].day, null]);
        } else {
            newArr.push([arr[key].day, arr[key].income]);
            isArrNull = false;
        }
    }

    if (isArrNull) {
        newArr = newArr.map((el) => {
            return [el[0], 0];
        });
    }

    return newArr;
}

export function setOptionsId(arr) {
    return arr.map((value) => {
        return {
            id: value.id,
            value: value.id,
            text: value.name,
        };
    });
}
export function calculationDays({ start, end }) {
    let arrDay = [];

    for (let i = start; i !== end; i++) {
        arrDay.push({ value: i });
    }

    return arrDay;
}

export function numberDays(start, end) {
    let one_day = 1000 * 60 * 60 * 24;

    return Math.ceil((end.getTime() - start.getTime()) / one_day);
}

export function cleverCalculationDays(start, end) {
    if (start) {
        let arrDay = [];
        let [dayStart, monthStart, yearStart] = start.split('.');

        while (format(new Date(yearStart, monthStart - 1, dayStart - 1, 0), 'dd.MM.yyyy') !== end) {
            arrDay.push({
                month: new Date(yearStart, monthStart - 1, dayStart, 0).getMonth(),
                day: new Date(yearStart, monthStart - 1, dayStart, 0).getDate(),
                year: new Date(yearStart, monthStart - 1, dayStart, 0).getFullYear(),
                full: format(new Date(yearStart, monthStart - 1, dayStart, 0), 'dd.MM.yyyy'),
            });
            dayStart++;
        }

        return arrDay;
    }
}

export function getColor(number) {
    if (number % 3 === 0) {
        return 'blue';
    } else if (number % 2 === 0) {
        return 'pink';
    } else {
        return 'azure';
    }
}

export function getName() {
    const url = window.location.href;

    return url.split('.')[0].split('/')[2];
}

export function calculationYears(years) {
    let listYears = [];

    for (let i = years; i !== 2018; i--) {
        listYears.push({ value: i, key: i, text: i });
    }

    return listYears;
}

export function addStatusIsActive(str) {
    const item = countryStatusIsActive.filter((i) => i.value === str)[0];
    return (
        item && (
            <pre>
                <a className={`status${item.css}`} />
                {item.text}
            </pre>
        )
    );
}

export function setOptionsName(arr) {
    return arr.map((value) => {
        return {
            id: value.id,
            value: value.name,
            text: value.name,
        };
    });
}

export function ls(key, value) {
    let _value = value;

    if (typeof value === 'object') {
        _value = JSON.stringify(_value);
    }

    if (arguments.length === 2) {
        localStorage.setItem(key, _value);
    } else {
        return localStorage.getItem(key);
    }
}

export function dateParseAndFormat(date) {
    if (date) {
        return format(parseISO(date), 'dd.MM.yyyy');
    }
}

export function dateFormatDD(date) {
    return format(date, 'dd.MM.yyyy');
}

export function dateFormatMM(date) {
    return format(date, 'MM-dd-yyyy');
}

export function renderHelper(number) {
    const arrRender = [];

    for (let i = number; i > 0; i--) {
        arrRender.push([i]);
    }

    return arrRender;
}

export function renderIcon(role, onClickEditDistribution, deleteOrder, onClickEdit, item) {
    switch (role) {
        case USER_ROLES.MASTER:
            return (
                <Icon
                    onClick={(ev) => onClickEditDistribution(ev, item.key)}
                    name="list"
                    size="large"
                    className="table-order__img"
                />
            );
        case USER_ROLES.OPERATOR:
            return (
                <>
                    <Icon
                        onClick={(ev) => deleteOrder(ev, item)}
                        name="times circle outline"
                        className="table-order__img"
                        size="large"
                    />
                    <Icon
                        onClick={(ev) => onClickEdit(ev, item.key)}
                        name="pencil alternate"
                        size="large"
                        className="table-order__img"
                    />
                </>
            );
        case MANAGER:
            return (
                <>
                    <Icon
                        onClick={(ev) => deleteOrder(ev, item)}
                        name="times circle outline"
                        className="table-order__img"
                        size="large"
                    />
                    <Icon
                        onClick={(ev) => onClickEditDistribution(ev, item.key)}
                        name="list"
                        size="large"
                        className="table-order__img"
                    />
                    <Icon
                        onClick={(ev) => onClickEdit(ev, item.key)}
                        name="pencil alternate"
                        size="large"
                        className="table-order__img"
                    />
                </>
            );
    }
}

export function getPositionCostString(duration, costPerSecond, count) {
    const totalSeconds = duration * count;
    let secondsString = '';

    if (totalSeconds >= 3600) {
        secondsString = `${Math.floor(totalSeconds / 3600)}ч ${Math.round(
            (totalSeconds % 3600) / 60,
        )}мин.`;
        return `${secondsString}, ${(costPerSecond * totalSeconds) / 100}₽`;
    }

    if (totalSeconds > 60) {
        secondsString = `${Math.round(totalSeconds / 60)}мин.`;
        return `${secondsString}, ${(costPerSecond * totalSeconds) / 100}₽`;
    }

    secondsString = totalSeconds + ' сек.';
    return `${secondsString}, ${(costPerSecond * totalSeconds) / 100}₽`;
}

export function getPositionCostOperation(duration, costPerSecond, count) {
    const totalSeconds = duration * count;

    return (costPerSecond * totalSeconds) / 100;
}
