import React from 'react';
import { Icon, Table, Visibility } from 'semantic-ui-react';

import { userRoleRus } from '../../utils/const';
import { addStatusIsActive } from '../../utils/functionHelper';

import './style.scss';

function DefaultTable({
    handleUpdate,
    essence,
    onClickDelete,
    onClickEdit,
    config,
    firstIcon = 'pencil alternate',
    secondaryIcon = 'times circle outline',
}) {
    return (
        <Visibility onUpdate={handleUpdate}>
            <Table basic className="table" fixed>
                <Table.Header>
                    <Table.Row>
                        {config &&
                            config.map(({ title }) => (
                                <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
                            ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {essence &&
                        essence.map((item) => (
                            <Table.Row key={item.key}>
                                {config.map(({ key, width }) => (
                                    <Table.Cell width={width} key={key}>
                                        {key ? (
                                            key === 'isActive' ? (
                                                addStatusIsActive(item.isActive)
                                            ) : key === 'role' ? (
                                                userRoleRus[item[key]]
                                            ) : (
                                                item[key]
                                            )
                                        ) : (
                                            <>
                                                <Icon
                                                    onClick={() => onClickDelete(item)}
                                                    name={secondaryIcon}
                                                    className="table__img"
                                                    size="large"
                                                />
                                                <Icon
                                                    onClick={() => onClickEdit(item)}
                                                    name={firstIcon}
                                                    size="large"
                                                    className="table__img"
                                                />
                                            </>
                                        )}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </Visibility>
    );
}

export default DefaultTable;
