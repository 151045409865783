import React from 'react';
import { Input } from 'semantic-ui-react';

function InputInformation({ form, handleChange }) {
    return (
        <>
            <p className="information__text">Наименование Вашей организации</p>
            <Input
                placeholder="Введите наименование организации"
                className="information__input-name"
                size="big"
                name="name"
                value={form.name}
                onChange={handleChange}
            />
            <span className="information__subtext">
                Стандартная стоимость <br /> секунды производства, коп.
            </span>
            <Input
                type="number"
                placeholder="100"
                className="information__input-price"
                size="large"
                name="price"
                value={form.price}
                onChange={handleChange}
            />
        </>
    );
}

export default InputInformation;
