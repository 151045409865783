import { put, takeEvery, call, all, fork } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { setOptionsId, setOptionsName } from '../utils/functionHelper';
import { GET_OPERATION_GROUP_LIST_SUCCESS } from './operation';

//*               /Colors /Customers /Models /Operations /Orders /Settings /Sizes /OrderPositions /OrderOperations           *//

//*  TYPES  *//

export const ADD_DROPDOWN = 'ADD_DROPDOWN';

export const GET_OPERATION_GROUP_REQUEST_ID = 'GET_OPERATION_GROUP_REQUEST_ID';

export const PUT_OPERATION_GROUP_REQUEST = 'PUT_OPERATION_GROUP_REQUEST';
export const POST_OPERATION_GROUP_REQUEST = 'POST_OPERATION_GROUP_REQUEST';

export const GET_OPTION_LIST_REQUEST_ALT = 'GET_OPTION_LIST_REQUEST_ALT';

export const GET_OPTION_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST';
export const GET_OPTION_LIST_SUCCESS = 'GET_OPTION_LIST_SUCCESS';

export const SET_ADD_CUSTOMERS_REQUEST = 'SET_ADD_CUSTOMERS_REQUEST';
export const GET_ADD_CUSTOMERS_SUCCESS = 'GET_ADD_CUSTOMERS_SUCCESS';

export const GET_OPERATION_REQUEST = 'GET_OPERATION_REQUEST';
export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';

export const GET_OPTION_MODEL_LIST_REQUEST = 'GET_OPTION_MODEL_LIST_REQUEST';
export const GET_OPTION_MODEL_LIST_SUCCESS = 'GET_OPTION_MODEL_LIST_SUCCESS';

export const SET_NEW_MODEL_REQUEST = 'SET_NEW_MODEL_REQUEST';
export const SET_NEW_MODEL_SUCCESS = 'SET_NEW_MODEL_SUCCESS';

export const SET_NEW_ORDER_REQUEST = 'SET_NEW_ORDER_REQUEST';
export const SET_NEW_ORDER_SUCCESS = 'SET_NEW_ORDER_SUCCESS';
export const SET_NEW_ORDER_ERROR = 'SET_NEW_ORDER_ERROR';

export const SET_EDIT_ORDER_REQUEST = 'SET_EDIT_ORDER_REQUEST';
export const SET_EDIT_ORDER_SUCCESS = 'SET_EDIT_ORDER_SUCCESS';
export const SET_EDIT_ORDER_ERROR = 'SET_EDIT_ORDER_ERROR';

export const SET_ADD_ORDER_OPERATIONS_REQUEST = 'SET_ADD_ORDER_OPERATIONS_REQUEST';
export const SET_ADD_ORDER_OPERATIONS_SUCCESS = 'SET_ADD_ORDER_OPERATIONS_SUCCESS';
export const SET_ADD_ORDER_OPERATIONS_ERROR = 'SET_ADD_ORDER_OPERATIONS_ERROR';

export const SET_DELETE_ORDER_OPERATIONS_REQUEST = 'SET_DELETE_ORDER_OPERATIONS_REQUEST';
export const SET_DELETE_ORDER_OPERATIONS_SUCCESS = 'SET_DELETE_ORDER_OPERATIONS_SUCCESS';
export const SET_DELETE_ORDER_OPERATIONS_ERROR = 'SET_DELETE_ORDER_OPERATIONS_ERROR';

export const ADD_NEW_MODEL_CREATE = 'ADD_NEW_MODEL_CREATE';

export const CLEAR_ERROR_ORDER = 'CLEAR_ERROR_ORDER';

//*  INITIAL STATE  *//

const initial = {
    customers: [],
    models: [],
    createData: {},
    operation: null,
    listOperation: [],
    size: [],
    color: [],
    error: null,
    errorOrder: null,
    order: {
        number: '',
        status: 'Incomplete',
        customerId: null,
        costPerSecond: null,
        cost: null,
        positions: [],
    },
};

//*  REDUCER  *//

export default function createOrder(state = initial, action) {
    switch (action.type) {
        case ADD_DROPDOWN:
            const { name, value } = action.payload;

            return {
                ...state,
                [name]: [{ name: value }, ...state[name]],
            };
        case CLEAR_ERROR_ORDER:
            return {
                ...state,
                errorOrder: null,
            };
        case SET_EDIT_ORDER_ERROR:
        case SET_NEW_ORDER_ERROR:
            return {
                ...state,
                errorOrder: action.payload,
            };
        case SET_DELETE_ORDER_OPERATIONS_ERROR:
        case SET_ADD_ORDER_OPERATIONS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case GET_OPTION_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case GET_ADD_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
            };
        case GET_OPERATION_SUCCESS:
            return {
                ...state,
                operation: action.payload,
            };
        case ADD_NEW_MODEL_CREATE:
            return {
                ...state,
                models: [...state.models, { ...action.payload, noBack: true }],
            };
        case GET_OPTION_MODEL_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case GET_ORDER_SUCCESS:
            const { responseOrder, responseOperation } = action.payload;

            return {
                ...state,
                error: false,
                order: {
                    ...responseOrder,
                    customerId: responseOrder.customer.id,
                    positions: [...responseOperation],
                },
            };
        case SET_DELETE_ORDER_OPERATIONS_SUCCESS:
        case SET_ADD_ORDER_OPERATIONS_SUCCESS:
            return {
                ...state,
            };
        default:
            return state;
    }
}
//*  ACTION CREATORS  *//

export const setDeleteOrderOperations = (payload) => {
    return {
        type: SET_DELETE_ORDER_OPERATIONS_REQUEST,
        payload,
    };
};

export const clearErrorOrder = () => {
    return {
        type: CLEAR_ERROR_ORDER,
    };
};

export const newModel = (payload) => {
    return {
        type: ADD_NEW_MODEL_CREATE,
        payload,
    };
};

export const setOrderOperations = (payload) => {
    return {
        type: SET_ADD_ORDER_OPERATIONS_REQUEST,
        payload,
    };
};

export const addNewModel = (payload) => {
    return {
        type: SET_NEW_MODEL_REQUEST,
        payload,
    };
};

export const addDropDown = (payload) => {
    return {
        type: ADD_DROPDOWN,
        payload,
    };
};

export const getOrder = (payload) => {
    return {
        type: GET_ORDER_REQUEST,
        payload,
    };
};

export const addEditOrder = (payload) => {
    return {
        type: SET_EDIT_ORDER_REQUEST,
        payload,
    };
};

export const addNewOrder = (payload) => {
    return {
        type: SET_NEW_ORDER_REQUEST,
        payload,
    };
};

export const addCustomers = (payload) => {
    return {
        type: SET_ADD_CUSTOMERS_REQUEST,
        payload,
    };
};

export const postOperationGroup = (payload) => {
    return {
        type: POST_OPERATION_GROUP_REQUEST,
        payload,
    };
};

export const putOperationGroup = (payload) => {
    return {
        type: PUT_OPERATION_GROUP_REQUEST,
        payload,
    };
};

export const getOptionList = () => {
    return {
        type: GET_OPTION_LIST_REQUEST,
    };
};

export const getOptionListAlt = () => {
    return {
        type: GET_OPTION_LIST_REQUEST_ALT,
    };
};

export const getOperationGroupId = (payload) => {
    return {
        type: GET_OPERATION_GROUP_REQUEST_ID,
        payload,
    };
};

export const getOperation = (payload) => {
    return {
        type: GET_OPERATION_REQUEST,
        payload,
    };
};

export const getOptionModelList = () => {
    return {
        type: GET_OPTION_MODEL_LIST_REQUEST,
    };
};

//*  SELECTORS *//

export const baseCreateOrderState = (state) => state.createOrder;

export const createData = createSelector(
    [baseCreateOrderState],
    (state) => state.createData && state.createData,
);
export const order = createSelector([baseCreateOrderState], (state) => state.order);

export const operation = createSelector(
    [baseCreateOrderState],
    (data) =>
        data.operation &&
        data.operation.map((value) => {
            return {
                id: value.id,
                name: value.name,
                duration: value.duration,
            };
        }),
);

export const getErrorOrder = createSelector([baseCreateOrderState], (state) => state.errorOrder);

export const getError = createSelector([baseCreateOrderState], (state) => state.error);

export const customerList = createSelector(
    [baseCreateOrderState],
    (data) => data.customers && setOptionsId(data.customers),
);
export const operationList = createSelector(
    [baseCreateOrderState],
    (data) => data.listOperation && setOptionsName(data.listOperation),
);
export const getListOperation = createSelector(
    [baseCreateOrderState],
    (data) => data.listOperation && data.listOperation.map((item) => item.name),
);

export const operationSizes = createSelector(
    [baseCreateOrderState],
    (data) => data.size && setOptionsName(data.size),
);
export const operationColor = createSelector(
    [baseCreateOrderState],
    (data) => data.color && setOptionsName(data.color),
);
export const modelsList = createSelector(
    [baseCreateOrderState],
    (data) =>
        data.models &&
        data.models.map((item) => {
            return {
                id: item.id,
                value: item.id,
                text: item.name,
                noBack: item.noBack,
            };
        }),
);

//*  SAGA  *//

function* workerGetCustomerListAlt() {
    try {
        const { value: costPerSecond } = yield postman.get(`/Settings/CostPerSecond`);
        const { items: models } = yield postman.get(`/Models`, {
            params: {
                isActive: true,
                pageSize: 10000,
            },
        });

        yield put({
            type: GET_OPTION_LIST_SUCCESS,
            payload: {
                models,
                createData: {
                    costPerSecond,
                },
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetCustomerList() {
    try {
        const { items: customers } = yield postman.get(`/Customers`, {
            params: {
                isActive: true,
                pageSize: 10000,
            },
        });
        const { value: number } = yield postman.post('/Settings/OrderCounter', {});
        const { value: costPerSecond } = yield postman.get(`/Settings/CostPerSecond`);
        const { items: models } = yield postman.get(`/Models`, {
            params: {
                isActive: true,
                pageSize: 10000,
            },
        });

        yield put({
            type: GET_OPTION_LIST_SUCCESS,
            payload: {
                customers,
                models,
                createData: {
                    number,
                    costPerSecond,
                },
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetOperation({ payload }) {
    try {
        const response = yield postman.get(`/Models/${payload.id}/Operations`);

        yield put({ type: GET_OPERATION_SUCCESS, payload: response });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddCustomers({ payload }) {
    try {
        yield postman.post('/Customers', { name: payload });

        const { items } = yield postman.get(`/Customers`, {
            params: {
                isActive: true,
                pageSize: 10000,
            },
        });

        yield put({ type: GET_ADD_CUSTOMERS_SUCCESS, payload: items });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddNewOrder({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        if (data.startDate) {
            const [date] = data.startDate.split('+');
            data.startDate = date;
        }

        yield postman.post('/Orders', data);
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: SET_NEW_ORDER_ERROR,
            payload: data.errorCode,
        });
    }
}

function* requestGetOperation() {
    const responseOperation = yield postman.get('/Operations', {
        params: {
            isActive: true,
            pageSize: 10000,
        },
    });

    yield put({
        type: GET_OPTION_MODEL_LIST_SUCCESS,
        payload: {
            listOperation: responseOperation.items,
        },
    });
}
function* requestGetSizes() {
    const responseSizes = yield postman.get('/Sizes', {
        params: {
            isActive: true,
            pageSize: 10000,
        },
    });

    yield put({
        type: GET_OPTION_MODEL_LIST_SUCCESS,
        payload: {
            size: responseSizes.items,
        },
    });
}

function* requestGetColor() {
    const responseColor = yield postman.get('/Colors', {
        params: {
            isActive: true,
            pageSize: 10000,
        },
    });

    yield put({
        type: GET_OPTION_MODEL_LIST_SUCCESS,
        payload: {
            color: responseColor.items,
        },
    });
}

function* workerGetOperationList() {
    try {
        yield fork(requestGetOperation);
        yield fork(requestGetSizes);
        yield fork(requestGetColor);
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* requestOperation(item, responseOrder) {
    const responseOperation = yield postman.get(`/Models/${item.model.id}/Operations`);
    const responseOrderOperation = yield postman.get(
        `/Orders/${responseOrder.id}/positions/${item.id}/operations`,
    );

    const newArr = responseOperation.map((itemOperation) => {
        const arr = responseOrderOperation.filter((i) => i.operationId === itemOperation.id);
        let operation;
        let maxValue = item.count;

        if (arr.length > 0) {
            operation = arr.map((element) => {
                return {
                    operationId: element.operationId,
                    id: element.id,
                    add: true,
                    isEdit: false,
                    producerId: element.producerId,
                    requiredCount: element.requiredCount,
                    productionLineId: element.productionLineId,
                };
            });
            maxValue -= operation.reduce((prev, next) => prev + next.requiredCount, 0);
        }

        return {
            ...itemOperation,
            maxValue,
            orderOperation: operation ? operation : [],
        };
    });

    return {
        id: item.id,
        model: {
            id: item.model.id,
            name: item.model.name,
            description: item.model.description,
            operations: newArr,
            isVisible: true,
        },
        size: {
            name: item.size.name,
        },
        color: {
            name: item.color.name,
        },
        count: item.count,
    };
}

function* workerGetOrder({ payload }) {
    try {
        const responseOrder = yield postman.get(`/Orders/${payload}`);
        const responsePosition = yield postman.get(`/Orders/${responseOrder.id}/positions`);
        const responseOperation = yield all(
            responsePosition.map((item) => {
                return call(requestOperation, item, responseOrder);
            }),
        );

        yield put({
            type: GET_ORDER_SUCCESS,
            payload: {
                responseOrder,
                responseOperation,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerSetModel({ payload }) {
    try {
        const { data, setOpen, copyingModel } = payload;
        const responseModel = yield postman.post('/Models', data.model);

        data.model.id = responseModel.id;
        const responseOperation = yield postman.get(`/Models/${responseModel.id}/Operations`);

        data.model.operations = responseOperation;
        copyingModel(data);
        setOpen(false);
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddEditOrder({ payload }) {
    try {
        const { data, callbackSuccess, id } = payload;

        yield postman.put(`/Orders/${id}`, data);
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: SET_EDIT_ORDER_ERROR,
            payload: data.errorCode,
        });
    }
}

function* workerAddOrderOperations({ payload }) {
    try {
        const {
            idOrder,
            idPosition,
            operationId,
            producerId,
            requiredCount,
            id,
            updateData,
            productionLineId,
        } = payload;

        yield postman.post(`/Orders/${idOrder}/positions/${idPosition}/operations`, {
            id,
            operationIds: Array.isArray(operationId) ? operationId : [operationId],
            producerId,
            requiredCount,
            productionLineId,
        });
        let response = yield postman.get(`/Orders/${idOrder}/positions/${idPosition}/operations`);

        response = response.filter((el) => el.operationId === operationId);
        response = response.map((el) => {
            return {
                ...el,
                isEdit: false,
            };
        });
        yield put({
            type: SET_ADD_ORDER_OPERATIONS_SUCCESS,
        });
        updateData && updateData(response);
    } catch (err) {
        const { data } = err;

        yield put({
            type: SET_ADD_ORDER_OPERATIONS_ERROR,
            payload: data.errorCode,
        });
    }
}

function* workerDeleteOrderOperations({ payload }) {
    try {
        const { idOrder, idPosition, id, operationId, updateData } = payload;

        yield postman.delete(`/Orders/${idOrder}/positions/${idPosition}/operations/${id}`);
        let response = yield postman.get(`/Orders/${idOrder}/positions/${idPosition}/operations`);

        response = response.filter((el) => el.operationId === operationId);
        response = response.map((el) => {
            return {
                ...el,
                isEdit: false,
            };
        });
        yield put({
            type: SET_DELETE_ORDER_OPERATIONS_SUCCESS,
        });
        updateData(response);
    } catch (err) {
        const { data } = err;

        yield put({
            type: SET_DELETE_ORDER_OPERATIONS_ERROR,
            payload: data.errorCode,
        });
    }
}

function* workerPostOperationGroup({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.post(`/OperationGroups`, data);
        callbackSuccess && callbackSuccess();
    } catch (err) {}
}

function* workerPutOperationGroup({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.put(`/OperationGroups`, data);
        callbackSuccess && callbackSuccess();
    } catch (err) {}
}

function* workerGetOperationGroupId({ payload }) {
    try {
        const data = yield postman.get(`/OperationGroups`, {
            params: {
                modelId: payload,
                isActive: true,
            },
        });
        yield put({
            type: GET_OPERATION_GROUP_LIST_SUCCESS,
            payload: {
                operationGroup: data.items,
            },
        });
    } catch (err) {}
}

export function* watchCreateOrders() {
    yield takeEvery(GET_OPTION_LIST_REQUEST, workerGetCustomerList);
    yield takeEvery(GET_OPTION_LIST_REQUEST_ALT, workerGetCustomerListAlt);
    yield takeEvery(SET_ADD_CUSTOMERS_REQUEST, workerAddCustomers);
    yield takeEvery(GET_OPERATION_REQUEST, workerGetOperation);
    yield takeEvery(GET_OPTION_MODEL_LIST_REQUEST, workerGetOperationList);
    yield takeEvery(SET_NEW_ORDER_REQUEST, workerAddNewOrder);
    yield takeEvery(GET_ORDER_REQUEST, workerGetOrder);
    yield takeEvery(SET_NEW_MODEL_REQUEST, workerSetModel);
    yield takeEvery(SET_EDIT_ORDER_REQUEST, workerAddEditOrder);
    yield takeEvery(SET_ADD_ORDER_OPERATIONS_REQUEST, workerAddOrderOperations);
    yield takeEvery(SET_DELETE_ORDER_OPERATIONS_REQUEST, workerDeleteOrderOperations);
    yield takeEvery(POST_OPERATION_GROUP_REQUEST, workerPostOperationGroup);
    yield takeEvery(PUT_OPERATION_GROUP_REQUEST, workerPutOperationGroup);
    yield takeEvery(GET_OPERATION_GROUP_REQUEST_ID, workerGetOperationGroupId);
}
