import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { getSalary, salaryData } from '../../ducks/report';
import { configTableSalary } from '../../utils/const';
import { getProductionLines, productionLinesOption } from '../../ducks/productionLines';
import useDebounce from '../../hooks/useDebounce';

import './style.scss';

function Salary() {
    const dispatch = useDispatch();
    const data = useSelector(salaryData);
    const stream = useSelector(productionLinesOption);
    const date = new Date();
    const [filter, setFilter] = useState({
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: date,
        productionLineId: null,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
    }, []);

    useEffect(() => {
        if (filter.endDate && filter.startDate) {
            dispatch(getSalary(debouncedFilter));
        }
    }, [debouncedFilter]);

    return (
        <div className="salary">
            <div className="salary__body">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <h1 className="filter__title">Зарплата</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3} className="filter__text">
                            От
                            <SemanticDatepicker
                                locale="ru-RU"
                                format="DD.MM.YYYY"
                                onChange={handleChange}
                                className="filter__input"
                                iconPosition="left"
                                icon="calendar alternate"
                                placeholder="00.00.0000"
                                name="startDate"
                                value={filter.startDate}
                                clearable={false}
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            До
                            <SemanticDatepicker
                                locale="ru-RU"
                                clearable={false}
                                format="DD.MM.YYYY"
                                onChange={handleChange}
                                className="filter__input"
                                iconPosition="left"
                                icon="calendar alternate"
                                placeholder="00.00.0000"
                                name="endDate"
                                value={filter.endDate}
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Поток
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                value={filter.productionLineId}
                                placeholder="Выберите поток"
                                options={stream}
                                name="productionLineId"
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <div className="salary__table">
                        {data &&
                            data.map((item) => (
                                <>
                                    <h3 className="salary__title">{item.name}</h3>
                                    <Table structured celled className="salary__table">
                                        <Table.Header>
                                            <Table.Row>
                                                {configTableSalary &&
                                                    configTableSalary.map(({ title }) => (
                                                        <Table.HeaderCell key={title}>
                                                            {title}
                                                        </Table.HeaderCell>
                                                    ))}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {item &&
                                                item.producersIncome.map((item) => (
                                                    <Table.Row key={item.name}>
                                                        {configTableSalary.map(({ key }) => (
                                                            <Table.Cell key={key}>
                                                                {item[key]}
                                                            </Table.Cell>
                                                        ))}
                                                    </Table.Row>
                                                ))}
                                            <Table.Row>
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell />
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell />
                                            </Table.Row>
                                            <Table.Row></Table.Row>
                                            <Table.Row>
                                                <Table.Cell />
                                                <Table.Cell />
                                                <Table.Cell>
                                                    <p>Общая зарплата:</p>
                                                </Table.Cell>
                                                <Table.Cell>{item.totalIncome}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </>
                            ))}
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default Salary;
