export const LOGIN_LINK = '/login';
export const WIZARD_LINK = '/wizard';
export const ORDER_LINK = '/order';
export const ORDER_DISTRIBUTION_LINK = '/order_distribution';
export const SETTING_LINK = '/setting';
export const PROFITABILITY_LINK = '/profitability';
export const COSTS_LINK = '/costs';
export const USER_LINK = '/users';
export const CLIENT_LINK = '/clients';
export const SALARY_LINK = '/salary';
export const MORE_REPORTS_LINK = '/more_reports';
export const ORDER_TIMELINE_LINK = '/order_timeline';
export const MODEL_LINK = '/model';
export const COLOR_LINK = '/color';
export const SIZE_LINK = '/size';
export const OPERATION_LINK = '/operation';
export const GROUP_OPERATIONS = '/group_operations'
