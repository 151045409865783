import React from 'react';
import { Progress } from 'semantic-ui-react';

import { Stepper } from '../../../components';
import { listStepperWizard } from '../../../utils/const';

const style = { margin: '0px' };

function HeaderWizard({ progress }) {
    return (
        <div className="heder">
            <div className="heder__body">
                <h1 className="heder__title">Настройка системы</h1>
                <Stepper progress={progress} arrName={listStepperWizard} />
            </div>
            <Progress
                percent={progress === 0 ? 20 : progress === 1 ? 50 : progress === 2 ? 75 : 100}
                size="tiny"
                color="green"
                style={style}
            />
        </div>
    );
}

export default HeaderWizard;
