import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { Switch } from 'react-router-dom';

import { Navigation } from '../../components';
import {
    OrderListPage,
    UserList,
    ClientList,
    Profitability,
    Costs,
    Salary,
    Report,
    OrderTimeline,
    ColorList,
    OperationList,
    ModelList,
    GroupOperations,
} from '../';
import {
    USER_LINK,
    CLIENT_LINK,
    PROFITABILITY_LINK,
    COSTS_LINK,
    SALARY_LINK,
    ORDER_TIMELINE_LINK,
    SIZE_LINK,
    COLOR_LINK,
    OPERATION_LINK,
    MODEL_LINK,
    GROUP_OPERATIONS,
} from '../../router/links';
import PrivateRoute from '../../router/privateRoute';
import { SizeList } from '../index';

import './style.scss';

function MainPage() {
    const [activeSection, setActiveSection] = useState(null);

    return (
        <div className="main-page">
            <Grid columns={2} relaxed>
                <Grid.Column width={3}>
                    <Navigation activeSection={activeSection} setSection={setActiveSection} />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Switch>
                        <PrivateRoute exact path={'/'} component={OrderListPage} />
                        <PrivateRoute exact path={USER_LINK} component={UserList} />
                        <PrivateRoute exact path={CLIENT_LINK} component={ClientList} />
                        <PrivateRoute exact path={PROFITABILITY_LINK} component={Profitability} />
                        <PrivateRoute exact path={COSTS_LINK} component={Costs} />
                        <PrivateRoute exact path={SALARY_LINK} component={Salary} />
                        <PrivateRoute exact path={ORDER_TIMELINE_LINK} component={OrderTimeline} />
                        <PrivateRoute
                            exact
                            path={`${PROFITABILITY_LINK}/month`}
                            component={Report}
                        />
                        <PrivateRoute exact path={SIZE_LINK} component={SizeList} />
                        <PrivateRoute exact path={COLOR_LINK} component={ColorList} />
                        <PrivateRoute exact path={OPERATION_LINK} component={OperationList} />
                        <PrivateRoute exact path={MODEL_LINK} component={ModelList} />
                        <PrivateRoute exact path={GROUP_OPERATIONS} component={GroupOperations} />
                    </Switch>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default MainPage;
