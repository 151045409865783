import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Visibility, Table, Icon, Input, Select, Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { getOrders, ordersList, ordersCustomer, deleteOrderRequest } from '../../ducks/orders';
import { ORDER_LINK, ORDER_DISTRIBUTION_LINK } from '../../router/links';
import { USER_ROLE, countryStatus, configTableOrder, USER_ROLES } from '../../utils/const';
import { ls, renderIcon } from '../../utils/functionHelper';
import ModalDelete from '../../components/ModalDelete';
import useDebounce from '../../hooks/useDebounce';
import Popup from './components/popup';

import './style.scss';

function OrderList() {
    const dispatch = useDispatch();
    const orders = useSelector(ordersList);
    const optionCustomer = useSelector(ordersCustomer);
    const role = ls(USER_ROLE);
    const history = useHistory();
    const [filter, setFilter] = useState({
        number: null,
        statuses: null,
        createdFrom: null,
        customerId: null,
        pageSize: 20,
    });
    const [modal, setModal] = useState({ isVisible: false, id: null, number: null });
    const [popup, setPopup] = useState({ isVisible: false, item: null });
    const debouncedFilter = useDebounce(filter, 200);
    const onClickCreate = useCallback(() => {
        history.push(`${ORDER_LINK}/create`);
    }, []);
    const onClickEdit = (ev, id) => {
        ev.stopPropagation();
        history.push({ pathname: `${ORDER_LINK}/${id}` });
    };
    const onClickEditDistribution = (ev, id) => {
        ev.stopPropagation();
        history.push({ pathname: `${ORDER_DISTRIBUTION_LINK}/${id}` });
    };
    const deleteOrder = (ev, item) => {
        ev.stopPropagation();

        setModal(
            Object.assign({}, modal, {
                isVisible: !modal.isVisible,
                id: item.key,
                number: item.number,
            }),
        );
    };
    const handleChange = (event, { name, value }) => {
        let newValue;

        if (name === 'date') {
            newValue = event.target.dataset.testid.substr(16);
        } else {
            newValue = value;
        }

        setFilter(
            Object.assign({}, filter, {
                [name]: newValue,
            }),
        );
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && orders.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };

    const onClickDelete = useCallback(() => {
        dispatch(deleteOrderRequest({ id: modal.id, callbackSuccess: fetchData }));
        setModal(Object.assign({}, modal, { isVisible: false, number: null, id: null }));
    }, [modal]);

    const visiblePopup = (item) => {
        setPopup(Object.assign({}, popup, { isVisible: !popup.isVisible, item: item }));
    };

    function fetchData() {
        dispatch(getOrders({ ...debouncedFilter, role }));
    }

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <Visibility onUpdate={handleUpdate}>
            <div className="table-order">
                <div className="filter">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <h1 className="filter__title">Заказы</h1>
                            </Grid.Column>
                            {!(role === USER_ROLES.MASTER) && (
                                <>
                                    <Grid.Column width={4}>
                                        <button className="filter__button" onClick={onClickCreate}>
                                            <p>
                                                <Icon name="plus" size="small" />
                                                Добавить заказ
                                            </p>
                                        </button>
                                    </Grid.Column>
                                </>
                            )}
                        </Grid.Row>
                        <Grid.Row className="filter__row">
                            <Grid.Column width={2} className="filter__text">
                                Номер заказа
                                <Input
                                    placeholder="0"
                                    className="filter__input"
                                    onChange={handleChange}
                                    name="number"
                                />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter__text">
                                Статус
                                <Select
                                    onChange={handleChange}
                                    clearable
                                    className="filter__input"
                                    placeholder="Наименование статуса"
                                    options={countryStatus}
                                    name="statuses"
                                />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter__text">
                                Дата создания
                                <SemanticDatepicker
                                    locale="ru-RU"
                                    format="DD.MM.YYYY"
                                    onChange={handleChange}
                                    className="filter__inputDate"
                                    iconPosition="left"
                                    icon="calendar alternate"
                                    placeholder="00.00.0000"
                                    name="createdFrom"
                                />
                            </Grid.Column>
                            {!(role === 'Master') && (
                                <Grid.Column width={3} className="filter__text">
                                    Заказчик
                                    <Dropdown
                                        className="filter__input"
                                        fluid
                                        clearable
                                        search
                                        selection
                                        placeholder="Наименование"
                                        options={optionCustomer}
                                        onChange={handleChange}
                                        name="customerId"
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </div>
                <Table basic className="table-order__table" fixed={true}>
                    <Table.Header>
                        <Table.Row>
                            {configTableOrder &&
                                configTableOrder.map(({ title }) => (
                                    <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
                                ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders &&
                            orders.map((item) => (
                                <Table.Row
                                    key={item.key}
                                    className="table-order__row"
                                    onClick={() => visiblePopup(item)}
                                >
                                    {configTableOrder.map(({ key, width }) => (
                                        <Table.Cell width={width} key={key}>
                                            {key
                                                ? item[key]
                                                : renderIcon(
                                                      role,
                                                      onClickEditDistribution,
                                                      deleteOrder,
                                                      onClickEdit,
                                                      item,
                                                  )}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </div>
            {modal.isVisible && (
                <ModalDelete
                    closeModal={deleteOrder}
                    onClickDelete={onClickDelete}
                    modal={modal}
                    text={`заказ №${modal.number}`}
                />
            )}
            {popup.isVisible && (
                <Popup popup={popup.isVisible} order={popup.item} closePopup={visiblePopup} />
            )}
        </Visibility>
    );
}

export default OrderList;
