import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Button } from 'semantic-ui-react';
import className from 'classname';

import {
    setSettingRoleCustomersRequest,
    errorCustomers,
    setCustomers,
    settingCustomers,
    settingRole,
} from '../../../ducks/setting';
import { CustomersInput, Constructor } from '../../../components';

const getNewEmptyValue = () => {
    return {
        id: uuidv4(),
    };
};

function Customers({ backProgress, nextProgress }) {
    const customers = useSelector(settingCustomers);
    const role = useSelector(settingRole);
    const [form, setForm] = useState(customers ? customers : { customers: [getNewEmptyValue()] });
    const dispatch = useDispatch();
    const error = useSelector(errorCustomers);
    const back = () => {
        dispatch(setCustomers(form));
        backProgress();
    };

    const handleChange = useCallback(
        (event, { name, value }) => {
            setForm((form) => ({
                ...form,
                [name]: value,
            }));
        },
        [form],
    );

    const isNext = useMemo(() => {
        return Object.values(form)
            .flat()
            .some(({ name }) => !name);
    }, [form]);

    function onSubmit() {
        dispatch(
            setSettingRoleCustomersRequest({ role, customers: form, nextProgress, backProgress }),
        );
    }

    return (
        <div className="customers">
            <div className="customers__body">
                <h2 className="customers__title">Шаг 4. Клиентская база</h2>
                <div
                    className={className({
                        customers__error: error.errorCustomers,
                    })}
                >
                    <p>{error.errorCustomers}</p>
                </div>
                <Constructor
                    key={form.customers}
                    {...form}
                    value={form.customers}
                    error={error}
                    onChange={handleChange}
                    getNewEmptyValue={getNewEmptyValue}
                    name={'customers'}
                >
                    <CustomersInput />
                </Constructor>
                <div className="customers__nav">
                    <Button className="customers__button" onClick={back}>
                        <Icon name="angle left" size="large" />
                        Назад
                    </Button>
                    <Button className="customers__button" disabled={isNext} onClick={onSubmit}>
                        Продолжить
                        <Icon name="angle right" size="large" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Customers;
