import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Input, Dropdown, Grid, Visibility } from 'semantic-ui-react';

import { modelList, getModel, deleteModel } from '../../ducks/model';
import { countryStatusIsActive, configTableModel } from '../../utils/const';
import ModalModel from './components/modal';
import useDebounce from '../../hooks/useDebounce';
import DefaultTable from '../../components/DefaultTable';

import './style.scss';

function ModelList() {
    const dispatch = useDispatch();
    const model = useSelector(modelList);
    const [modalData, setModalData] = useState({ open: false, data: {} });
    const [filter, setFilter] = useState({
        nameSubstring: null,
        pageSize: 20,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const onClickCreate = useCallback(() => {
        setModalData(Object.assign({}, modalData, { open: true }));
    }, []);
    function fetchData() {
        dispatch(getModel(debouncedFilter));
    }
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const onClickDelete = (item) => {
        dispatch(deleteModel({ item, callbackSuccess: fetchData }));
    };
    const onClickCopy = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && model.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <div className="model-table">
            <div className="filter">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <h1 className="filter__title">Модели</h1>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <button className="filter__button" onClick={onClickCreate}>
                                <p>
                                    <Icon name="plus" size="small" />
                                    Добавить модель
                                </p>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3} className="filter__text">
                            Название
                            <Input
                                placeholder="Наименование модели"
                                className="filter__input"
                                onChange={handleChange}
                                name="nameSubstring"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Статус
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Выберите статус"
                                options={countryStatusIsActive}
                                name="isActive"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <DefaultTable
                essence={model}
                onClickDelete={onClickDelete}
                onClickEdit={onClickCopy}
                config={configTableModel}
                firstIcon={'clone outline'}
                secondaryIcon={'archive'}
                handleUpdate={handleUpdate}
            />
            {modalData.open && (
                <ModalModel fetchData={fetchData} modalData={modalData} closeModal={closeModal} />
            )}
        </div>
    );
}

export default ModelList;
