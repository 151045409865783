import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';
import { addStatus, dateFormatDD, setOptionsId } from '../utils/functionHelper';

import { postman } from '../utils/postman';

//*                                                       /Orders /Customers                                                *//

//*  TYPES  *//

export const GET_ORDERS_LIST_REQUEST = 'GET_ORDERS_LIST_REQUEST';
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS';

export const GET_ALL_ORDERS_REQUEST = 'GET_ALL_ORDERS_REQUEST';
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS';

export const DELETE_ORDERS_REQUEST = 'DELETE_ORDERS_REQUEST';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';

export const CLEAR_ORDER = 'CLEAR_ORDER';

//*  INITIAL STATE  *//

const initial = {
    items: [],
    itemsCount: 0,
    pagesCount: 0,
    customer: [],
    currentOrder: null,
};

//*  REDUCER  *//

export default function order(state = initial, action) {
    switch (action.type) {
        case GET_ORDERS_LIST_SUCCESS:
            return {
                ...state,
                customer: action.payload.customer,
                items: action.payload.items,
                itemsCount: action.payload.itemsCount,
                pagesCount: action.payload.pagesCount,
            };
        case GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                currentOrder: action.payload,
            };
        case CLEAR_ORDER:
            return {
                ...state,
                currentOrder: null,
            };
        case DELETE_ORDERS_SUCCESS:
            return {
                ...state,
                customer: action.payload.customer,
                items: action.payload.items,
                itemsCount: action.payload.itemsCount,
                pagesCount: action.payload.pagesCount,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const deleteOrderRequest = (payload) => {
    return {
        type: DELETE_ORDERS_REQUEST,
        payload,
    };
};

export const clearOrder = () => {
    return {
        type: CLEAR_ORDER,
    };
};

export const getAllOrder = (payload) => {
    return {
        type: GET_ALL_ORDERS_REQUEST,
        payload,
    };
};

export const getOrders = (payload) => {
    return {
        type: GET_ORDERS_LIST_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseOrderState = (state) => state.order;

export const ordersList = createSelector(
    [baseOrderState],
    (data) =>
        data.items &&
        data.items.map((value) => {
            return {
                key: value.id,
                number: value.number,
                status: addStatus(value.status),
                createDate: dateFormatDD(parseISO(value.createDate)),
                cost: `${value.cost} ₽`,
                customer: value.customer.name,
            };
        }),
);
export const ordersCustomer = createSelector(
    [baseOrderState],
    (data) => data.customer && setOptionsId(data.customer),
);

export const getCurrentOrder = createSelector(
    [baseOrderState],
    (state) => state.currentOrder && state.currentOrder,
);

//*  SAGA  *//

function* workerGetOrders({ payload }) {
    try {
        const { statuses, customerId, number, createdFrom, role, pageSize } = payload;
        let responseCustomers = null;
        const responseOrder = yield postman.get(
            `/Orders?${statuses ? `Statuses=${statuses}&` : ''}`,
            {
                params: {
                    customerId,
                    createdFrom,
                    number,
                    pageSize,
                },
            },
        );

        if (!(role === 'Master')) {
            responseCustomers = yield postman.get(`/Customers`, {
                params: {
                    isActive: true,
                    pageSize: 10000,
                },
            });
        }

        yield put({
            type: GET_ORDERS_LIST_SUCCESS,
            payload: {
                itemsCount: responseOrder.itemsCount,
                pagesCount: responseOrder.pagesCount,
                items: responseOrder.items,
                customer: responseCustomers && responseCustomers.items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteOrder({ payload }) {
    try {
        const { id, callbackSuccess } = payload;

        yield postman.delete(`/Orders/${id}`);

        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* requestOperation(item) {
    const responseOperation = yield postman.get(`/Models/${item.model.id}/Operations`);

    return {
        id: item.id,
        model: {
            id: item.model.id,
            name: item.model.name,
            description: item.model.description,
            operations: responseOperation,
            isVisible: true,
        },
        size: {
            name: item.size.name,
        },
        color: {
            name: item.color.name,
        },
        count: item.count,
    };
}

function* workerGetAllOrder({ payload }) {
    try {
        const responseOrder = yield postman.get(`/Orders/${payload}`);
        const responsePosition = yield postman.get(`/Orders/${responseOrder.id}/positions`);
        const responseLines = yield postman.get(
            `/ProductionLines/${responseOrder.productionLineId}`,
        );
        const responseOperation = yield all(
            responsePosition.map((item) => {
                return call(requestOperation, item);
            }),
        );

        yield put({
            type: GET_ALL_ORDERS_SUCCESS,
            payload: {
                ...responseOrder,
                positions: responseOperation,
                line: responseLines,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchOrders() {
    yield takeEvery(GET_ORDERS_LIST_REQUEST, workerGetOrders);
    yield takeEvery(DELETE_ORDERS_REQUEST, workerDeleteOrder);
    yield takeEvery(GET_ALL_ORDERS_REQUEST, workerGetAllOrder);
}
