import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import InputInformation from './components/inputInformation';
import { listStepperSetting } from '../../utils/const';
import { Stepper } from '../../components';
import { information, setInformationRequest, getSetting } from '../../ducks/setting';
import {
    getProductionLines,
    productionLinesList,
    addProductionLines,
    deleteProductionLines,
} from '../../ducks/productionLines';
import { Constructor, StreamsInput } from '../../components';

import './style.scss';

const getNewEmptyValue = () => {
    return {
        id: uuidv4(),
    };
};

function Setting() {
    const dispatch = useDispatch();
    const history = useHistory();
    const production = useSelector(productionLinesList);
    const inform = useSelector(information);
    const [form, setForm] = useState({
        name: null,
        price: null,
        streams: null,
        tab: 0,
    });
    const onSubmit = () => {
        dispatch(setInformationRequest({ ...form }));
        dispatch(addProductionLines({ data: form, callbackSuccess: orderPage }));
    };
    const orderPage = () => {
        history.push('/');
    };
    const handleChange = (event, { name, value }) => {
        let newValue;

        if (name === 'name') {
            newValue = value;
        } else {
            const [first, two] = value.split('.');

            if (two && two.length > 2) {
                return;
            } else {
                newValue = +value > 100 ? '100' : value < 0 ? 0 : value;
            }
        }

        setForm(
            Object.assign({}, form, {
                [name]: newValue,
            }),
        );
    };

    useEffect(() => {
        if (production) {
            setForm(
                Object.assign({}, form, {
                    streams: production.length > 0 ? production : [getNewEmptyValue()],
                }),
            );
        }
    }, [production]);

    const deleteItem = (item) => {
        dispatch(deleteProductionLines({ item }));
    };

    useEffect(() => {
        dispatch(getSetting());
        dispatch(getProductionLines({ isActive: true }));
    }, []);

    useEffect(() => {
        setForm(Object.assign({}, form, { name: inform.name, price: inform.price }));
    }, [inform]);

    return (
        <div className="setting-page">
            <div className="setting-page__body">
                <div className="heder">
                    <div className="heder__body">
                        <h1 className="heder__title">Настройка организации</h1>
                        <div className="heder__stepper">
                            <Stepper
                                form={form}
                                progress={-1}
                                setProgress={setForm}
                                arrName={listStepperSetting}
                            />
                        </div>
                    </div>
                </div>
                <div className="information">
                    <div className="information__body">
                        {form.tab === 0 && (
                            <InputInformation form={form} handleChange={handleChange} />
                        )}
                        {form.tab === 1 && (
                            <>
                                <p className="information__text">Ваши потоки</p>
                                <Constructor
                                    key={form.streams}
                                    {...form}
                                    value={form.streams}
                                    onChange={handleChange}
                                    getNewEmptyValue={getNewEmptyValue}
                                    name={'streams'}
                                >
                                    <StreamsInput deleteItem={deleteItem} />
                                </Constructor>
                            </>
                        )}
                        <div className="information__nav">
                            <Button className="information__button" onClick={orderPage}>
                                <Icon name="angle left" size="large" />
                                Назад
                            </Button>
                            <Button
                                className="information__button"
                                disabled={!form.price || !form.name}
                                onClick={onSubmit}
                            >
                                Сохранить
                                <Icon name="angle right" size="large" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Setting;
