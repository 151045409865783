import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { formatISO } from 'date-fns';
import className from 'classnames';

import { HeaderOrder } from '../../components';
import FormCreate from './components/formCreate';
import {
    addNewOrder,
    addEditOrder,
    createData,
    getOrder,
    order,
    getErrorOrder,
    clearErrorOrder,
} from '../../ducks/createOrders';
import { getProductionLines } from '../../ducks/productionLines';

import { errorListOrder } from '../../utils/const';

import './style.scss';

function CreateOrder(prop) {
    const dispatch = useDispatch();
    const history = useHistory();
    const id = prop.location.pathname.split('/')[2];
    const data = useSelector(createData);
    const orderEdit = useSelector(order);
    const error = useSelector(getErrorOrder);
    const date = new Date();
    const [form, setForm] = useState({
        number: null,
        status: 'New',
        customerId: null,
        costPerSecond: null,
        cost: null,
        productionLineId: null,
        startDate: formatISO(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0)),
        positions: [],
    });
    const onClickOrder = () => {
        history.push('/');
    };
    const isNext = useMemo(() => {
        return (
            !form.productionLineId ||
            !form.customerId ||
            !form.number ||
            !form.costPerSecond ||
            !form.cost ||
            !form.positions.length > 0 ||
            [...form.positions].some(
                ({ color, size, count, model }) =>
                    !color.name ||
                    !size.name ||
                    !count ||
                    [...model.operations].some(({ name, duration }) => !name || !duration),
            )
        );
    }, [form]);

    function onSubmit() {
        dispatch(
            id === 'create'
                ? addNewOrder({
                      data: form,
                      callbackSuccess: () => history.push({ pathname: `/` }),
                  })
                : addEditOrder({
                      data: form,
                      id: id,
                      callbackSuccess: () => history.push({ pathname: `/` }),
                  }),
        );
    }

    useEffect(() => {
        dispatch(clearErrorOrder());
    }, []);

    useEffect(() => {
        if (orderEdit) {
            setForm(orderEdit);
        }
    }, [orderEdit]);

    useEffect(() => {
        if (id === 'create')
            setForm(
                Object.assign({}, form, {
                    ...data,
                }),
            );
    }, [data]);

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
        if (id !== 'create') {
            dispatch(getOrder(id));
        }
    }, []);

    return (
        <div className="create-order">
            <div className="create-order__body">
                <div
                    className={className({
                        'create-order__error': error,
                    })}
                >
                    <div>
                        <p>{error && errorListOrder[error]}</p>
                    </div>
                </div>
                <span className="create-order__nav" onClick={onClickOrder}>
                    <Icon name="angle left" size="large" />
                    Список заказов
                </span>
                <HeaderOrder
                    onSubmit={onSubmit}
                    isNext={isNext}
                    status={form.status}
                    name={form.id ? { date: orderEdit.createDate, number: orderEdit.number } : null}
                    number={form.number}
                />
                <FormCreate form={form} setForm={setForm} disable={!(form.status === 'New')} />
            </div>
        </div>
    );
}

export default CreateOrder;
