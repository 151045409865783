import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Icon, Button } from 'semantic-ui-react';

import { setInformationRequest, information } from '../../../ducks/setting';

function Information({ nextProgress }) {
    const inform = useSelector(information);
    const [form, setForm] = useState({
        name: inform.name ? inform.name : null,
        price: inform.price ? inform.price : null,
    });
    const dispatch = useDispatch();
    const onSubmit = () => {
        dispatch(setInformationRequest({ ...form, nextProgress: nextProgress }));
    };
    const handleChange = (event, { name, value }) => {
        let newValue;

        if (name === 'name') {
            newValue = value;
        } else {
            const [first, two] = value.split('.');

            if (two && two.length > 2) {
                return;
            } else {
                newValue = !isNaN(value) ? (value > 100 ? 100 : +value > 0 ? +value : '') : +value;
            }
        }

        setForm(
            Object.assign({}, form, {
                [name]: newValue,
            }),
        );
    };

    return (
        <div className="information">
            <div className="information__body">
                <h2 className="information__title">Шаг 1. Информация об организации</h2>
                <p className="information__text">Наименование Вашей организации</p>
                <Input
                    placeholder="Введите наименование организации"
                    className="information__input-name"
                    size="big"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                />
                <span className="information__text">
                    Стандартная стоимость <br /> секунды производства, коп.
                </span>
                <Input
                    type="number"
                    placeholder="100"
                    className="information__input-price"
                    size="large"
                    name="price"
                    value={form.price}
                    onChange={handleChange}
                />
                <div className="information__nav">
                    <Button
                        className="information__button"
                        disabled={!form.price || !form.name}
                        onClick={onSubmit}
                    >
                        Продолжить
                        <Icon name="angle right" size="large" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Information;
