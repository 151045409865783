import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Placeholder } from 'semantic-ui-react';

import { hintPosition } from '../../../utils/const';

function HintInput() {
    return (
        <div className="hint-input">
            <Grid>
                <Grid.Row>
                    {hintPosition &&
                        hintPosition.map((item, index) => (
                            <Grid.Column width={item.width} key={item.value}>
                                <p className="hint-input__subtitle">{item.value}</p>
                                <Placeholder>
                                    <Placeholder.Line length="full" />
                                </Placeholder>
                            </Grid.Column>
                        ))}
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default HintInput;
