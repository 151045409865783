import React from 'react';
import { Icon, Input } from 'semantic-ui-react';

const StreamsInput = ({ value = [], error, onChange, onAdd, onDelete, deleteItem }) => {
    const deleteElement = (ev, item) => {
        onDelete(ev, item.id);
        if (item.createDate) {
            deleteItem(item);
        }
    };

    return (
        <>
            {value.map((input, index) => (
                <div key={input.id}>
                    <span className="streams__text">
                        {!input.createDate ? 'Новый поток' : 'Поток'}
                    </span>
                    {value.length > 1 && (
                        <button
                            className="streams__button-delete"
                            onClick={(event) => deleteElement(event, input)}
                        >
                            <Icon name="times circle outline" size="large" />
                            Удалить
                        </button>
                    )}
                    <Input
                        id={input.id}
                        name="name.streams"
                        className="streams__name"
                        placeholder="Введите наименование потока"
                        size="big"
                        onChange={onChange}
                        value={value[index].name}
                    />
                </div>
            ))}
            <button className="streams__button-add" onClick={onAdd}>
                <p>
                    <Icon name="add circle" size="large" />
                    Добавить поток
                </p>
            </button>
        </>
    );
};

export default StreamsInput;
