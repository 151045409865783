import React, { useMemo } from 'react';
import { Grid, Input, Modal, Button, TextArea, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import className from 'classnames';

import {
    addCustomers,
    editCustomers,
    customerError,
    clearError,
    getLoading,
} from '../../../ducks/client';
import { errorListClient } from '../../../utils/const';

function CustomerModal({ fetchData, modalData, closeModal }) {
    const dispatch = useDispatch();
    const error = useSelector(customerError);
    const loading = useSelector(getLoading);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: data ? data.id : null,
            name: data ? data.name : null,
            contacts: data ? data.contacts : null,
            description: data ? data.description : null,
            isActive: data.id ? data.isActive : true,
        },
        onSubmit: (values) => {
            dispatch(
                !values.id
                    ? addCustomers({
                          data: values,
                          callbackSuccess,
                      })
                    : editCustomers({
                          data: values,
                          callbackSuccess,
                      }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const onChange = (event, { value, name }) => {
        setFieldValue(name, !values.isActive);
    };
    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const isNext = useMemo(() => {
        return !values.name;
    }, [values]);

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <div
                    className={className({
                        modal__error: error,
                    })}
                >
                    <p>{error && errorListClient[error]}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {values.id ? 'Изменеие клиента' : 'Новый клиент'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    toggle
                                    onChange={onChange}
                                    className="modal__check"
                                    label={values.isActive ? 'Активен' : 'Неактивен'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="name"
                                    iconPosition="left"
                                    icon="user"
                                    type="text"
                                    value={values.name}
                                    placeholder="Название"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Контакты</h3>
                                <TextArea
                                    name="contacts"
                                    type="text"
                                    value={values.contacts}
                                    placeholder="Контакты"
                                    onChange={handleChange}
                                    className="modal__textarea"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Описание</h3>
                                <TextArea
                                    name="description"
                                    type="text"
                                    iconPosition="left"
                                    icon="user"
                                    value={values.description}
                                    placeholder="Описание"
                                    onChange={handleChange}
                                    className="modal__textarea"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default CustomerModal;
