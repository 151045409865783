import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Button } from 'semantic-ui-react';
import className from 'classname';

import {
    addProductionLines,
    getProductionLines,
    productionLinesList,
    productionLinesError,
    deleteProductionLines,
} from '../../../ducks/productionLines';
import { StreamsInput, Constructor } from '../../../components';

const getNewEmptyValue = () => {
    return {
        id: uuidv4(),
    };
};

function Streams({ backProgress, nextProgress }) {
    const production = useSelector(productionLinesList);
    const [form, setForm] = useState({});
    const dispatch = useDispatch();
    const error = useSelector(productionLinesError);
    const back = () => {
        backProgress();
    };
    const handleChange = useCallback(
        (event, { name, value }) => {
            setForm((form) => ({
                ...form,
                [name]: value,
            }));
        },
        [form],
    );
    const isNext = useMemo(() => {
        return Object.values(form)
            .flat()
            .some((obj) => !obj?.name);
    }, [form]);
    const deleteItem = (item) => {
        dispatch(deleteProductionLines({ item }));
    };

    function onSubmit() {
        dispatch(addProductionLines({ data: form, callbackSuccess: nextProgress }));
    }

    useEffect(() => {
        setForm({
            streams: production && production.length > 0 ? production : [getNewEmptyValue()],
        });
    }, [production]);

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
    }, []);

    return (
        <div className="streams">
            <div className="streams__body">
                <h2 className="streams__title">Шаг 2. Настройка потоков</h2>
                <div
                    className={className({
                        customers__error: error.errorCustomers,
                    })}
                >
                    <p>{error.errorCustomers}</p>
                </div>
                <Constructor
                    key={form.streams}
                    {...form}
                    value={form.streams}
                    error={error}
                    onChange={handleChange}
                    getNewEmptyValue={getNewEmptyValue}
                    name={'streams'}
                >
                    <StreamsInput deleteItem={deleteItem} />
                </Constructor>
                <div className="streams__nav">
                    <Button className="streams__button" onClick={back}>
                        <Icon name="angle left" size="large" />
                        Назад
                    </Button>
                    <Button className="streams__button" disabled={isNext} onClick={onSubmit}>
                        Продолжить
                        <Icon name="angle right" size="large" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Streams;
