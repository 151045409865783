import React from 'react';
import { Grid, Modal, Button } from 'semantic-ui-react';

import './style.scss';

function ModalDelete({ closeModal, modal, onClickDelete, text }) {
    return (
        <Modal size="mini" centered={false} onClose={closeModal} open={modal}>
            <div className="modal-delete">
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1 className="modal__title">
                                {`Вы действительно хотите удалить ${text}?`}
                            </h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row />
                    <Grid.Row />
                    <Grid.Row />
                    <Grid.Row />
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Button
                                className="modal-delete__button"
                                secondary
                                onClick={onClickDelete}
                            >
                                Да
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Button className="modal-delete__button" onClick={closeModal}>
                                Нет
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Modal>
    );
}

export default ModalDelete;
