import React from 'react';

import Customers from './customers';
import EndSetting from './endSetting';
import RolesAndPersonnel from './rolesAndPersonnel';
import Information from './information';
import Streams from './streams';

function WizardContent({ progress, nextProgress, backProgress }) {
    switch (progress) {
        case 0:
            return <Information nextProgress={nextProgress} />;
        case 1:
            return <Streams backProgress={backProgress} nextProgress={nextProgress} />;
        case 2:
            return <RolesAndPersonnel nextProgress={nextProgress} backProgress={backProgress} />;
        case 3:
            return <Customers backProgress={backProgress} nextProgress={nextProgress} />;
        case 4:
            return <EndSetting />;
    }
}

export default WizardContent;
