import React, { useMemo } from 'react';
import { Grid, Input, Modal, Button, Dropdown, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import className from 'classnames';

import { addPersonal, editPersonal, userError, clearError, getLoading } from '../../../ducks/user';
import { countryRole, USER_ROLES } from '../../../utils/const';
import { errorListUser } from '../../../utils/const';

function ModalUser({ fetchData, modalData, closeModal, stream }) {
    const dispatch = useDispatch();
    const error = useSelector(userError);
    const loading = useSelector(getLoading);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: data ? data.key : null,
            name: data ? data.FIO : null,
            password: data ? data.password : null,
            role: data ? data.role : null,
            productionLineId: data ? data.productionLineId : null,
            isActive: data.key ? data.isActive : true,
        },
        onSubmit: (values) => {
            dispatch(
                !values.id
                    ? addPersonal({
                          data: values,
                          callbackSuccess,
                      })
                    : editPersonal({
                          data: values,
                          callbackSuccess,
                      }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const onChange = (event, { value, name }) => {
        let newValue;

        if (name === 'isActive') {
            newValue = !values.isActive;
        } else {
            newValue = value;
        }
        setFieldValue(name, newValue);
    };
    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const isNext = useMemo(() => {
        if (values.role === USER_ROLES.QA || values.role === USER_ROLES.PREPRODUCER) {
            if (values.password && values.name) {
                return false;
            }
        }

        if (values.role && values.password && values.name && values.productionLineId) {
            return false;
        }

        if (values.id && values.role && values.name) {
            return false;
        }

        return true;
    }, [values]);

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <div
                    className={className({
                        modal__error: error,
                    })}
                >
                    <p>{error && errorListUser[error]}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {values.id ? 'Изменение сотрудника' : 'Новый сотрудник'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    toggle
                                    onChange={onChange}
                                    className="modal__check"
                                    label={values.isActive ? 'Активен' : 'Неактивен'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="name"
                                    iconPosition="left"
                                    icon="user"
                                    type="text"
                                    value={values.name}
                                    placeholder="ФИО"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Dropdown
                                    search
                                    name="role"
                                    placeholder="Выберите роль"
                                    selection
                                    onChange={onChange}
                                    options={countryRole}
                                    value={values.role}
                                    fluid
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4} className="modal__column">
                                <p className="modal__subtitle">Пин-код</p>
                            </Grid.Column>
                            <Grid.Column width={3} className="modal__column">
                                <Input
                                    name="password"
                                    type="text"
                                    value={values.password}
                                    placeholder="0000"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                            <Grid.Column width={7} floated="right">
                                {!(
                                    values.role === USER_ROLES.QA ||
                                    values.role === USER_ROLES.PREPRODUCER
                                ) && (
                                    <Dropdown
                                        search
                                        name="productionLineId"
                                        placeholder="Выберите поток"
                                        selection
                                        onChange={onChange}
                                        options={stream}
                                        value={values.productionLineId}
                                        fluid
                                        className="modal__input"
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalUser;
