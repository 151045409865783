import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { ls, setOptionsId } from '../utils/functionHelper';
import { postman, setAccessToken } from '../utils/postman';
import { ACCESS_TOKEN_KEY, USER_ROLE, USER_ROLES } from '../utils/const';

//*                                                       /Auth /User                                             *//

//*  TYPES  *//

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';

export const AUTHORIZATION_USER_REQUEST = 'AUTHORIZATION_USER_REQUEST';
export const AUTHORIZATION_USER_SUCCESS = 'AUTHORIZATION_USER_SUCCESS';

export const GET_PERSONAL_LIST_REQUEST = 'GET_PERSONAL_LIST_REQUEST';
export const GET_PERSONAL_LIST_SUCCESS = 'GET_PERSONAL_LIST_SUCCESS';

export const ADD_NEW_PERSONAL_REQUEST = 'ADD_NEW_PERSONAL_REQUEST';
export const ADD_NEW_PERSONAL_SUCCESS = 'ADD_NEW_PERSONAL_SUCCESS';

export const EDIT_PERSONAL_REQUEST = 'EDIT_PERSONAL_REQUEST';
export const EDIT_PERSONAL_SUCCESS = 'EDIT_PERSONAL_SUCCESS';

export const DELETE_PERSONAL_REQUEST = 'DELETE_PERSONAL_REQUEST';
export const DELETE_PERSONAL_SUCCESS = 'DELETE_PERSONAL_SUCCESS';

export const ERROR_HANDLER_USER = 'ERROR_HANDLER_USER';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    user: [],
    error: null,
    isAuth: false,
    personalList: [],
};

//*  REDUCER  *//

export default function user(state = initial, action) {
    switch (action.type) {
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                user: action.payload ? action.payload : [],
            };
        case ERROR_HANDLER_USER:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case AUTHORIZATION_USER_SUCCESS:
            return {
                ...state,
                isAuth: true,
                error: null,
            };
        case GET_PERSONAL_LIST_SUCCESS:
            return {
                ...state,
                personalList: action.payload,
            };
        case ADD_NEW_PERSONAL_REQUEST:
        case EDIT_PERSONAL_REQUEST:
        case DELETE_PERSONAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_PERSONAL_SUCCESS:
        case EDIT_PERSONAL_SUCCESS:
        case DELETE_PERSONAL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const deletePersonal = (payload) => {
    return {
        type: DELETE_PERSONAL_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_USER,
    };
};

export const addPersonal = (payload) => {
    return {
        type: ADD_NEW_PERSONAL_REQUEST,
        payload,
    };
};

export const editPersonal = (payload) => {
    return {
        type: EDIT_PERSONAL_REQUEST,
        payload,
    };
};

export const getUserList = (payload) => {
    return {
        type: GET_USER_LIST_REQUEST,
        payload,
    };
};

export const getPersonalList = (payload) => {
    return {
        type: GET_PERSONAL_LIST_REQUEST,
        payload,
    };
};

export const authorization = (payload) => {
    return {
        type: AUTHORIZATION_USER_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseUserState = (state) => state.user;

export const userAll = createSelector(
    [baseUserState],
    (data) => data.user && setOptionsId(data.user),
);

export const getLoading = createSelector([baseUserState], (state) => state.loading);

export const personalAll = createSelector(
    [baseUserState],
    (data) =>
        data.personalList &&
        data.personalList.map((item) => {
            return {
                value: item.id,
                id: item.id,
                text: item.name,
                streams: item.productionLineId,
            };
        }),
);

export const userList = createSelector(
    [baseUserState],
    (data) =>
        data.personalList &&
        data.personalList.map((value) => {
            return {
                key: value.id,
                FIO: value.name,
                isActive: value.isActive,
                role: value.role,
                productionLineId: value.productionLineId,
            };
        }),
);

export const userError = createSelector([baseUserState], (state) => state.error);

//*  SAGA  *//

function* workerAuthorization({ payload }) {
    try {
        const { callbackSuccess, data } = payload;
        const response = yield postman.post(`/Auth/Login`, data);

        ls(ACCESS_TOKEN_KEY, response.token);
        ls(USER_ROLE, response.role);
        setAccessToken(response.token);

        const responseSetting = yield postman.get('/Settings/SetupComplete');

        yield put({
            type: AUTHORIZATION_USER_SUCCESS,
        });
        callbackSuccess(responseSetting.value === 'true' ? '/' : '/wizard');
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerGetUser({ payload }) {
    try {
        const response = yield postman.get(`/Auth/Users?`, {
            params: {
                role: payload,
            },
        });

        yield put({
            type: GET_USER_LIST_SUCCESS,
            payload: response,
        });
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title ? data.title : err.status,
        });
    }
}

function* workerGetPersonalList({ payload }) {
    try {
        let { nameSubstring, roles, isActive, pageSize, productionLineId } = payload;

        roles = roles.length > 0 ? roles : Object.values(USER_ROLES);
        const responseUser = yield postman.get(`/Users?`, {
            params: {
                nameSubstring,
                isActive,
                roles,
                pageSize,
                productionLineId,
            },
        });

        yield put({
            type: GET_PERSONAL_LIST_SUCCESS,
            payload: responseUser.items,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddPersonal({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.post(`/Users`, data);

        yield put({
            type: ADD_NEW_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.errorCode,
        });
    }
}

function* workerDeletePersonal({ payload }) {
    try {
        const { id, callbackSuccess } = payload;

        yield postman.delete(`/Users/${id}`);

        yield put({
            type: DELETE_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditPersonal({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.put(`/Users/${data.id}`, data);

        yield put({
            type: EDIT_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.errorCode,
        });
    }
}

export function* watchUser() {
    yield takeEvery(AUTHORIZATION_USER_REQUEST, workerAuthorization);
    yield takeEvery(GET_USER_LIST_REQUEST, workerGetUser);
    yield takeEvery(GET_PERSONAL_LIST_REQUEST, workerGetPersonalList);
    yield takeEvery(ADD_NEW_PERSONAL_REQUEST, workerAddPersonal);
    yield takeEvery(DELETE_PERSONAL_REQUEST, workerDeletePersonal);
    yield takeEvery(EDIT_PERSONAL_REQUEST, workerEditPersonal);
}
