import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import className from 'classname';

import { errorRole, setRole, settingRole } from '../../../ducks/setting';
import { getProductionLines } from '../../../ducks/productionLines';
import { USER_ROLES } from '../../../utils/const';
import { RoleAddAccordion, Constructor } from '../../../components';
import RolesConstructor from '../../../utils/rolesConstructor';

const formObj = [
    new RolesConstructor('seamstress', 'Швея', USER_ROLES.PRODUCER, true),
    new RolesConstructor('master', 'Мастер', USER_ROLES.MASTER, true),
    new RolesConstructor('operator', 'Оператор', USER_ROLES.OPERATOR, false),
    new RolesConstructor('cutter', 'Закройщик', USER_ROLES.PREPRODUCER, false),
    new RolesConstructor('otc', 'ОТК', USER_ROLES.QA, false),
];

function getDefaultForm() {
    let form = {};

    formObj.forEach((item) => {
        form[item.name] = item.defaultValue;
    });

    return form;
}

function RolesAndPersonnel({ nextProgress, backProgress }) {
    const setting = useSelector(settingRole);
    const [form, setForm] = useState(setting ? setting : getDefaultForm);
    const dispatch = useDispatch();
    const error = useSelector(errorRole);
    const handleChange = useCallback(
        (event, { name, value }) => {
            setForm((form) => ({
                ...form,
                [name]: value,
            }));
        },
        [form],
    );
    const isNext = useMemo(() => {
        return Object.values(form)
            .flat()
            .some(({ name, password }) => !name || !password);
    }, [form]);

    const back = () => {
        dispatch(setRole(form));
        backProgress();
    };

    function onSubmit() {
        dispatch(setRole(form));
        nextProgress();
    }

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
    }, []);

    return (
        <div className="roles-and-personnel">
            <div className="roles-and-personnel__body">
                <h2 className="roles-and-personnel__title">Шаг 3. Выбор и распределение ролей</h2>
                <hr className="roles-and-personnel__hr" defaultChecked />
                <br />
                <div
                    className={className({
                        'roles-and-personnel__error': !!error.errorRole,
                    })}
                >
                    <p>{error.errorRole}</p>
                </div>
                {formObj.map((item) => (
                    <Constructor
                        key={item.name}
                        {...item}
                        value={form[item.name]}
                        error={error}
                        onChange={handleChange}
                    >
                        <RoleAddAccordion />
                    </Constructor>
                ))}
                <div className="roles-and-personnel__nav">
                    <Button className="roles-and-personnel__button" onClick={back}>
                        <Icon name="angle left" size="large" />
                        Назад
                    </Button>
                    <Button
                        className="roles-and-personnel__button"
                        disabled={isNext}
                        onClick={onSubmit}
                    >
                        Продолжить
                        <Icon name="angle right" size="large" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default RolesAndPersonnel;
