import { combineReducers } from 'redux';

import user from '../ducks/user';
import setting from '../ducks/setting';
import order from '../ducks/orders';
import createOrder from '../ducks/createOrders';
import customer from '../ducks/client';
import report from '../ducks/report';
import productionLines from '../ducks/productionLines';
import size from '../ducks/size';
import color from '../ducks/color';
import operation from '../ducks/operation';
import model from '../ducks/model';

export default combineReducers({
    user,
    setting,
    order,
    createOrder,
    customer,
    report,
    productionLines,
    size,
    color,
    operation,
    model,
});
