import React, { useState } from 'react';

import { numberDays, getColor, dateParseAndFormat } from '../../../utils/functionHelper';
import { Popup } from 'semantic-ui-react';

function ItemDnD({ data, full, setCurrentItem, end }) {
    const [hover, setHover] = useState(false);
    const width = 49;
    const date = new Date();
    const day =
        numberDays(new Date(data.startDate), new Date(data.endDate ? data.endDate : end)) + 1;
    const backDay = numberDays(
        new Date(data.startDate),
        new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    const nextDay = numberDays(
        new Date(date.getFullYear(), date.getMonth(), date.getDate()),
        new Date(data.endDate ? data.endDate : end),
    );
    const color = getColor(data.number);
    const styleDefault = {
        width: `${width * day}px`,
    };
    const styleBack = {
        width: `${width * backDay}px`,
    };
    const styleNext = {
        width: `${width * (nextDay + 1)}px`,
    };

    function dragStartHandler(e) {
        setCurrentItem(data);
        setHover(false);
        let elem = document.createElement('div');

        elem.className = 'drag-ghost';
        elem.textNode = 'Dragging';
        elem.style.position = 'absolute';
        elem.style.top = '-1000px';
        let div = document.createElement('div');

        div.appendChild(elem);
        document.querySelector('body').appendChild(div);
        e.dataTransfer.setDragImage(div, -10, -10);
    }
    const onOpenPopup = () => {
        setHover(data.orderId);
    };

    const onClosePopup = (e, { type }) => {
        if (e.type === 'mouseleave') {
            setHover(false);
        }
    };

    return (
        <Popup
            trigger={
                dateParseAndFormat(data.startDate) === full &&
                (new Date(data.startDate) >=
                new Date(date.getFullYear(), date.getMonth(), date.getDate()) ? (
                    <div className="table__list">
                        <div
                            className={`table__item ${color}`}
                            style={styleDefault}
                            onDragStart={dragStartHandler}
                            draggable={true}
                        >
                            <span>
                                {`№${data.number} `}
                                {data.readinessPercentage !== 0 &&
                                    ` (${data.readinessPercentage}%)`}
                                {!data.endDate && `(Бесконечность)`}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="table__list">
                        <div
                            style={styleBack}
                            draggable={true}
                            onDragStart={dragStartHandler}
                            className={`table__item--start ${color}-bright`}
                        >
                            {<span>{`${data.readinessPercentage}%`}</span>}
                        </div>
                        <div
                            className={`table__item--end ${color}`}
                            style={styleNext}
                            draggable={true}
                            onDragStart={dragStartHandler}
                        >
                            <span>
                                {`№${data.number}`} {!data.endDate && `(Бесконечность)`}
                            </span>
                        </div>
                    </div>
                ))
            }
            position="top left"
            on={['hover']}
            open={hover}
            onOpen={onOpenPopup}
            onClose={onClosePopup}
        >
            <Popup.Content>
                {data.models.map(({ name, count }, index) => {
                    if (index > 0) {
                        return `; ${name}: ${count}`;
                    } else {
                        return `${name}: ${count}`;
                    }
                })}
            </Popup.Content>
        </Popup>
    );
}

export default ItemDnD;
