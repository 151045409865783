import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { HeaderOrder } from '../../components';
import FormDistribution from './components/formDistribution';
import { getOrder, order } from '../../ducks/createOrders';
import { USER_ROLES } from '../../utils/const';
import { getPersonalList } from '../../ducks/user';
import { getProductionLines } from '../../ducks/productionLines';

import './style.scss';

function OrderDistribution(prop) {
    const id = prop.location.pathname.split('/')[2];
    const orderData = useSelector(order);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form, setForm] = useState([]);
    const onClickOrder = () => {
        history.push('/');
    };

    useEffect(() => {
        setForm(orderData);
    }, [orderData]);

    useEffect(() => {
        dispatch(getProductionLines({ isActive: true }));
        dispatch(getOrder(id));
        dispatch(getPersonalList({ roles: USER_ROLES.PRODUCER, isActive: true, pageSize: 10000 }));
    }, []);

    return (
        <div className="order-distribution">
            <div className="order-distribution__body">
                <span className="order-distribution__nav" onClick={onClickOrder}>
                    <Icon name="angle left" size="large" />
                    Список заказов
                </span>
                <HeaderOrder
                    onSubmit={onClickOrder}
                    status={form.status}
                    customer={form.customer && form.customer.name}
                    name={
                        form.createDate && {
                            date: form.createDate,
                            number: form.number,
                        }
                    }
                />
                <FormDistribution form={form} setForm={setForm} id={id} />
            </div>
        </div>
    );
}

export default OrderDistribution;
