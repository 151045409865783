import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { dateFormatDD } from '../utils/functionHelper';
import { parseISO } from 'date-fns';

//*                                                       /Sizes                                                *//

//*  TYPES  *//

export const GET_SIZE_REQUEST = 'GET_SIZE_REQUEST';
export const GET_SIZE_SUCCESS = 'GET_SIZE_SUCCESS';

export const ADD_SIZE_REQUEST = 'ADD_SIZE_REQUEST';
export const ADD_SIZE_SUCCESS = 'ADD_SIZE_SUCCESS';

export const EDIT_SIZE_REQUEST = 'EDIT_SIZE_REQUEST';
export const EDIT_SIZE_SUCCESS = 'EDIT_SIZE_SUCCESS';

export const DELETE_SIZE_REQUEST = 'DELETE_SIZE_REQUEST';
export const DELETE_SIZE_SUCCESS = 'DELETE_SIZE_SUCCESS';

export const ERROR_HANDLER_SIZE = 'ERROR_HANDLER_SIZE';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: false,
    items: [],
    itemsCount: 0,
    pagesCount: 0,
};

//*  REDUCER  *//

export default function size(state = initial, action) {
    switch (action.type) {
        case ERROR_HANDLER_SIZE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_SIZE_SUCCESS:
        case DELETE_SIZE_SUCCESS:
        case ADD_SIZE_SUCCESS:
        case EDIT_SIZE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case DELETE_SIZE_REQUEST:
        case ADD_SIZE_REQUEST:
        case GET_SIZE_REQUEST:
        case EDIT_SIZE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const clearError = () => {
    return {
        type: ERROR_HANDLER_SIZE,
    };
};

export const getSize = (payload) => {
    return {
        type: GET_SIZE_REQUEST,
        payload,
    };
};

export const deleteSize = (payload) => {
    return {
        type: DELETE_SIZE_REQUEST,
        payload,
    };
};

export const addSize = (payload) => {
    return {
        type: ADD_SIZE_REQUEST,
        payload,
    };
};

export const editSize = (payload) => {
    return {
        type: EDIT_SIZE_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseSizeState = (state) => state.size;

export const getLoading = createSelector([baseSizeState], (state) => state.loading);

export const sizeList = createSelector(
    [baseSizeState],
    (data) =>
        data.items &&
        data.items.map((value) => {
            return {
                ...value,
                createDate: dateFormatDD(parseISO(value.createDate)),
            };
        }),
);

export const sizeError = createSelector([baseSizeState], (state) => state.error);

//*  SAGA  *//

function* workerGetSize({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Sizes`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
            },
        });

        yield put({
            type: GET_SIZE_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddSize({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.post(`/Sizes`, { ...data });

        yield put({
            type: GET_SIZE_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditSize({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.put(`/Sizes/${data.id}`, { ...data });

        yield put({
            type: EDIT_SIZE_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteSize({ payload }) {
    try {
        const { item, callbackSuccess } = payload;

        yield postman.put(`/Sizes/${item.id}`, { ...item, isActive: !item.isActive });
        yield put({
            type: DELETE_SIZE_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchSize() {
    yield takeEvery(ADD_SIZE_REQUEST, workerAddSize);
    yield takeEvery(EDIT_SIZE_REQUEST, workerEditSize);
    yield takeEvery(GET_SIZE_REQUEST, workerGetSize);
    yield takeEvery(DELETE_SIZE_REQUEST, workerDeleteSize);
}
