import { v4 as uuidv4 } from 'uuid';

const getNewPosition = role => {
    return {
        id: uuidv4(),
        role,
    };
};

class RolesConstructor {
    constructor(name, title, role, isOneDefault) {
        this.name = name;
        this.title = title;
        this.role = role;
        this.isOneDefault = isOneDefault;
        this.defaultValue = isOneDefault ? [getNewPosition(role)] : [];
    }
    getNewEmptyValue = () => getNewPosition(this.role);
}

export default RolesConstructor;
