import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LOGIN_LINK } from './links';
import { ls } from '../utils/functionHelper';
import { ACCESS_TOKEN_KEY } from '../utils/const';

function PrivateRoute({ component: Component }) {
    if (ls(ACCESS_TOKEN_KEY)) {
        return <Route render={(prop) => <Component {...prop} />} />;
    }

    return (
        <Redirect
            to={{
                pathname: LOGIN_LINK,
            }}
        />
    );
}

export default PrivateRoute;
