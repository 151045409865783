import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { dateFormatMM } from '../utils/functionHelper';
import { STATUS_ORDER } from '../utils/const';

//*                                                       /ReportPage /Expenses                                               *//

//*  TYPES  *//

export const GET_PROFITABILITY_REQUEST = 'GET_PROFITABILITY_REQUEST';
export const GET_PROFITABILITY_SUCCESS = 'GET_PROFITABILITY_SUCCESS';

export const GET_SALARY_REQUEST = 'GET_SALARY_REQUEST';
export const GET_SALARY_SUCCESS = 'GET_SALARY_SUCCESS';

export const GET_EXPENSES_REQUEST = 'GET_EXPENSES_REQUEST';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';

export const ADD_EXPENSES_REQUEST = 'ADD_EXPENSES_REQUEST';
export const ADD_EXPENSES_SUCCESS = 'ADD_EXPENSES_SUCCESS';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';

export const GET_TIMELINE_REQUEST = 'GET_TIMELINE_REQUEST';
export const GET_TIMELINE_SUCCESS = 'GET_TIMELINE_SUCCESS';

export const SET_TIMELINE_REQUEST = 'SET_TIMELINE_REQUEST';
export const SET_TIMELINE_SUCCESS = 'SET_TIMELINE_SUCCESS';

export const EDIT_EXPENSES_REQUEST = 'EDIT_EXPENSES_REQUEST';
export const EDIT_EXPENSES_SUCCESS = 'EDIT_EXPENSES_SUCCESS';

export const ERROR_HANDLER_REPORT = 'ERROR_HANDLER_REPORT';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: null,
    data: null,
    expenses: null,
    salary: null,
    report: null,
    timeline: null,
};

//*  REDUCER  *//

export default function report(state = initial, action) {
    switch (action.type) {
        case GET_SALARY_SUCCESS:
        case GET_EXPENSES_SUCCESS:
        case GET_PROFITABILITY_SUCCESS:
        case GET_REPORT_SUCCESS:
        case SET_TIMELINE_SUCCESS:
        case GET_TIMELINE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case ERROR_HANDLER_REPORT:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SET_TIMELINE_REQUEST:
        case ADD_EXPENSES_REQUEST:
        case GET_PROFITABILITY_REQUEST:
        case GET_REPORT_REQUEST:
        case GET_TIMELINE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_EXPENSES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getReport = (payload) => {
    return {
        type: GET_REPORT_REQUEST,
        payload,
    };
};

export const setTimeline = (payload) => {
    return {
        type: SET_TIMELINE_REQUEST,
        payload,
    };
};

export const getTimeline = (payload) => {
    return {
        type: GET_TIMELINE_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_REPORT,
    };
};

export const addExtension = (payload) => {
    return {
        type: ADD_EXPENSES_REQUEST,
        payload,
    };
};

export const editExtension = (payload) => {
    return {
        type: ADD_EXPENSES_REQUEST,
        payload,
    };
};

export const getSalary = (payload) => {
    return {
        type: GET_SALARY_REQUEST,
        payload,
    };
};

export const getExpenses = (payload) => {
    return {
        type: GET_EXPENSES_REQUEST,
        payload,
    };
};

export const getProfitability = (payload) => {
    return {
        type: GET_PROFITABILITY_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseReportState = (state) => state.report;

export const getLoading = createSelector([baseReportState], (state) => state.loading);

export const profitabilityData = createSelector([baseReportState], (state) => state.data);

export const salaryData = createSelector([baseReportState], (state) => state.salary);

export const timelineData = createSelector([baseReportState], (state) => state.timeline);

export const reportData = createSelector([baseReportState], (state) => state.report);

export const statusLoading = createSelector([baseReportState], (state) => state.loading);

export const expensesData = createSelector(
    [baseReportState],
    (data) =>
        data.expenses &&
        data.expenses.map((value) => {
            return {
                name: value.expensesType.name,
                amount: value.amount,
                key: value.id,
            };
        }),
);

export const profitabilityError = createSelector([baseReportState], (state) => state.error);

//*  SAGA  *//

function* workerGetProfitability({ payload }) {
    try {
        const { year, month, day, factValues } = payload;
        const data = yield postman.get(`/Report/GetDailyReport`, {
            params: {
                year,
                month,
                day,
                factValues,
            },
        });

        yield put({
            type: GET_PROFITABILITY_SUCCESS,
            payload: {
                data,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetExpenses({ payload }) {
    try {
        const { year, month } = payload;
        const expenses = yield postman.get(`/Expenses/${year}/${month}`);

        yield put({
            type: GET_EXPENSES_SUCCESS,
            payload: {
                expenses,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditExpenses({ payload }) {
    try {
        const { data, year, month, callbackSuccess } = payload;

        yield postman.put(`/Expenses/${year}/${month}/${data.key}`, { amount: data.amount });

        yield put({
            type: EDIT_EXPENSES_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_REPORT,
            payload: data.errorCode,
        });
    }
}

function* workerAddExpenses({ payload }) {
    try {
        const { data, callbackSuccess, year, month } = payload;

        yield postman.post(`/Expenses/${year}/${month}`, [
            {
                amount: data.amount,
                expensesType: { name: data.name },
            },
        ]);
        yield put({
            type: ADD_EXPENSES_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_REPORT,
            payload: data.errorCode,
        });
    }
}

function* workerGetSalary({ payload }) {
    try {
        const { startDate, endDate, productionLineId } = payload;
        const salary = yield postman.get(`/Report/GetProducersIncome`, {
            params: {
                startDate: dateFormatMM(startDate),
                endDate: dateFormatMM(endDate),
                productionLineId,
            },
        });

        yield put({
            type: GET_SALARY_SUCCESS,
            payload: {
                salary,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetReport({ payload }) {
    try {
        const { year, month } = payload;
        const report = yield postman.get(`/Report/GetMonthlyReport`, {
            params: {
                year,
                month,
            },
        });

        yield put({
            type: GET_REPORT_SUCCESS,
            payload: {
                report,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetTimeline({ payload }) {
    try {
        let { statuses } = payload;

        statuses = statuses.length > 0 ? statuses : Object.values(STATUS_ORDER);

        const timeline = yield postman.get(`/Report/GetReadinessForecast`, {
            params: {
                statuses,
            },
        });

        yield put({
            type: GET_TIMELINE_SUCCESS,
            payload: { timeline },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerSetTimeline({ payload }) {
    try {
        const { full, currentItem, stream, callbackSuccess } = payload;
        const [day, mont, year] = full.split('.');
        const newData = new Date(year, mont - 1, +day + 1, 0);
        const order = yield postman.get(`/Orders/${currentItem.orderId}`);

        order.startDate = newData;
        order.productionLineId = stream;

        yield postman.put(`/Orders/${currentItem.orderId}`, { ...order });

        yield put({
            type: SET_TIMELINE_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchReport() {
    yield takeEvery(GET_PROFITABILITY_REQUEST, workerGetProfitability);
    yield takeEvery(GET_EXPENSES_REQUEST, workerGetExpenses);
    yield takeEvery(ADD_EXPENSES_REQUEST, workerAddExpenses);
    yield takeEvery(EDIT_EXPENSES_REQUEST, workerEditExpenses);
    yield takeEvery(GET_SALARY_REQUEST, workerGetSalary);
    yield takeEvery(GET_REPORT_REQUEST, workerGetReport);
    yield takeEvery(GET_TIMELINE_REQUEST, workerGetTimeline);
    yield takeEvery(SET_TIMELINE_REQUEST, workerSetTimeline);
}
