import React, { useState } from 'react';

import WizardContent from './components/wizardContent';
import HeaderWizard from './components/headerWizard';

import './style.scss';

function Wizard() {
    const [progress, setProgress] = useState(0);
    const nextProgress = () => {
        setProgress(progress + 1);
    };
    const backProgress = () => {
        setProgress(progress - 1);
    };

    return (
        <div className="wizard">
            <div className="wizard__body">
                {progress !== 4 && <HeaderWizard progress={progress} />}
                <WizardContent
                    backProgress={backProgress}
                    nextProgress={nextProgress}
                    progress={progress}
                />
            </div>
        </div>
    );
}

export default Wizard;
