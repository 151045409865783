import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputDistribution from './inputDistribution';
import {
    setOrderOperations,
    setDeleteOrderOperations,
    getError,
} from '../../../ducks/createOrders';
import { personalAll } from '../../../ducks/user';
import { productionLinesOption } from '../../../ducks/productionLines';
import { errorListOrder } from '../../../utils/const';

function FormDistribution({ form, setForm, id }) {
    const dispatch = useDispatch();
    const personalOptions = useSelector(personalAll);
    const streams = useSelector(productionLinesOption);
    const error = useSelector(getError);
    const addOperations = (index, elementIndex, id, maxValue) => {
        const newOrderOperation = [...form.positions];
        const tempElement = newOrderOperation?.[index]?.model?.operations;

        if (tempElement[elementIndex].orderOperation) {
            tempElement[elementIndex] = {
                ...tempElement[elementIndex],
                orderOperation: [
                    ...tempElement[elementIndex].orderOperation,
                    {
                        operationId: id,
                        isEdit: true,
                    },
                ],
            };
        } else {
            tempElement[elementIndex] = {
                ...tempElement[elementIndex],
                maxValue,
                orderOperation: [
                    {
                        operationId: id,
                        isEdit: true,
                    },
                ],
            };
        }

        setForm(Object.assign({}, form, { positions: newOrderOperation }));
    };

    const editOperation = (index, elementIndex, indexInput, value) => {
        const newOperation = [...form.positions];
        const tempElement = newOperation?.[index]?.model?.operations?.[elementIndex];

        tempElement.orderOperation[indexInput].isEdit = true;
        tempElement.maxValue += value ? value : 0;
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const addOrderOperation = (index, elementIndex, indexInput, value) => {
        const newOperation = [...form.positions];
        const tempElement = newOperation[index].model.operations[elementIndex];
        const updateData = (newData) => {
            tempElement.maxValue =
                newOperation[index].count -
                newData.reduce((prev, next) => prev + next.requiredCount, 0);
            tempElement.orderOperation = newData;
            setForm(Object.assign({}, form, { positions: newOperation }));
        };

        dispatch(
            setOrderOperations({
                ...value,
                idOrder: form.id,
                idPosition: newOperation[index].id,
                updateData,
            }),
        );
    };

    const deleteOperation = (index, elementIndex, indexInput, value) => {
        const newOperation = [...form.positions];
        const tempElement = newOperation?.[index]?.model?.operations?.[elementIndex];
        const updateData = (newData) => {
            tempElement.maxValue =
                newOperation[index].count -
                newData.reduce((prev, next) => prev + next.requiredCount, 0);
            tempElement.orderOperation = newData;
            setForm(Object.assign({}, form, { positions: newOperation }));
        };

        if (tempElement.orderOperation[indexInput].id) {
            dispatch(
                setDeleteOrderOperations({
                    idOrder: form.id,
                    idPosition: newOperation[index].id,
                    ...value,
                    updateData,
                }),
            );
            return;
        }

        tempElement.orderOperation.splice(indexInput, 1);
        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    const onChange = (event, { name, value, id }) => {
        const newOperation = [...form.positions];
        const [input, index, inputIndex, maxValue] = name.split('.');
        const tempElement =
            newOperation?.[id]?.model?.operations?.[index]?.orderOperation?.[inputIndex];

        if (!isNaN(value)) {
            tempElement[input] = +value > 0 ? (+value > maxValue ? +maxValue : +value) : 0;
        } else {
            if (input === 'producerId') {
                const { streams } = personalOptions.find((item) => item.value === value);
                tempElement.productionLineId = streams;
            }
            tempElement[input] = value;
        }

        setForm(Object.assign({}, form, { positions: newOperation }));
    };

    return (
        <div className="form-distribution">
            {error && (
                <div className="form-distribution__error">
                    <p>{error && errorListOrder[error]}</p>
                </div>
            )}
            <div className="form-distribution__body">
                <h2 className="form-distribution__section">Состав заказа</h2>
                <InputDistribution
                    costPerSecond={form.costPerSecond}
                    form={form.positions}
                    orderId={form.id}
                    setForm={setForm}
                    addOperations={addOperations}
                    onChange={onChange}
                    deleteOperation={deleteOperation}
                    editOperation={editOperation}
                    addOrderOperation={addOrderOperation}
                    streams={streams}
                    personalOptions={personalOptions}
                    id={id}
                />
            </div>
        </div>
    );
}

export default FormDistribution;
