import React, { useState } from 'react';
import { Input, Dropdown, Grid, Icon, Accordion } from 'semantic-ui-react';

import { getPositionCostString } from '../../../utils/functionHelper';
import ModalOperationGroupId from './modal';

function InputDistribution({
    costPerSecond,
    form,
    addOperations,
    onChange,
    deleteOperation,
    editOperation,
    addOrderOperation,
    personalOptions,
    streams,
    orderId,
    id,
}) {
    const [modal, setModal] = useState({
        isActive: false,
    });
    const [active, setActive] = useState(false);

    const onClickGroupOperation = (item) => {
        setModal(
            Object.assign({}, modal, {
                isActive: true,
                item,
            }),
        );
    };

    const onCloseModal = () => {
        setModal({ isActive: false });
    };

    return (
        <div className="form-distribution__container">
            {form &&
                form.map((item, index) => (
                    <Accordion className="form-distribution__container" key={item.count + index}>
                        <Grid className="form-distribution__grid">
                            <Grid.Row
                                className="form-distribution__row--title"
                                onClick={
                                    active === index
                                        ? () => setActive(false)
                                        : () => setActive(index)
                                }
                            >
                                <Grid.Column width={12}>
                                    <span className="form-distribution__title">
                                        {item.model.name}
                                    </span>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Accordion.Title active={active === true} index={index}>
                                        <p className="form-distribution__dropdown">
                                            {active === index
                                                ? `Операции (${item.model.operations.length})`
                                                : ''}
                                            <Icon
                                                name={
                                                    ![...item.model.operations].some(
                                                        ({ maxValue }) => !(maxValue === 0),
                                                    )
                                                        ? 'check circle'
                                                        : active === index
                                                        ? 'chevron down'
                                                        : 'chevron up'
                                                }
                                                size="large"
                                                color={
                                                    ![...item.model.operations].some(
                                                        ({ maxValue }) => !(maxValue === 0),
                                                    )
                                                        ? 'green'
                                                        : ''
                                                }
                                                className="form-distribution__icon"
                                            />
                                        </p>
                                    </Accordion.Title>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="form-distribution__row-body">
                                <Grid.Column width={3}>
                                    <span className="form-distribution__subtitle">
                                        Размер: {item.size.name}
                                    </span>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <span className="form-distribution__subtitle">
                                        Цвет: {item.color.name}
                                    </span>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <span className="form-distribution__subtitle">
                                        Количество: {item.count}
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                            {index === active && (
                                <>
                                    <Grid.Row className="form-distribution__row-body">
                                        <Grid.Column>
                                            <button
                                                className="form-distribution__button-add"
                                                onClick={() => onClickGroupOperation(item)}
                                            >
                                                <p>
                                                    <Icon name="add circle" size="large" />
                                                    Назначить группу операций
                                                </p>
                                            </button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {item.model.operations &&
                                        item.model.operations.map((element, elementIndex) => (
                                            <>
                                                <Grid.Row
                                                    className="form-distribution__row"
                                                    key={elementIndex}
                                                >
                                                    <Grid.Column>
                                                        <p className="form-distribution__model-name">
                                                            {element.name}
                                                        </p>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {element.orderOperation &&
                                                    element.orderOperation.map(
                                                        (input, indexInput) => (
                                                            <Grid.Row key={indexInput}>
                                                                <Grid.Column width={5}>
                                                                    <Dropdown
                                                                        id={index}
                                                                        className="form-distribution__select"
                                                                        placeholder="Выберите сотрудника"
                                                                        search
                                                                        selection
                                                                        fluid
                                                                        disabled={!input.isEdit}
                                                                        options={personalOptions}
                                                                        onChange={onChange}
                                                                        value={input.producerId}
                                                                        name={`producerId.${elementIndex}.${indexInput}`}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={3}>
                                                                    <Dropdown
                                                                        id={index}
                                                                        className="form-distribution__select"
                                                                        placeholder="Поток"
                                                                        search
                                                                        selection
                                                                        fluid
                                                                        disabled={!input.isEdit}
                                                                        options={streams}
                                                                        onChange={onChange}
                                                                        value={
                                                                            input.productionLineId
                                                                        }
                                                                        name={`productionLineId.${elementIndex}.${indexInput}`}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={3}>
                                                                    <div className="status">
                                                                        {element.maxValue !== 0 && (
                                                                            <div className="status__not-full" />
                                                                        )}
                                                                    </div>
                                                                    <Input
                                                                        id={index}
                                                                        className="form-distribution__input"
                                                                        placeholder="0"
                                                                        onChange={onChange}
                                                                        disabled={!input.isEdit}
                                                                        value={input.requiredCount}
                                                                        type="number"
                                                                        name={`requiredCount.${elementIndex}.${indexInput}.${element.maxValue}`}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    width={3}
                                                                    textAlign="center"
                                                                >
                                                                    <p className="form-distribution__сost">
                                                                        {input.requiredCount > 0 &&
                                                                            getPositionCostString(
                                                                                element.duration,
                                                                                costPerSecond,
                                                                                input.requiredCount,
                                                                            )}
                                                                    </p>
                                                                </Grid.Column>
                                                                <Grid.Column
                                                                    width={1}
                                                                    floated="right"
                                                                >
                                                                    <span
                                                                        className="form-distribution__button"
                                                                        onClick={
                                                                            input.isEdit
                                                                                ? input.requiredCount &&
                                                                                  input.productionLineId &&
                                                                                  input.producerId
                                                                                    ? () =>
                                                                                          addOrderOperation(
                                                                                              index,
                                                                                              elementIndex,
                                                                                              indexInput,
                                                                                              input,
                                                                                          )
                                                                                    : ''
                                                                                : () =>
                                                                                      editOperation(
                                                                                          index,
                                                                                          elementIndex,
                                                                                          indexInput,
                                                                                          input.requiredCount,
                                                                                      )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            name={
                                                                                input.isEdit
                                                                                    ? 'check circle'
                                                                                    : 'pencil alternate'
                                                                            }
                                                                            disabled={
                                                                                input.isEdit &&
                                                                                !(
                                                                                    input.productionLineId &&
                                                                                    input.requiredCount &&
                                                                                    input.producerId
                                                                                )
                                                                            }
                                                                            color={
                                                                                input.isEdit
                                                                                    ? 'green'
                                                                                    : ''
                                                                            }
                                                                            size="large"
                                                                        />
                                                                    </span>
                                                                </Grid.Column>
                                                                <Grid.Column width={1}>
                                                                    <span
                                                                        className="form-distribution__button"
                                                                        onClick={() =>
                                                                            deleteOperation(
                                                                                index,
                                                                                elementIndex,
                                                                                indexInput,
                                                                                input,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            name="trash alternate outline"
                                                                            size="large"
                                                                        />
                                                                    </span>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        ),
                                                    )}
                                                {element.maxValue !== 0 &&
                                                    !element.orderOperation.some(
                                                        (el) => el.isEdit,
                                                    ) && (
                                                        <Grid.Row className="form-distribution__row-body">
                                                            <Grid.Column>
                                                                <span
                                                                    className="form-distribution__button"
                                                                    onClick={() =>
                                                                        addOperations(
                                                                            index,
                                                                            elementIndex,
                                                                            element.id,
                                                                            item.count,
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon name="plus circle" />
                                                                    Назначить
                                                                </span>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                            </>
                                        ))}
                                </>
                            )}
                        </Grid>
                    </Accordion>
                ))}
            {modal.isActive && (
                <ModalOperationGroupId
                    open={modal.isActive}
                    model={modal.item.model}
                    idPosition={modal.item.id}
                    closeModal={onCloseModal}
                    personalOptions={personalOptions}
                    streams={streams}
                    idOrder={orderId}
                    maxCount={modal.item.count}
                    id={id}
                />
            )}
        </div>
    );
}

export default InputDistribution;
