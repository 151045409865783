import React from 'react';
import {
    CLIENT_LINK,
    COLOR_LINK,
    COSTS_LINK,
    GROUP_OPERATIONS,
    MODEL_LINK,
    OPERATION_LINK,
    ORDER_TIMELINE_LINK,
    PROFITABILITY_LINK,
    SALARY_LINK,
    SIZE_LINK,
    USER_LINK,
} from '../router/links';

export const REFRESH_TOKEN_KEY = 'refreshToken';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const USER_ROLE = 'role';

export const MANAGER = 'Manager';

export const USER_ROLES = {
    PRODUCER: 'Producer',
    MASTER: 'Master',
    OPERATOR: 'Operator',
    PREPRODUCER: 'Preproducer',
    QA: 'QA',
};

export const urlAdmin = 'https://admin.trinitycrm.ru/login';

export const countryStatus = [
    { id: 'Incomplete', value: 'Incomplete', text: 'Неполный', css: '--inc' },
    { id: 'New', value: 'New', text: 'Новый', css: '--new' },
    { id: 'Preparing', value: 'Preparing', text: 'Раскрой', css: '--cut' },
    { id: 'Prepared', value: 'Prepared', text: 'Раскроено', css: '--cuter' },
    { id: 'Producing', value: 'Producing', text: 'Швейный цех', css: '--shop' },
    { id: 'Ready', value: 'Ready', text: 'Выполнено', css: '--end' },
    { id: 'QAPassed', value: 'QAPassed', text: 'Прошел ОТК', css: '--otc' },
];

export const countryTimelineStatus = [
    { id: 'Incomplete', value: 'Incomplete', text: 'Неполный' },
    { id: 'New', value: 'New', text: 'Новый', css: '--new' },
    { id: 'Preparing', value: 'Preparing', text: 'Раскрой' },
    { id: 'Prepared', value: 'Prepared', text: 'Раскроено' },
    { id: 'Producing', value: 'Producing', text: 'Швейный цех' },
];

export const STATUS_ORDER = {
    INCOMPLETE: 'Incomplete',
    NEW: 'New',
    PREPARING: 'Preparing',
    PREPARED: 'Prepared',
    PRODUCING: 'Producing',
};

export const userRoleRus = {
    Producer: 'Швея',
    Master: 'Мастер',
    Operator: 'Оператор',
    Preproducer: 'Закрепщик',
    QA: 'ОТК',
};

export const countryRole = [
    { id: 'Producer', value: 'Producer', text: 'Швея', name: 'Швея' },
    { id: 'Master', value: 'Master', text: 'Мастер', name: 'Мастер' },
    { id: 'Operator', value: 'Operator', text: 'Оператор', name: 'Оператор' },
    { id: 'Preproducer', value: 'Preproducer', text: 'Закрепщик', name: 'Закрепщик' },
    { id: 'QA', value: 'QA', text: 'ОТК', name: 'ОТК' },
];

export const hintPosition = [
    { value: 'Размер', width: 3 },
    { value: 'Цвет', width: 6 },
    { value: 'Количество', width: 3 },
    { value: 'Название операции', width: 12 },
    { value: 'Время', width: 3 },
];

export const errorListUser = {
    'Not Found': 'Не удалось загрузить пользователей',
    Unauthorized: 'Введен неверный пароль',
    UserBlocked: 'Пользователь заблокирован',
    DuplicateEntry: 'Такой пользователь уже есть',
    500: 'Неверный адрес',
};

export const errorListClient = {
    'Not Found': 'Не удалось загрузить заказчиков',
    DuplicateEntry: 'Такой заказчик уже есть',
};

export const errorListOrder = {
    'Not Found': 'Не удалось загрузить заказ',
    IncorrectStatus: 'Не верный статус заказа',
    DuplicateEntry: 'Такой номер заказа уже есть',
};

export const countryStatusIsActive = [
    { value: true, text: 'Активен', css: '--end' },
    { value: false, text: 'Неактивен', css: '--cuter' },
];

export const countryFactValues = [
    { value: true, text: 'Фактическое', key: 'Фактическая' },
    { value: false, text: 'Прогнозируемое', key: 'Прогнозируемая' },
];

export const listMonth = [
    { key: 'January', text: 'Январь', value: 1 },
    { key: 'February', text: 'Февраль', value: 2 },
    { key: 'March', text: 'Март', value: 3 },
    { key: 'April', text: 'Апрель', value: 4 },
    { key: 'May', text: 'Май', value: 5 },
    { key: 'June', text: 'Июнь', value: 6 },
    { key: 'July', text: 'Июль', value: 7 },
    { key: 'August', text: 'Август', value: 8 },
    { key: 'September', text: 'Сентябрь', value: 9 },
    { key: 'October', text: 'Октябрь', value: 10 },
    { key: 'November', text: 'Ноябрь', value: 11 },
    { key: 'December', text: 'Декабрь', value: 12 },
];

export const listStepperWizard = ['Информация', 'Потоки', 'Роли и персонал', 'Заказчики'];
export const listStepperSetting = ['Информация об организации', 'Настройка потоков'];

export const configTableReport = [
    { key: 'dailyIncome' },
    { key: 'producerPercentage' },
    { key: 'companyPercentage' },
    { key: 'grossProfit' },
];

export const navigationConfig = [
    { text: 'ПЕРСОНАЛ', to: USER_LINK, icon: 'users' },
    { text: 'КЛИЕНТЫ', to: CLIENT_LINK, icon: 'address book' },
    { text: 'ТАЙМЛАЙН', to: ORDER_TIMELINE_LINK, icon: 'time' },
];

export const headerTableReport = [
    'ФИО швеи',
    'Заработала за день',
    '% швеи',
    '% наш',
    'Грязная прибыль',
    'Расход',
    'Чистая прибыль',
];
export const configTableSalary = [
    { title: 'ФИО', key: 'name' },
    { title: 'Зарплата', key: 'income' },
    { title: 'Время работы в сек', key: 'workedSeconds' },
    { title: 'Средняя стоимость в сек', key: 'averageCostPerSecond' },
];
export const configTableCosts = [
    { title: 'Наименование', key: 'name' },
    { title: 'Стоимость', key: 'amount' },
    { title: '', key: '' },
];
export const configTableClient = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Контакты', key: 'contacts', width: 3 },
    { title: 'Описание', key: 'description', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
export const configTableUser = [
    { title: 'ФИО', key: 'FIO', width: 4 },
    { title: 'Роль', key: 'role', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
export const configTableDefault = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Дата создания', key: 'createDate', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
export const configTableOperation = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Длительность', key: 'duration', width: 3 },
    { title: 'Дата создания', key: 'createDate', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
export const configTableOperationGroup = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Дата создания', key: 'createDate', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];

export const configTableModel = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Время выполнения', key: 'totalDuration', width: 3 },
    { title: 'Дата создания', key: 'createDate', width: 3 },
    { title: 'Описание', key: 'description', width: 4 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
export const configTableOrder = [
    { title: '№', key: 'number', width: 2 },
    { title: 'Статус', key: 'status', width: 3 },
    { title: 'Дата', key: 'createDate', width: 3 },
    { title: 'Сумма', key: 'cost', width: 3 },
    { title: 'Заказчик', key: 'customer', width: 3 },
    { title: '', key: '', width: 3 },
];

export const configListReports = [
    { title: 'Рентабельность', to: PROFITABILITY_LINK },
    { title: 'Зарплата', to: SALARY_LINK },
    { title: 'Расходы', to: COSTS_LINK },
];

export const configListDirectory = [
    { title: 'Операции', to: OPERATION_LINK },
    { title: 'Модели', to: MODEL_LINK },
    { title: 'Цвета', to: COLOR_LINK },
    { title: 'Размеры', to: SIZE_LINK },
    { title: 'Группы операций', to: GROUP_OPERATIONS },
];

export const tableConfigReport = [
    { title: 'Кол-во дней', key: 'totalDays' },
    { title: 'Осталось дней', key: 'daysLeft' },
    { title: 'Отработано дней', key: 'workedDays' },
    { title: 'План на день', key: 'commonDayPlan' },
];

export const tableConfigTotal = [
    { title: 'Расход', key: 'totalExpenses' },
    { title: 'Доход', key: 'totalIncome' },
    { title: 'Остаток', key: 'residue' },
];

export const accordionConfig = [
    {
        text: 'ОТЧЕТЫ',
        name: 'report',
        icon: 'file alternate',
        config: configListReports,
    },
    {
        text: 'СПРАВОЧНИКИ',
        name: 'directory',
        icon: 'folder',
        config: configListDirectory,
    },
];
