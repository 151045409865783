import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { dateFormatDD } from '../utils/functionHelper';
import { parseISO } from 'date-fns';

//*                                                       /Colors                                                *//

//*  TYPES  *//

export const GET_COLOR_REQUEST = 'GET_COLOR_REQUEST';
export const GET_COLOR_SUCCESS = 'GET_COLOR_SUCCESS';

export const ADD_COLOR_REQUEST = 'ADD_COLOR_REQUEST';
export const ADD_COLOR_SUCCESS = 'ADD_COLOR_SUCCESS';

export const EDIT_COLOR_REQUEST = 'EDIT_COLOR_REQUEST';
export const EDIT_COLOR_SUCCESS = 'EDIT_COLOR_SUCCESS';

export const DELETE_COLOR_REQUEST = 'DELETE_COLOR_REQUEST';
export const DELETE_COLOR_SUCCESS = 'DELETE_COLOR_SUCCESS';

export const ERROR_HANDLER_COLOR = 'ERROR_HANDLER_COLOR';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: false,
    items: [],
    itemsCount: 0,
    pagesCount: 0,
};

//*  REDUCER  *//

export default function color(state = initial, action) {
    switch (action.type) {
        case ERROR_HANDLER_COLOR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_COLOR_SUCCESS:
        case DELETE_COLOR_SUCCESS:
        case ADD_COLOR_SUCCESS:
        case EDIT_COLOR_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case DELETE_COLOR_REQUEST:
        case ADD_COLOR_REQUEST:
        case GET_COLOR_REQUEST:
        case EDIT_COLOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const clearError = () => {
    return {
        type: ERROR_HANDLER_COLOR,
    };
};

export const getColor = (payload) => {
    return {
        type: GET_COLOR_REQUEST,
        payload,
    };
};

export const deleteColor = (payload) => {
    return {
        type: DELETE_COLOR_REQUEST,
        payload,
    };
};

export const addColor = (payload) => {
    return {
        type: ADD_COLOR_REQUEST,
        payload,
    };
};

export const editColor = (payload) => {
    return {
        type: EDIT_COLOR_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseColorState = (state) => state.color;

export const getLoading = createSelector([baseColorState], (state) => state.loading);

export const colorList = createSelector(
    [baseColorState],
    (data) =>
        data.items &&
        data.items.map((value) => {
            return {
                ...value,
                createDate: dateFormatDD(parseISO(value.createDate)),
            };
        }),
);

export const colorError = createSelector([baseColorState], (state) => state.error);

//*  SAGA  *//

function* workerGetColor({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Colors`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
            },
        });

        yield put({
            type: GET_COLOR_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddColor({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.post(`/Colors`, { ...data });

        yield put({
            type: GET_COLOR_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditColor({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.put(`/Colors/${data.id}`, { ...data });

        yield put({
            type: EDIT_COLOR_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteColor({ payload }) {
    try {
        const { item, callbackSuccess } = payload;

        yield postman.put(`/Colors/${item.id}`, { ...item, isActive: !item.isActive });
        yield put({
            type: DELETE_COLOR_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchColor() {
    yield takeEvery(ADD_COLOR_REQUEST, workerAddColor);
    yield takeEvery(EDIT_COLOR_REQUEST, workerEditColor);
    yield takeEvery(GET_COLOR_REQUEST, workerGetColor);
    yield takeEvery(DELETE_COLOR_REQUEST, workerDeleteColor);
}
