import React from 'react';
import { Icon, Accordion } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

import { SETTING_LINK, LOGIN_LINK, GROUP_OPERATIONS } from '../../router/links';
import {
    ACCESS_TOKEN_KEY,
    accordionConfig,
    MANAGER,
    navigationConfig,
    USER_ROLE,
    USER_ROLES,
} from '../../utils/const';
import { ls } from '../../utils/functionHelper';

import './style.scss';

function Navigation({ activeSection, setSection }) {
    const history = useHistory();
    const role = ls(USER_ROLE);
    const onClickExit = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        history.push(LOGIN_LINK);
    };
    const onClickWizard = () => {
        history.push(SETTING_LINK);
    };
    const onClickAccordion = (ev, { name }) => {
        setSection(activeSection === name ? null : name);
    };

    return (
        <div className="navigation">
            <div className="navigation__top">
                <div className="navigation__logo"></div>
                <NavLink className="navigation__text" exact to={'/'}>
                    <Icon name="briefcase" />
                    ЗАКАЗЫ
                </NavLink>
                <hr className="navigation__hr" />
                {role === USER_ROLES.MASTER && (
                    <Accordion>
                        <Accordion.Title
                            active={activeSection === 'directory'}
                            onClick={onClickAccordion}
                            name="directory"
                        >
                            <button className="navigation__button">
                                <span>
                                    <Icon name="folder" />
                                    СПРАВОЧНИКИ
                                </span>
                            </button>
                        </Accordion.Title>
                        <Accordion.Content active={activeSection === 'directory'}>
                            <NavLink className="navigation__list" to={GROUP_OPERATIONS}>
                                <Icon name="circle outline" />
                                Группы операций
                            </NavLink>
                        </Accordion.Content>
                    </Accordion>
                )}
                {!(role === USER_ROLES.MASTER) && (
                    <>
                        {navigationConfig.map(({ to, text, icon }) => (
                            <>
                                <NavLink className="navigation__text" to={to}>
                                    <Icon name={icon} />
                                    {text}
                                </NavLink>
                                <hr className="navigation__hr" />
                            </>
                        ))}
                        {accordionConfig.map(({ text, name, config, icon }) => (
                            <>
                                <Accordion>
                                    <Accordion.Title
                                        active={activeSection === { name }}
                                        onClick={onClickAccordion}
                                        name={name}
                                    >
                                        <button className="navigation__button">
                                            <span>
                                                <Icon name={icon} />
                                                {text}
                                            </span>
                                        </button>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeSection === name}>
                                        {config.map(({ title, to }) => (
                                            <>
                                                {title === 'Рентабельность' && role === MANAGER && (
                                                    <NavLink
                                                        className="navigation__list"
                                                        to={to}
                                                        key={title}
                                                    >
                                                        <Icon name="circle outline" />
                                                        {title}
                                                    </NavLink>
                                                )}
                                                {title !== 'Рентабельность' && (
                                                    <NavLink
                                                        className="navigation__list"
                                                        to={to}
                                                        key={title}
                                                    >
                                                        <Icon name="circle outline" />
                                                        {title}
                                                    </NavLink>
                                                )}
                                            </>
                                        ))}
                                    </Accordion.Content>
                                </Accordion>
                                <hr className="navigation__hr" />
                            </>
                        ))}
                    </>
                )}
            </div>
            <div className="navigation__bottom">
                {!(role === 'Master') && (
                    <button className="navigation__button--setting" onClick={onClickWizard}>
                        Настройки
                        <Icon name="setting" className="navigation__icon" />
                    </button>
                )}
                <hr className="navigation__hr" />
                <button className="navigation__button--exit" onClick={onClickExit}>
                    Выход
                    <Icon name="sign-out" className="navigation__icon" />
                </button>
            </div>
        </div>
    );
}

export default Navigation;
