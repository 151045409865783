import { all } from 'redux-saga/effects';

import { watchUser } from '../ducks/user';
import { watchSetting } from '../ducks/setting';
import { watchOrders } from '../ducks/orders';
import { watchCreateOrders } from '../ducks/createOrders';
import { watchCustomer } from '../ducks/client';
import { watchReport } from '../ducks/report';
import { watchProductionLines } from '../ducks/productionLines';
import { watchSize } from '../ducks/size';
import { watchColor } from '../ducks/color';
import { watchOperation } from '../ducks/operation';
import { watchModel } from '../ducks/model';

export function* rootWatcher() {
    yield all([
        watchUser(),
        watchSetting(),
        watchOrders(),
        watchCreateOrders(),
        watchCustomer(),
        watchReport(),
        watchProductionLines(),
        watchSize(),
        watchColor(),
        watchOperation(),
        watchModel(),
    ]);
}
