import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Input, Icon, Modal, Button, Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import {
    operationList,
    operationSizes,
    operationColor,
    addNewModel,
} from '../../../ducks/createOrders';
import { getPositionCostOperation } from '../../../utils/functionHelper';

function ModalOrder({ setOpen, open, item, index, addItem, copyingModel, formValue }) {
    const dispatch = useDispatch();
    const optionOperation = useSelector(operationList);
    const optionSizes = useSelector(operationSizes);
    const optionColor = useSelector(operationColor);
    const [form, setForm] = useState(item[0] && JSON.parse(JSON.stringify(item[0])));

    const handleChange = (event, { name, value }) => {
        const [object, input, index] = name.split('.');
        const tempObj = form;

        if (input && index) {
            tempObj[object].operations[index][input] =
                !isNaN(value) && input === 'duration' ? +value : value;
        } else if (input) {
            tempObj[object][input] = value;
        } else {
            tempObj[name] = +value;
        }

        setForm(Object.assign({}, tempObj));
    };

    const deleteOperation = (indexItem) => {
        const tempObj = form;

        tempObj.model.operations.splice(indexItem, 1);
        setForm(Object.assign({}, tempObj));
    };

    const addOperation = (index) => {
        const tempObj = form;

        tempObj.model.operations = [...tempObj.model.operations, {}];
        setForm(Object.assign({}, tempObj));
    };

    const fetchData = () => {
        const operation = form.model.operations.map((item) => {
            return {
                name: item.name,
                duration: item.duration,
            };
        });

        return {
            model: {
                name:
                    form.model.name === item[open - 1].model.name
                        ? `${form.model.name}-Копия`
                        : form.model.name,
                operations: [...operation],
            },
            size: {
                name: form.size.name,
            },
            color: {
                name: form.color.name,
            },
            count: form.count,
        };
    };

    const onSubmit = () => {
        dispatch(addNewModel({ data: fetchData(), setOpen: setOpen, copyingModel: copyingModel }));
    };

    useEffect(() => {
        setForm(JSON.parse(JSON.stringify(item[open - 1])));
    }, [open]);

    const isNext = useMemo(() => {
        return (
            !form.model.name ||
            !form.color.name ||
            !form.size.name ||
            !form.count ||
            [...form.model.operations].some(({ name, duration }) => !name || !duration)
        );
    }, [form]);

    return (
        <Modal
            centered={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={!!open}
        >
            {item && (
                <div className="modal" key={form}>
                    <Grid className="modal__grid">
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <h1 className="modal__name">
                                    Создать дубликат модели "{item[0].model.name}"
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <p className="modal__text">Наименование модели</p>
                                <Input
                                    className="modal__input"
                                    placeholder="Введите наименование модели"
                                    value={form.model.name}
                                    name="model.name"
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <p className="modal__text">Размер</p>
                                <Dropdown
                                    value={form.size.name}
                                    name="size.name"
                                    search
                                    selection
                                    fluid
                                    allowAdditions
                                    additionLabel="Добавить: "
                                    onAddItem={addItem}
                                    options={optionSizes}
                                    onChange={handleChange}
                                    placeholder="Выбрать"
                                    className="modal__input"
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <p className="modal__text">Цвет</p>
                                <Dropdown
                                    value={form.color.name}
                                    name="color.name"
                                    search
                                    selection
                                    fluid
                                    allowAdditions
                                    additionLabel="Добавить: "
                                    onAddItem={addItem}
                                    options={optionColor}
                                    onChange={handleChange}
                                    placeholder="Выбрать цвет"
                                    className="modal__input"
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <p className="modal__text">Количество</p>
                                <Input
                                    value={form.count}
                                    type="number"
                                    name="count"
                                    onChange={handleChange}
                                    placeholder="0"
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {form.model.operations.map((item, indexItem) => (
                            <Grid.Row key={indexItem}>
                                <Grid.Column width={12} className="modal__column">
                                    <p className="modal__text">Наименование модели</p>
                                    <Dropdown
                                        id={index}
                                        name={`model.name.${indexItem}`}
                                        search
                                        selection
                                        fluid
                                        allowAdditions
                                        additionLabel="Добавить: "
                                        onAddItem={addItem}
                                        options={optionOperation}
                                        onChange={handleChange}
                                        value={item.name}
                                        placeholder="Выбрать операцию"
                                        className="modal__input"
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <p className="modal__text">Время</p>
                                    <Input
                                        type="number"
                                        name={`model.duration.${indexItem}`}
                                        placeholder="0"
                                        onChange={handleChange}
                                        value={item.duration}
                                        className="modal__input"
                                    />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    {indexItem > 0 && (
                                        <p
                                            className="modal__delete"
                                            onClick={() => deleteOperation(indexItem)}
                                        >
                                            <Icon name="times circle outline" size="large" />
                                        </p>
                                    )}
                                </Grid.Column>
                                <p className="modal__сost">
                                    Стоимость операции:
                                    {getPositionCostOperation(
                                        item.duration,
                                        formValue.costPerSecond,
                                        form.count,
                                    )
                                        ? ` ${getPositionCostOperation(
                                              item.duration,
                                              formValue.costPerSecond,
                                              form.count,
                                          )}₽`
                                        : ' 0'}
                                </p>
                            </Grid.Row>
                        ))}
                        <Grid.Row>
                            <Grid.Column>
                                <p className="modal__button" onClick={() => addOperation()}>
                                    <Icon name="plus circle" />
                                    Добавить операцию
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="modal__navigate" verticalAlign="bottom">
                            <Grid.Column width={2}>
                                <Button secondary disabled={isNext} onClick={onSubmit}>
                                    Создать
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <p className="modal__button" onClick={() => setOpen(false)}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )}
        </Modal>
    );
}

export default ModalOrder;
