import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';

//*                                                   /Setting /User /Customers                                     *//

//*  TYPES  *//

export const SET_INFORMATION_REQUEST = 'SET_INFORMATION_REQUEST';
export const GET_INFORMATION_SUCCESS = 'GET_INFORMATION_SUCCESS';

export const SET_SETTING_ROLE = 'SET_SETTING_ROLE';
export const SET_SETTING_CUSTOMERS = 'SET_SETTING_CUSTOMERS';

export const SET_SETTING_CUSTOMERS_ROLE_REQUEST = 'SET_SETTING_CUSTOMERS_ROLE_REQUEST';
export const SET_SETTING_CUSTOMERS_ERROR = 'SET_SETTING_CUSTOMERS_ERROR';
export const SET_SETTING_ROLE_ERROR = 'SET_SETTING_ROLE_ERROR';

export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST';
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';

//*  INITIAL STATE  *//

const initial = {
    errorRole: '',
    idRole: null,
    errorCustomers: '',
    idCustomers: null,
    information: {},
    settingRole: null,
    settingCustomer: null,
};

//*  REDUCER  *//

export default function setting(state = initial, action) {
    switch (action.type) {
        case SET_SETTING_CUSTOMERS_ERROR:
            return {
                ...state,
                idCustomers: action.payload,
                errorCustomers: 'Такой заказчик уже есть',
            };
        case SET_SETTING_ROLE_ERROR:
            return {
                ...state,
                idRole: action.payload,
                errorRole: 'Такой пользователь уже есть',
            };
        case GET_SETTING_SUCCESS:
        case GET_INFORMATION_SUCCESS:
            return {
                ...state,
                information: action.payload,
            };
        case SET_SETTING_ROLE:
            return {
                ...state,
                settingRole: action.payload,
            };
        case SET_SETTING_CUSTOMERS:
            return {
                ...state,
                settingCustomer: action.payload,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const setInformationRequest = (payload) => ({ type: SET_INFORMATION_REQUEST, payload });
export const setSettingRoleCustomersRequest = (payload) => ({
    type: SET_SETTING_CUSTOMERS_ROLE_REQUEST,
    payload,
});

export const setCustomers = (payload) => ({ type: SET_SETTING_CUSTOMERS, payload });
export const setRole = (payload) => ({ type: SET_SETTING_ROLE, payload });

export const getSetting = () => {
    return {
        type: GET_SETTING_REQUEST,
    };
};

//*  SELECTORS *//

export const baseSettingState = (state) => state.setting;

export const information = createSelector([baseSettingState], (state) => state.information);
export const settingRole = createSelector([baseSettingState], (state) => state.settingRole);
export const settingCustomers = createSelector(
    [baseSettingState],
    (state) => state.settingCustomer,
);

export const errorRole = createSelector([baseSettingState], (value) => ({
    errorRole: value.errorRole,
    idRole: value.idRole,
}));
export const errorCustomers = createSelector([baseSettingState], (value) => ({
    errorCustomers: value.errorCustomers,
    idCustomers: value.idCustomers,
}));

//*  SAGA  *//

function* workerSetInformation({ payload }) {
    try {
        const { nextProgress, price, name } = payload;

        yield postman.post(`/Settings/CostPerSecond`, { value: `${price}` });

        yield postman.post(`/Settings/CompanyName`, { value: name });

        yield put({ type: GET_INFORMATION_SUCCESS, payload: { price, name } });
        nextProgress && nextProgress();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerSetRoleAndCustomers({ payload }) {
    const { nextProgress, role, customers, backProgress } = payload;

    yield put(setCustomers(customers));
    try {
        yield postman.post(`/Users/batch`, Object.values(role).flat());
    } catch (err) {
        const { status, data } = err;

        if (status === 409) {
            yield put({ type: SET_SETTING_ROLE_ERROR, payload: data.message.split(';')[0] });
            backProgress();
        }
    }
    try {
        yield postman.post(`/Customers/batch`, Object.values(customers).flat());
        yield postman.post(`/Settings/SetupComplete`, { value: 'true' });
        nextProgress && nextProgress();
    } catch (err) {
        const { status, data } = err;

        if (status === 409) {
            yield put({ type: SET_SETTING_CUSTOMERS_ERROR, payload: data.message.split(';')[0] });
        }
    }
}

function* workerGetSetting() {
    try {
        const { value: name } = yield postman.get(`/Settings/CompanyName`);
        const { value: price } = yield postman.get(`/Settings/CostPerSecond`);

        yield put({
            type: GET_SETTING_SUCCESS,
            payload: { price, name },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchSetting() {
    yield takeEvery(SET_INFORMATION_REQUEST, workerSetInformation);
    yield takeEvery(SET_SETTING_CUSTOMERS_ROLE_REQUEST, workerSetRoleAndCustomers);
    yield takeEvery(GET_SETTING_REQUEST, workerGetSetting);
}
