import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
    LOGIN_LINK,
    WIZARD_LINK,
    ORDER_LINK,
    ORDER_DISTRIBUTION_LINK,
    SETTING_LINK,
} from './links';
import PrivateRoute from './privateRoute';
import {
    AuthorizationPage,
    WizardPage,
    CreateOrder,
    MainPage,
    OrderDistribution,
    Setting,
} from '../pages';

function MainRoute() {
    return (
        <Switch>
            <Route exact path={LOGIN_LINK} component={AuthorizationPage} />
            <PrivateRoute exact path={WIZARD_LINK} component={WizardPage} />
            <PrivateRoute exact path={`${ORDER_LINK}/create`} component={CreateOrder} />
            <PrivateRoute exact path={`${ORDER_LINK}/:id`} component={CreateOrder} />
            <PrivateRoute
                exact
                path={`${ORDER_DISTRIBUTION_LINK}/:id`}
                component={OrderDistribution}
            />
            <PrivateRoute exact path={SETTING_LINK} component={Setting} />
            <PrivateRoute path="/" component={MainPage} />
        </Switch>
    );
}

export default MainRoute;
