import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Input, Dropdown, Grid } from 'semantic-ui-react';

import {
    operationList,
    deleteOperation,
    getOperationGroup,
    deleteOperationGroup,
} from '../../ducks/operation';
import {
    countryStatusIsActive,
    configTableOperation,
    configTableOperationGroup,
} from '../../utils/const';
import ModalOperationGroup from './components/modal';
import useDebounce from '../../hooks/useDebounce';
import DefaultTable from '../../components/DefaultTable';
import { getOptionListAlt, modelsList } from '../../ducks/createOrders';

import './style.scss';

function GroupOperations() {
    const dispatch = useDispatch();
    const operation = useSelector(operationList);
    const [modalData, setModalData] = useState({ open: false, data: {} });
    const [filter, setFilter] = useState({
        nameSubstring: '',
        pageSize: 20,
        name: 'operation',
    });
    const optionModels = useSelector(modelsList);
    const debouncedFilter = useDebounce(filter, 200);
    const onClickCreate = useCallback(() => {
        setModalData(Object.assign({}, modalData, { open: true }));
    }, []);
    const onClickEdit = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };
    function fetchData() {
        dispatch(getOperationGroup(debouncedFilter));
    }
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const onClickDelete = (item) => {
        dispatch(deleteOperationGroup({ item, callbackSuccess: fetchData }));
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && operation.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };

    useEffect(() => {
        fetchData();
        dispatch(getOptionListAlt());
    }, [debouncedFilter]);

    return (
        <div className="operation-table">
            <div className="filter">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <h1 className="filter__title">Группы операций</h1>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <button className="filter__button" onClick={onClickCreate}>
                                <p>
                                    <Icon name="plus" size="small" />
                                    Добавить группу операций
                                </p>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3} className="filter__text">
                            Название
                            <Input
                                placeholder="Наименование группы операции"
                                className="filter__input"
                                onChange={handleChange}
                                name="nameSubstring"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Статус
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Выберите статус"
                                options={countryStatusIsActive}
                                name="isActive"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Модель
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                search
                                clearable
                                placeholder="Выберите модель"
                                options={optionModels}
                                name="modelId"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <DefaultTable
                essence={operation}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                config={configTableOperationGroup}
                secondaryIcon={'archive'}
                handleUpdate={handleUpdate}
            />
            {modalData.open && (
                <ModalOperationGroup
                    fetchData={fetchData}
                    modalData={modalData}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
}

export default GroupOperations;
