import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import className from 'classnames';

import { authorization } from '../../../ducks/user';
import { errorListUser } from '../../../utils/const';
import { userAll, userError } from '../../../ducks/user';
import { getName } from '../../../utils/functionHelper';

function FormAuth() {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({
        loginId: null,
        password: null,
    });
    const history = useHistory();
    const user = useSelector(userAll);
    const error = useSelector(userError);
    const name = getName();
    const toTerminal = () => {
        history.push('/');
    };
    const handleSubmit = () => {
        dispatch(
            authorization({
                data: userData,
                callbackSuccess: (path) => history.push({ pathname: path }),
            }),
        );
    };
    const nameUser = useMemo(() => user.find((i) => i.id === userData.loginId), [userData.loginId]);
    const onChange = (ev, { name, value }) => {
        setUserData(Object.assign({}, userData, { [name]: value }));
    };

    return (
        <div className="form_auth">
            <div className="form_auth__body">
                <Form onSubmit={handleSubmit}>
                    <div
                        className={className({
                            form_auth__error: error,
                        })}
                    >
                        <p>{error && errorListUser[error]}</p>
                    </div>
                    <h1 className="form_auth__title">Производство</h1>
                    <Form.Field>
                        <Form.Select
                            trigger={
                                <div className="form_auth__dropdown">
                                    <Icon name="user" />
                                    <span>
                                        {nameUser ? nameUser.text : 'Выберите пользователя'}
                                    </span>
                                </div>
                            }
                            placeholder="Выберите пользователя"
                            value={userData.loginId}
                            className="form_auth__input"
                            options={user}
                            onChange={onChange}
                            name="loginId"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            className="form_auth__input"
                            placeholder="Введите пароль"
                            type="password"
                            icon="lock"
                            value={userData.password}
                            iconPosition="left"
                            onChange={onChange}
                            name="password"
                        />
                    </Form.Field>
                    <Button
                        type="submit"
                        disabled={!userData.loginId || !userData.password}
                        className="form_auth__button"
                        content="Вход"
                        basic
                        size="massive"
                    />
                </Form>
                <hr className="form_auth__hr" />
                <div className="form_auth__nav" onClick={toTerminal}>
                    <a href={`https://${name}.terminal.trinitycrm.ru`} className="form_auth__text">
                        Режим терминала
                        <Icon disabled className="form_auth__icon" name="angle right" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default FormAuth;
