import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Table, Button, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import className from 'classname';
import { NavLink } from 'react-router-dom';

import { getProfitability, profitabilityData } from '../../ducks/report';
import {
    listMonth,
    countryFactValues,
    configTableReport,
    headerTableReport,
} from '../../utils/const';
import { calculationDays, calculationYears } from '../../utils/functionHelper';
import { COSTS_LINK, PROFITABILITY_LINK } from '../../router/links';

import './style.scss';

function Profitability() {
    const dispatch = useDispatch();
    const date = new Date();
    const [filter, setFilter] = useState({
        year: date.getFullYear(),
        month: listMonth[date.getMonth()].value,
        day: date.getDate(),
        factValues: true,
    });
    const [button, setButton] = useState({
        start: 1,
        end: 15,
    });
    const dataTable = useSelector(profitabilityData);
    const arrYear = calculationYears(date.getFullYear());
    const listDay = calculationDays(button);
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const setPage = (page) => {
        setFilter(
            Object.assign({}, filter, {
                day: page,
            }),
        );
    };
    const buttonOutput = (ev, { name }) => {
        const maxDay = new Date(filter.year, filter.month, 0).getDate();
        const minDay = 1;
        let newButton = {};

        if (name === 'next' && maxDay >= button.end) {
            newButton = {
                start: button.start + 1,
                end: button.end + 1,
            };
        }

        if (name === 'back' && minDay !== button.start) {
            newButton = {
                start: button.start - 1,
                end: button.end - 1,
            };
        }

        setButton(
            Object.assign({}, button, {
                ...newButton,
            }),
        );
    };

    useEffect(() => {
        dispatch(getProfitability(filter));
    }, [filter]);

    return (
        <div className="profitability">
            <div className="profitability__body">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <h1 className="profitability__title-page">Рентабельность</h1>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="profitability__pagination">
                    <div className="profitability__nav">
                        <NavLink to={`${PROFITABILITY_LINK}/month`}>
                            <Button basic inverted>
                                Месяц
                            </Button>
                        </NavLink>
                        <NavLink to={COSTS_LINK}>
                            <Button basic inverted>
                                Расходы
                            </Button>
                        </NavLink>
                    </div>
                    <Button
                        basic
                        inverted
                        className="profitability__set-button"
                        name="back"
                        onClick={buttonOutput}
                    >
                        <Icon name="angle left" />
                    </Button>
                    {listDay &&
                        listDay.map(({ value }) => (
                            <Button
                                key={value}
                                basic
                                inverted
                                onClick={() => setPage(value)}
                                className={className({
                                    profitability__button: filter.day !== value,
                                    'profitability__button--active': filter.day === value,
                                })}
                            >
                                {value}
                            </Button>
                        ))}
                    <Button
                        basic
                        inverted
                        className="profitability__set-button"
                        name="next"
                        onClick={buttonOutput}
                    >
                        <Icon name="angle right" />
                    </Button>
                </div>
                <div className="filter">
                    <Grid>
                        <Grid.Row className="filter__row">
                            <Grid.Column width={4} className="filter__text">
                                Год
                                <Dropdown
                                    onChange={handleChange}
                                    className="filter__input"
                                    fluid
                                    selection
                                    placeholder="2021"
                                    options={arrYear}
                                    name="year"
                                    value={filter.year}
                                />
                            </Grid.Column>
                            <Grid.Column width={4} className="filter__text">
                                Месяц
                                <Dropdown
                                    onChange={handleChange}
                                    className="filter__input"
                                    fluid
                                    selection
                                    placeholder="Май"
                                    options={listMonth}
                                    name="month"
                                    value={filter.month}
                                />
                            </Grid.Column>
                            <Grid.Column width={4} className="filter__text">
                                Значения
                                <Dropdown
                                    onChange={handleChange}
                                    className="filter__input"
                                    selection
                                    options={countryFactValues}
                                    name="factValues"
                                    value={filter.factValues}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                {dataTable && (
                    <>
                        <h3 className="profitability__title">Общие данные</h3>
                        <div className="profitability__table-secondary">
                            <Table definition celled className="profitability__table-secondary">
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Кол-во швей</Table.Cell>
                                        <Table.Cell>{dataTable.producersCount}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Расход на день</Table.Cell>
                                        <Table.Cell>{dataTable.dayExpenses}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Грязная прибыль за день</Table.Cell>
                                        <Table.Cell>{dataTable.dayGrossProfit}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Чистая прибыль за день</Table.Cell>
                                        <Table.Cell>{dataTable.dayNetProfit}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        {dataTable.reportForProductLines.map((items) => (
                            <div key={items.productLineName}>
                                <h3 className="profitability__title">{items.productLineName}</h3>
                                <Table celled structured className="profitability__table-main">
                                    <Table.Header>
                                        <Table.Row>
                                            {headerTableReport.map((title) => (
                                                <Table.HeaderCell>{title}</Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {items.records.map(
                                            ({ name, forOrders, netProfit }, index) => (
                                                <>
                                                    <Table.Row key={forOrders.length + index}>
                                                        <Table.Cell rowSpan={forOrders.length}>
                                                            {name}
                                                        </Table.Cell>
                                                        {configTableReport.map(({ key }, index) => (
                                                            <Table.Cell key={key + index}>
                                                                {forOrders[0][key]}
                                                            </Table.Cell>
                                                        ))}
                                                        <Table.Cell rowSpan={forOrders.length}>
                                                            {dataTable.expensesForOneProducer}
                                                        </Table.Cell>
                                                        <Table.Cell rowSpan={forOrders.length}>
                                                            {netProfit}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {forOrders.map(
                                                        (data, index) =>
                                                            index !== 0 && (
                                                                <Table.Row
                                                                    key={data.grossProfit + index}
                                                                >
                                                                    {configTableReport.map(
                                                                        ({ key }) => (
                                                                            <Table.Cell key={key}>
                                                                                {data[key]}
                                                                            </Table.Cell>
                                                                        ),
                                                                    )}
                                                                </Table.Row>
                                                            ),
                                                    )}
                                                </>
                                            ),
                                        )}
                                        <Table.Row>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell>
                                                <p>{`Чистая прибыль ${items.productLineName}`}</p>
                                            </Table.Cell>
                                            <Table.Cell>{items.netProfit}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell>
                                                <p>{`Грязная прибыль ${items.productLineName}`}</p>
                                            </Table.Cell>
                                            <Table.Cell>{items.grossProfit}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}

export default Profitability;
