import React from 'react';
import Steps, { Step } from 'rc-steps';
import className from 'classnames';

import './style.scss';

function Stepper({ progress, arrName, setProgress, form }) {
    return (
        <Steps current={progress}>
            {arrName &&
                arrName.map((item, index) => (
                    <Step
                        key={item}
                        title={item}
                        className={
                            form &&
                            className({
                                stepper__text: true,
                                'stepper__text rc-steps-item-process rc-steps-item-active':
                                    index === form.tab,
                            })
                        }
                        onClick={
                            arrName.length === 2
                                ? () => setProgress(Object.assign({}, form, { tab: index }))
                                : ''
                        }
                    />
                ))}
        </Steps>
    );
}

export default Stepper;
