import React, { useEffect } from 'react';
import { Grid, Modal, Button, Placeholder } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';

import { addStatus, dateFormatDD, getPositionCostOperation } from '../../../utils/functionHelper';
import { getAllOrder, clearOrder, getCurrentOrder } from '../../../ducks/orders';

import { hintPosition } from '../../../utils/const';

function Popup({ order, closePopup, popup }) {
    const dispatch = useDispatch();
    const currentOrder = useSelector(getCurrentOrder);

    useEffect(() => {
        dispatch(clearOrder());
    }, []);

    useEffect(() => {
        if (order) {
            dispatch(getAllOrder(order.key));
        }
    }, [order]);

    return (
        <Modal size="massive" centered={false} onClose={closePopup} open={popup}>
            <div className="modal-popup">
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1 className="modal-popup__title">{`Заказ №${order.number} от ${order.createDate}`}</h1>
                            {currentOrder &&
                                addStatus(currentOrder.status, currentOrder.customer.name)}
                        </Grid.Column>
                    </Grid.Row>
                    {currentOrder ? (
                        <>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <span className="modal-popup__subtitle">Поток:</span>
                                    <span className="modal-popup__text">{` ${currentOrder.line.name}`}</span>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <span className="modal-popup__subtitle">
                                        Начало выполнения:{' '}
                                    </span>
                                    <span className="modal-popup__text">{` ${dateFormatDD(
                                        parseISO(currentOrder.startDate),
                                    )}`}</span>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <span className="modal-popup__subtitle">Стоимость заказа:</span>
                                    <span className="modal-popup__text">{` ${currentOrder.cost} ₽`}</span>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <span className="modal-popup__subtitle">
                                        Стоимость секунды производства, коп:
                                    </span>
                                    <span className="modal-popup__text">{` ${currentOrder.costPerSecond}`}</span>
                                </Grid.Column>
                            </Grid.Row>
                            {currentOrder.positions.map((item) => (
                                <>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <span className="modal-popup__subtitle">
                                                Название модели:
                                            </span>
                                            <span className="modal-popup__text">{` ${item.model.name}`}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <span className="modal-popup__subtitle">Цвет:</span>
                                            <span className="modal-popup__text">{` ${item.color.name}`}</span>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <span className="modal-popup__subtitle">Размер:</span>
                                            <span className="modal-popup__text">{` ${item.size.name}`}</span>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <span className="modal-popup__subtitle">
                                                Количество:
                                            </span>
                                            <span className="modal-popup__text">{` ${item.count}`}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <span className="modal-popup__subtitle">Операции:</span>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <span className="modal-popup__subtitle">Время:</span>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <span className="modal-popup__subtitle">
                                                Стоимость операции:
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {item.model.operations.map((el) => (
                                        <Grid.Row className="modal-popup__row">
                                            <Grid.Column width={5}>
                                                <span className="modal-popup__text">{` ${el.name}`}</span>
                                            </Grid.Column>
                                            <Grid.Column width={5}>
                                                <span className="modal-popup__text">{` ${el.duration}`}</span>
                                            </Grid.Column>
                                            <Grid.Column width={5}>
                                                <span className="modal-popup__text">
                                                    {getPositionCostOperation(
                                                        el.duration,
                                                        currentOrder.costPerSecond,
                                                        item.count,
                                                    )
                                                        ? ` ${getPositionCostOperation(
                                                              el.duration,
                                                              currentOrder.costPerSecond,
                                                              item.count,
                                                          )}₽`
                                                        : ' 0'}
                                                </span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    ))}
                                </>
                            ))}
                        </>
                    ) : (
                        <Grid.Row>
                            {hintPosition &&
                                hintPosition.map((item, index) => (
                                    <Grid.Column width={item.width} key={item.value}>
                                        <p className="hint-input__subtitle">{item.value}</p>
                                        <Placeholder>
                                            <Placeholder.Line length="full" />
                                        </Placeholder>
                                    </Grid.Column>
                                ))}
                        </Grid.Row>
                    )}
                    <Grid.Row />
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Button className="modal-delete__button" onClick={closePopup}>
                                Назад
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Modal>
    );
}

export default Popup;
