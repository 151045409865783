import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderAuth from './components/headerAuth';
import FormAuth from './components/formAuth';
import { getUserList } from '../../ducks/user';

import './style.scss';

function AuthorizationPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserList());
    }, []);

    return (
        <div className="authorization">
            <div className="authorization__body">
                <HeaderAuth />
                <FormAuth />
            </div>
        </div>
    );
}

export default AuthorizationPage;
