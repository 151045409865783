import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { dateFormatDD } from '../utils/functionHelper';
import { parseISO } from 'date-fns';

//*                                                       /MODEL                                                *//

//*  TYPES  *//

export const GET_MODEL_REQUEST = 'GET_MODEL_REQUEST';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';

export const ADD_MODEL_REQUEST = 'ADD_MODEL_REQUEST';
export const ADD_MODEL_SUCCESS = 'ADD_MODEL_SUCCESS';

export const EDIT_MODEL_REQUEST = 'EDIT_MODEL_REQUEST';
export const EDIT_MODEL_SUCCESS = 'EDIT_MODEL_SUCCESS';

export const DELETE_MODEL_REQUEST = 'DELETE_MODEL_REQUEST';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';

export const GET_OPERATION_MODEL_REQUEST = 'GET_OPERATION_MODEL_REQUEST';
export const GET_OPERATION_MODEL_SUCCESS = 'GET_OPERATION_MODEL_SUCCESS';

export const ERROR_HANDLER_MODEL = 'ERROR_HANDLER_MODEL';

export const CLEAR_OPERATION = 'CLEAR_OPERATION';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: false,
    model: [],
    itemsCount: 0,
    pagesCount: 0,
    operation: [{}],
};

//*  REDUCER  *//

export default function model(state = initial, action) {
    switch (action.type) {
        case CLEAR_OPERATION:
            return {
                ...state,
                operation: [{}],
            };
        case GET_OPERATION_MODEL_SUCCESS:
            return {
                ...state,
                operation: action.payload.responseOperation,
            };
        case ERROR_HANDLER_MODEL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_MODEL_SUCCESS:
        case DELETE_MODEL_SUCCESS:
        case ADD_MODEL_SUCCESS:
        case EDIT_MODEL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case DELETE_MODEL_REQUEST:
        case ADD_MODEL_REQUEST:
        case GET_MODEL_REQUEST:
        case EDIT_MODEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getOperationList = (payload) => {
    return {
        type: GET_OPERATION_MODEL_REQUEST,
        payload,
    };
};

export const clearOperation = () => {
    return {
        type: CLEAR_OPERATION,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_MODEL,
    };
};

export const getModel = (payload) => {
    return {
        type: GET_MODEL_REQUEST,
        payload,
    };
};

export const deleteModel = (payload) => {
    return {
        type: DELETE_MODEL_REQUEST,
        payload,
    };
};

export const addModel = (payload) => {
    return {
        type: ADD_MODEL_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseModelState = (state) => state.model;

export const getLoading = createSelector([baseModelState], (state) => state.loading);

export const getOperationModel = createSelector([baseModelState], (state) => state.operation);

export const modelList = createSelector(
    [baseModelState],
    (data) =>
        data.model &&
        data.model.map((value) => {
            return {
                ...value,
                createDate: dateFormatDD(parseISO(value.createDate)),
            };
        }),
);

//*  SAGA  *//

function* workerGetOperationList({ payload }) {
    try {
        const responseOperation = yield postman.get(`/Models/${payload}/Operations`);

        yield put({
            type: GET_OPERATION_MODEL_SUCCESS,
            payload: {
                responseOperation: responseOperation.map((item) => {
                    return {
                        name: item.name,
                        duration: item.duration,
                    };
                }),
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerGetModel({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Models/GetExtended`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
            },
        });

        yield put({
            type: GET_MODEL_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                model: items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerAddModel({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.post(`/Models`, { ...data });

        yield put({
            type: GET_MODEL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteModel({ payload }) {
    try {
        const { item, callbackSuccess } = payload;

        yield postman.put(`/Models/${item.id}`, { ...item, isActive: !item.isActive });
        yield put({
            type: DELETE_MODEL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchModel() {
    yield takeEvery(ADD_MODEL_REQUEST, workerAddModel);
    yield takeEvery(GET_MODEL_REQUEST, workerGetModel);
    yield takeEvery(DELETE_MODEL_REQUEST, workerDeleteModel);
    yield takeEvery(GET_OPERATION_MODEL_REQUEST, workerGetOperationList);
}
