import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';

//*                                                       /Customers                                                *//

//*  TYPES  *//

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';

export const ADD_NEW_CUSTOMERS_REQUEST = 'ADD_NEW_CUSTOMERS_REQUEST';
export const ADD_NEW_CUSTOMERS_SUCCESS = 'ADD_NEW_CUSTOMERS_SUCCESS';

export const EDIT_CUSTOMERS_REQUEST = 'EDIT_CUSTOMERS_REQUEST';
export const EDIT_CUSTOMERS_SUCCESS = 'EDIT_CUSTOMERS_SUCCESS';

export const DELETE_CUSTOMERS_REQUEST = 'DELETE_CUSTOMERS_REQUEST';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';

export const ERROR_HANDLER_CUSTOMERS = 'ERROR_HANDLER_CUSTOMERS';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: null,
    items: [],
    itemsCount: 0,
    pagesCount: 0,
};

//*  REDUCER  *//

export default function customer(state = initial, action) {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case ERROR_HANDLER_CUSTOMERS:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ADD_NEW_CUSTOMERS_REQUEST:
        case EDIT_CUSTOMERS_REQUEST:
        case DELETE_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_CUSTOMERS_SUCCESS:
        case DELETE_CUSTOMERS_SUCCESS:
        case EDIT_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const deleteCustomersRequest = (payload) => {
    return {
        type: DELETE_CUSTOMERS_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_CUSTOMERS,
    };
};

export const addCustomers = (payload) => {
    return {
        type: ADD_NEW_CUSTOMERS_REQUEST,
        payload,
    };
};

export const editCustomers = (payload) => {
    return {
        type: EDIT_CUSTOMERS_REQUEST,
        payload,
    };
};

export const getCustomer = (payload) => {
    return {
        type: GET_CUSTOMERS_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseCustomerState = (state) => state.customer;

export const getLoading = createSelector([baseCustomerState], (state) => state.loading);

export const customerList = createSelector([baseCustomerState], (state) => state.items);

export const customerError = createSelector([baseCustomerState], (state) => state.error);

//*  SAGA  *//

function* workerGetCustomer({ payload }) {
    try {
        const { isActive, nameSubstring, createdFrom, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Customers`, {
            params: {
                isActive,
                nameSubstring,
                createdFrom,
                pageSize,
            },
        });

        yield put({
            type: GET_CUSTOMERS_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteCustomer({ payload }) {
    try {
        const { item, callbackSuccess } = payload;

        yield postman.put(`/Customers/${item.id}`, { ...item, isActive: !item.isActive });
        yield put({
            type: EDIT_CUSTOMERS_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditCustomer({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.put(`/Customers/${data.id}`, data);

        yield put({
            type: EDIT_CUSTOMERS_SUCCESS,
        });
        callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_CUSTOMERS,
            payload: data.errorCode,
        });
    }
}

function* workerAddCustomer({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.post(`/Customers`, data);

        yield put({
            type: ADD_NEW_CUSTOMERS_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_CUSTOMERS,
            payload: data.errorCode,
        });
    }
}

export function* watchCustomer() {
    yield takeEvery(GET_CUSTOMERS_REQUEST, workerGetCustomer);
    yield takeEvery(DELETE_CUSTOMERS_REQUEST, workerDeleteCustomer);
    yield takeEvery(ADD_NEW_CUSTOMERS_REQUEST, workerAddCustomer);
    yield takeEvery(EDIT_CUSTOMERS_REQUEST, workerEditCustomer);
}
