import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import className from 'classname';
import { Icon } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';

import {
    searchOperation,
    getSearchList,
    clearOperation,
    getOperationListWeb,
    addOperationWeb,
} from '../../../ducks/operation';
import { getListOperation } from '../../../ducks/createOrders';

function MyDropdown({ name, id, disabled, value, placeholder, onChangeModel }) {
    const dispatch = useDispatch();
    const [valueDefault, setDefaultValue] = useState({ value: '', id: uuidv4() });
    const searchList = useSelector(getSearchList);
    const operationList = useSelector(getListOperation);
    const operationWeb = useSelector(getOperationListWeb);
    const [text] = useDebounce(valueDefault.value, 100);
    const [focus, setFocus] = useState(false);

    const arrayOption = useMemo(() => {
        const operationWebFormat = operationWeb.map((item) => item.value);
        const operation = _.uniqBy(operationWebFormat, function (o) {
            return o;
        });

        return [...operationList, ...operation];
    }, [operationWeb, operationList]);

    const arraySearch = useMemo(() => {
        let operationWebFormat = operationWeb.map((item) => item.value);
        operationWebFormat = _.uniqBy(operationWebFormat, function (o) {
            return o;
        });
        operationWebFormat = operationWebFormat.filter((item) => {
            if (item && item?.toLowerCase().includes(valueDefault.value?.toLowerCase())) {
                return true;
            }
        });

        return [...searchList, ...operationWebFormat];
    }, [operationWeb, searchList, valueDefault]);

    const onChange = (ev) => {
        setDefaultValue(Object.assign({}, valueDefault, { value: ev.target.value }));
        onChangeModel(ev, { name, id, value: ev.target.value });
        if (!operationList.find((item) => item === ev.target.value)) {
            let newArrayWeb = [...operationWeb];
            if (newArrayWeb.find((item) => item.id === valueDefault.id) && !ev.target.value) {
                newArrayWeb = newArrayWeb.filter((item) => item.id !== valueDefault.id);
            } else if (newArrayWeb.find((item) => item.id === valueDefault.id)) {
                const index = newArrayWeb.findIndex((item) => item.id === valueDefault.id);

                newArrayWeb[index].value = ev.target.value;
            } else {
                newArrayWeb.push({ value: ev.target.value, id: valueDefault.id });
            }

            dispatch(addOperationWeb([...newArrayWeb]));
        }
    };

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        setTimeout(() => {
            setFocus(false);
        }, 150);
    };

    const onClickItem = (ev, item) => {
        setDefaultValue(Object.assign({}, valueDefault, { value: item }));
        onChangeModel(ev, { name, id, value: item });
    };

    useEffect(() => {
        if (text && !disabled) {
            dispatch(searchOperation(text));
        } else {
            dispatch(clearOperation());
        }
    }, [text]);

    useEffect(() => {
        setDefaultValue(Object.assign({}, valueDefault, { value: value ? value : '' }));
    }, [value]);

    return (
        <div
            onFocus={onFocus}
            onBlur={onBlur}
            className={className(
                'my-dropdown',
                focus && 'my-dropdown--active',
                disabled && 'my-dropdown--disable',
            )}
        >
            <input
                name={name}
                id={id}
                disabled={disabled}
                value={valueDefault.value}
                onChange={onChange}
                placeholder={placeholder}
                className="input"
            />
            <div className="icon">
                <Icon name="caret down" />
            </div>
            {focus && (
                <div className="option">
                    {searchList.length > 0 || text
                        ? arraySearch.map((item) => (
                              <div
                                  className="option__item"
                                  key={item}
                                  onClick={(ev) => onClickItem(ev, item)}
                              >
                                  {item}
                              </div>
                          ))
                        : arrayOption.map((item) => (
                              <div
                                  className="option__item"
                                  key={item}
                                  onClick={(ev) => onClickItem(ev, item)}
                              >
                                  {item}
                              </div>
                          ))}
                </div>
            )}
        </div>
    );
}

export default MyDropdown;
