import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { ORDER_LINK } from '../../../router/links';

function EndSetting() {
    const history = useHistory();
    const createOrder = () => {
        history.push(`${ORDER_LINK}/create`);
    };
    const main = () => {
        history.push(`/`);
    };

    return (
        <div className="end-setting">
            <div className="end-setting__body">
                <Icon name="check circle" color="green" className="end-setting__img" size="huge" />
                <p className="end-setting__title">
                    Поздравляем!
                    <br />
                    Настройка вашего предприятия завершена.
                    <br />
                    Вы можете создать свой первый заказ.
                    <br />
                </p>
                <Button className="end-setting__button" onClick={createOrder}>
                    Создать заказ
                </Button>
                <br />
                <p className="end-setting__text" onClick={main}>
                    Позже
                </p>
            </div>
        </div>
    );
}

export default EndSetting;
