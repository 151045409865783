import React, { useMemo } from 'react';
import { Grid, Input, Modal, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { editExtension, addExtension, clearError, getLoading } from '../../../ducks/report';

function CostsModal({ fetchData, modalData, closeModal, filter }) {
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: data ? data.key : null,
            name: data ? data.name : null,
            amount: data ? data.amount : null,
        },
        onSubmit: (values) => {
            dispatch(
                !values.id
                    ? addExtension({
                          data: values,
                          ...filter,
                          callbackSuccess,
                      })
                    : editExtension({
                          data: values,
                          ...filter,
                          callbackSuccess,
                      }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const { values, handleSubmit, setFieldValue } = formik;
    const onChange = (ev, { name, value }) => {
        let newValue;
        if (name === 'amount') {
            newValue = +value > 0 ? +value : 0;
        } else {
            newValue = value;
        }
        setFieldValue(name, newValue);
    };
    const isNext = useMemo(() => {
        return !values.name || !values.amount;
    }, [values]);

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {values.id ? 'Изменение расхода' : 'Новый расход'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    disabled={values.id}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    placeholder="Название"
                                    onChange={onChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4} className="modal__column">
                                <p className="modal__subtitle">Стоимость</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input
                                    name="amount"
                                    type="number"
                                    min="0"
                                    value={values.amount}
                                    placeholder="1000"
                                    onChange={onChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default CostsModal;
