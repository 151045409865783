import React, { useMemo } from 'react';
import { Grid, Input, Modal, Button, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { addSize, editSize, clearError, getLoading } from '../../../ducks/size';

function ModalSize({ fetchData, modalData, closeModal }) {
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: data ? data.id : null,
            name: data ? data.name : null,
            isActive: data.id ? data.isActive : true,
        },
        onSubmit: (values) => {
            dispatch(
                !values.id
                    ? addSize({
                          data: values,
                          callbackSuccess,
                      })
                    : editSize({
                          data: values,
                          callbackSuccess,
                      }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const onChange = (event, { value, name }) => {
        let newValue;

        if (name === 'isActive') {
            newValue = !values.isActive;
        } else {
            newValue = value;
        }
        setFieldValue(name, newValue);
    };
    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const isNext = useMemo(() => {
        return !values.name;
    }, [values]);

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {values.id ? 'Изменение размера' : 'Новый размер'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    placeholder="Название"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    toggle
                                    onChange={onChange}
                                    className="modal__check"
                                    label={values.isActive ? 'Активен' : 'Неактивен'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalSize;
