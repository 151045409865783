import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Input, Modal, Button, Checkbox, Dropdown, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
    getOperation,
    getOptionList,
    getOptionListAlt,
    modelsList,
    operation,
    postOperationGroup,
    putOperationGroup,
} from '../../../ducks/createOrders';
import { getLoading } from '../../../ducks/operation';

function ModalOperationGroup({ fetchData, modalData, closeModal }) {
    const [form, setForm] = useState({
        id: uuidv4(),
        isActive: true,
        name: '',
        modelId: '',
        modelOperationIds: [],
    });
    const optionModels = useSelector(modelsList);
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const operationList = useSelector(operation);
    const { open, data } = modalData;

    const onChangeSelect = (ev, data) => {
        if (data.name === 'operation') {
            setForm(
                Object.assign({}, form, {
                    modelOperationIds: [
                        ...form.modelOperationIds,
                        {
                            id: data.value,
                            value: operationList.find((item) => item.id === data.value)?.name,
                        },
                    ],
                }),
            );
            return;
        }

        setForm(Object.assign({}, form, { modelId: data.value }));
    };

    const onChangeInput = (ev) => {
        setForm(Object.assign({}, form, { name: ev.target.value }));
    };

    const onClose = () => {
        closeModal();
        fetchData();
    };

    const dataOperationList = useMemo(() => {
        return operationList
            ?.map((item) => {
                return {
                    ...item,
                    value: item.id,
                    text: item.name,
                };
            })
            ?.filter((item) => {
                if (!form?.modelOperationIds?.length) {
                    return true;
                }
                return !form.modelOperationIds?.filter((el) => el.id === item.id).length;
            });
    }, [operationList, form.modelOperationIds]);

    const isNext = useMemo(() => {
        return !(
            form?.name &&
            form?.modelId &&
            form?.modelOperationIds &&
            form?.modelOperationIds?.length
        );
    }, [form]);

    const onChangeCheckbox = (ev, data) => {
        setForm(Object.assign({}, form, { isActive: data.checked }));
    };

    const onClickRemove = (id) => {
        setForm(
            Object.assign({}, form, {
                modelOperationIds: form.modelOperationIds?.filter((item) => item.id !== id),
            }),
        );
    };

    const onClickSave = () => {
        if (data?.id) {
            dispatch(
                putOperationGroup({
                    data: {
                        ...form,
                        modelOperationIds: form.modelOperationIds.map((item) => item.id),
                    },
                    callbackSuccess: onClose,
                }),
            );
        } else {
            dispatch(
                postOperationGroup({
                    data: {
                        ...form,
                        modelOperationIds: form.modelOperationIds.map((item) => item.id),
                    },
                    callbackSuccess: onClose,
                }),
            );
        }
    };

    useEffect(() => {
        if (form.modelId) {
            dispatch(getOperation({ id: form.modelId }));
        }
    }, [form.modelId]);

    useEffect(() => {
        if (data.id) {
            setForm(
                Object.assign({}, form, {
                    ...data,
                    modelOperationIds: operationList
                        ?.map((item) => {
                            return {
                                ...item,
                                value: item.name,
                            };
                        })
                        ?.filter((item) => data.modelOperationIds?.includes(item.id)),
                }),
            );
        }
    }, [data.id, operationList]);

    useEffect(() => {
        dispatch(getOptionListAlt());
    }, []);

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">
                                    {data?.id
                                        ? 'Изменение группы операций'
                                        : 'Новая группа операций'}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    value={form.name}
                                    name="name"
                                    type="text"
                                    onChange={onChangeInput}
                                    placeholder="Название группы операций"
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Dropdown
                                    placeholder="Выберите модель"
                                    className="form-create__input-name"
                                    search
                                    selection
                                    value={form.modelId}
                                    fluid
                                    disabled={data?.id}
                                    allowAdditions
                                    closeOnBlur
                                    closeOnEscape
                                    name="model"
                                    onChange={onChangeSelect}
                                    options={optionModels}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {form.modelOperationIds?.map((item) => (
                            <Grid.Row key={item.id}>
                                <Grid.Column width={13}>
                                    <Input
                                        value={item.value}
                                        type="text"
                                        disabled
                                        className="modal__input"
                                    />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    <div
                                        className={'modal__icon'}
                                        onClick={() => onClickRemove(item.id)}
                                    >
                                        <Icon name="times circle outline" />
                                        Удалить
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                        {form.modelId && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Dropdown
                                        placeholder="Выберите операцию"
                                        className="form-create__input-name"
                                        search
                                        selection
                                        value={null}
                                        fluid
                                        closeOnBlur
                                        closeOnEscape
                                        name="operation"
                                        onChange={onChangeSelect}
                                        options={dataOperationList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={form.isActive}
                                    toggle
                                    className="modal__check"
                                    onChange={onChangeCheckbox}
                                    label={form.isActive ? 'Активна' : 'Неактивна'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    onClick={onClickSave}
                                    type="button"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalOperationGroup;
