import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';
import { setOptionsId } from '../utils/functionHelper';

//*                                                       /Customers                                                *//

//*  TYPES  *//

export const GET_PRODUCTION_LINES_REQUEST = 'GET_PRODUCTION_LINES_REQUEST';
export const GET_PRODUCTION_LINES_SUCCESS = 'GET_PRODUCTION_LINES_SUCCESS';

export const ADD_PRODUCTION_LINES_REQUEST = 'ADD_PRODUCTION_LINES_REQUEST';
export const ADD_PRODUCTION_LINES_SUCCESS = 'ADD_PRODUCTION_LINES_SUCCESS';

export const DELETE_PRODUCTION_LINES_REQUEST = 'DELETE_PRODUCTION_LINES_REQUEST';
export const DELETE_PRODUCTION_LINES_SUCCESS = 'DELETE_PRODUCTION_LINES_SUCCESS';

export const ERROR_HANDLER_PRODUCTION_LINES = 'ERROR_HANDLER_PRODUCTION_LINES';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: false,
    streams: [],
    itemsCount: 0,
    pagesCount: 0,
};

//*  REDUCER  *//

export default function productionLines(state = initial, action) {
    switch (action.type) {
        case ERROR_HANDLER_PRODUCTION_LINES:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_PRODUCTION_LINES_SUCCESS:
        case DELETE_PRODUCTION_LINES_SUCCESS:
        case ADD_PRODUCTION_LINES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case DELETE_PRODUCTION_LINES_REQUEST:
        case ADD_PRODUCTION_LINES_REQUEST:
        case GET_PRODUCTION_LINES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const clearError = () => {
    return {
        type: ERROR_HANDLER_PRODUCTION_LINES,
    };
};

export const getProductionLines = (payload) => {
    return {
        type: GET_PRODUCTION_LINES_REQUEST,
        payload,
    };
};

export const deleteProductionLines = (payload) => {
    return {
        type: DELETE_PRODUCTION_LINES_REQUEST,
        payload,
    };
};

export const addProductionLines = (payload) => {
    return {
        type: ADD_PRODUCTION_LINES_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseProductionLinesState = (state) => state.productionLines;

export const getLoading = createSelector([baseProductionLinesState], (state) => state.loading);

export const productionLinesList = createSelector(
    [baseProductionLinesState],
    (state) => state.items,
);

export const productionLinesOption = createSelector(
    [baseProductionLinesState],
    (data) => data.items && setOptionsId(data.items),
);

export const productionLinesError = createSelector(
    [baseProductionLinesState],
    (state) => state.error,
);

//*  SAGA  *//

function* workerGetProductionLines({ payload }) {
    try {
        const { isActive, nameSubstring, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/ProductionLines`, {
            params: {
                isActive,
                nameSubstring,
                pageSize,
            },
        });

        yield put({
            type: GET_PRODUCTION_LINES_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* requestEditAndAddLines(item) {
    try {
        if (item.createDate) {
            yield postman.put(`/ProductionLines/${item.id}`, item);
        } else {
            yield postman.post(`/ProductionLines`, item);
        }
    } catch (e) {
        alert(e);
    }
}

function* workerAddProductionLines({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield all(
            data.streams.map((item) => {
                return call(requestEditAndAddLines, item);
            }),
        );

        yield put({
            type: GET_PRODUCTION_LINES_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteProductionLines({ payload }) {
    try {
        const { item } = payload;

        yield postman.put(`/ProductionLines/${item.id}`, { ...item, isActive: false });
        yield put({
            type: DELETE_PRODUCTION_LINES_SUCCESS,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchProductionLines() {
    yield takeEvery(GET_PRODUCTION_LINES_REQUEST, workerGetProductionLines);
    yield takeEvery(ADD_PRODUCTION_LINES_REQUEST, workerAddProductionLines);
    yield takeEvery(DELETE_PRODUCTION_LINES_REQUEST, workerDeleteProductionLines);
}
