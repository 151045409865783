import React from 'react';
import { Icon } from 'semantic-ui-react';

import { urlAdmin } from '../../../utils/const';

function HeaderAuth() {
    return (
        <div className="authorization-heder">
            <div className="authorization-heder__body">
                <a className="authorization-heder__logo" />
                <div className="authorization-heder__nav">
                    <a className="authorization-heder__title" href={urlAdmin}>
                        Вход для администратора
                        <Icon name="angle right" size="large" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HeaderAuth;
