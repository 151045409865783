import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Table, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Chart } from 'react-google-charts';

import { getReport, reportData } from '../../ducks/report';
import { listMonth, tableConfigReport, tableConfigTotal } from '../../utils/const';
import { calculationYears, convertingObject } from '../../utils/functionHelper';
import useDebounce from '../../hooks/useDebounce';

import './style.scss';

function ReportPage() {
    const dispatch = useDispatch();
    const date = new Date();
    const report = useSelector(reportData);
    const [filter, setFilter] = useState({
        year: date.getFullYear(),
        month: listMonth[date.getMonth()].value,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const arrYear = calculationYears(date.getFullYear());
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };

    const chartArr = convertingObject(report?.incomeByDays);

    useEffect(() => {
        dispatch(getReport(debouncedFilter));
    }, [debouncedFilter]);

    return (
        <div className="report">
            <div className="report__body">
                <div className="filter">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <h1 className="filter__title">Месячный отчет</h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="filter__row">
                            <Grid.Column width={4} className="filter__text">
                                Год
                                <Dropdown
                                    onChange={handleChange}
                                    className="filter__input"
                                    fluid
                                    selection
                                    placeholder="2021"
                                    options={arrYear}
                                    name="year"
                                    value={filter.year}
                                />
                            </Grid.Column>
                            <Grid.Column width={4} className="filter__text">
                                Месяц
                                <Dropdown
                                    onChange={handleChange}
                                    className="filter__input"
                                    fluid
                                    selection
                                    placeholder="Май"
                                    options={listMonth}
                                    name="month"
                                    value={filter.month}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <h3 className="report__title">Общая информация</h3>
                <div className="report__table-main">
                    <Table celled structured>
                        <Table.Header>
                            <Table.Row>
                                {tableConfigReport.map(({ title }) => (
                                    <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {report && (
                                <Table.Row>
                                    {tableConfigReport.map(({ key }) => (
                                        <Table.Cell key={key}>{report[key]}</Table.Cell>
                                    ))}
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
                {report && (
                    <div className="report__section">
                        <div className="report__table">
                            <Table definition celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>День</Table.Cell>
                                        {report.incomeByDays.map(({ day }) => (
                                            <Table.Cell>{day}</Table.Cell>
                                        ))}
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Заработано</Table.Cell>
                                        {report.incomeByDays.map(({ income }) => (
                                            <Table.Cell>{income ? income : ''}</Table.Cell>
                                        ))}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="report__chart">
                            <Chart
                                width={'100%'}
                                height={'400px'}
                                chartType="LineChart"
                                loader={<div>Загрузка графика</div>}
                                data={[['Дни', 'Заработано'], ...chartArr]}
                                options={{
                                    hAxis: {
                                        title: 'Дни',
                                        format: '',
                                    },
                                    vAxis: {
                                        title: 'Заработано',
                                    },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                        <div className="report__section">
                            <div className="report__table-main">
                                <h3 className="report__title">Итого</h3>
                                <Table celled structured>
                                    <Table.Header>
                                        <Table.Row>
                                            {tableConfigTotal.map(({ title }) => (
                                                <Table.HeaderCell key={title}>
                                                    {title}
                                                </Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            {tableConfigTotal.map(({ key }) => (
                                                <Table.Cell key={key}>{report[key]}</Table.Cell>
                                            ))}
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                            <div className="report__chart">
                                <Chart
                                    width={'70%'}
                                    height={'350px'}
                                    chartType="ColumnChart"
                                    loader={<div>Загрузка графика</div>}
                                    data={[
                                        [`${filter.year}`, 'Доход', 'Расход'],
                                        [
                                            `${listMonth[filter.month - 1].text}`,
                                            report.totalIncome,
                                            report.totalExpenses,
                                        ],
                                    ]}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReportPage;
