import React from 'react';
import { Icon, Input, TextArea } from 'semantic-ui-react';

const CustomersInput = ({ value = [], error, onChange, onAdd, onDelete }) => {
    return (
        <>
            {value.map((input, index) => (
                <div key={input.id}>
                    <span className="customers__text">
                        {value.length - 1 === index ? 'Новый заказчик' : 'Заказчик'}
                    </span>
                    {value.length > 1 && (
                        <button
                            className="customers__button-delete"
                            onClick={event => onDelete(event, input.id)}
                        >
                            <Icon name="times circle outline" size="large" />
                            Удалить
                        </button>
                    )}
                    <Input
                        id={input.id}
                        name="name.customers"
                        className="customers__name"
                        placeholder="Введите наименование организации"
                        size="big"
                        onChange={onChange}
                        value={value[index].name}
                        error={error.idCustomers === input.id}
                    />
                    <TextArea
                        id={input.id}
                        className="customers__description"
                        placeholder="Введите описание"
                        name="description.customers"
                        value={value[index].description}
                        size="big"
                        onChange={onChange}
                    />
                </div>
            ))}
            <button className="roles-and-personnel__button-add" onClick={onAdd}>
                <p>
                    <Icon name="add circle" size="large" />
                    Добавить организацию
                </p>
            </button>
        </>
    );
};

export default CustomersInput;
