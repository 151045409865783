import React from 'react';

const Constructor = ({ onChange, children, ...props }) => {
    const { value, name } = props;

    const handleChange = (event, { name: fieldName, value: newValue, id }) => {
        const [nameField, arr] = fieldName.split('.');
        const index = value.findIndex(i => i.id === id);
        const newArr = [...value];
        newArr[index] = {
            ...newArr[index],
            [nameField]: newValue,
        };

        onChange(event, {
            name: arr,
            value: newArr,
        });
    };

    const handleAdd = event => {
        onChange(event, {
            name,
            value: [...value, props.getNewEmptyValue()],
        });
    };

    const handleDelete = (event, id) => {
        const index = value.findIndex(n => n.id === id);
        const newArr = [...value];
        newArr.splice(index, 1);

        onChange(event, {
            name,
            value: newArr,
        });
    };

    const handleToggle = event => {
        onChange(event, {
            name,
            value: value && value.length ? [] : [props.getNewEmptyValue()],
        });
    };

    return React.cloneElement(children, {
        ...props,
        onChange: handleChange,
        onAdd: handleAdd,
        onDelete: handleDelete,
        onToggle: handleToggle,
    });
};

export default Constructor;
