import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Icon, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { listMonth, configTableCosts } from '../../utils/const';
import { getExpenses, expensesData } from '../../ducks/report';
import { calculationYears } from '../../utils/functionHelper';
import useDebounce from '../../hooks/useDebounce';
import CostsModal from './components/modal';

import './style.scss';

function Costs() {
    const dispatch = useDispatch();
    const data = useSelector(expensesData);
    const date = new Date();
    const [modalData, setModalData] = useState({ open: false, data: {} });
    const [filter, setFilter] = useState({
        year: date.getFullYear(),
        month: listMonth[date.getMonth()].value,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const arrYear = calculationYears(date.getFullYear());
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const onClickCreate = () => {
        setModalData(Object.assign({}, modalData, { open: true }));
    };

    function fetchData() {
        dispatch(getExpenses(debouncedFilter));
    }
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const onClickEdit = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <div className="costs">
            <div className="costs__body">
                <Grid className="filter">
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3}>
                            <h1 className="filter__title">Расходы</h1>
                        </Grid.Column>
                        <Grid.Column width={5} className="filter__text">
                            <button className="filter__button" onClick={onClickCreate}>
                                <p>
                                    <Icon name="plus" size="small" />
                                    Добавить расходы
                                </p>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={4} className="filter__text">
                            Год
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                placeholder="2021"
                                options={arrYear}
                                name="year"
                                value={filter.year}
                            />
                        </Grid.Column>
                        <Grid.Column width={4} className="filter__text">
                            Месяц
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                placeholder="Май"
                                options={listMonth}
                                name="month"
                                value={filter.month}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <h3 className="costs__title">Таблица расходов</h3>
                <div className="costs__table">
                    <Table structured celled className="profitability__table-secondary">
                        <Table.Header>
                            <Table.Row>
                                {configTableCosts &&
                                    configTableCosts.map(({ title }) => (
                                        <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
                                    ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data &&
                                data.map((item) => (
                                    <Table.Row key={item.name}>
                                        {configTableCosts.map(({ key }) => (
                                            <Table.Cell key={key}>
                                                {key ? (
                                                    item[key]
                                                ) : (
                                                    <Icon
                                                        onClick={() => onClickEdit(item)}
                                                        name="pencil alternate"
                                                        size="large"
                                                        className="costs__img"
                                                    />
                                                )}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                    {modalData.open && (
                        <CostsModal
                            fetchData={fetchData}
                            modalData={modalData}
                            closeModal={closeModal}
                            filter={filter}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Costs;
